import {NgModule} from '@angular/core';
import { AuthGuard } from './guards/auth/auth.guard'
import { RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: '', redirectTo: '/welcome', pathMatch: 'full'},
    {path: 'welcome', loadChildren: './pages/welcome/welcome.module#WelcomePageModule'},
    {path: 'login', loadChildren: './pages/Auth/login/login.module#LoginPageModule'},
    {path: 'register', loadChildren: './pages/Auth/register/register.module#RegisterPageModule'},
    {
        path: 'members',
        canActivate: [AuthGuard],
        loadChildren: './members/member-routing.module#MemberRoutingModule'
    },  { path: 'network', loadChildren: './pages/network/network.module#NetworkPageModule' },
  { path: 'verified', loadChildren: './pages/verified/verified.module#VerifiedPageModule' },
  { path: 'tutorials', loadChildren: './pages/tutorials/tutorials.module#TutorialsPageModule' },


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
