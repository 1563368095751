import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'employeeVacationsSearch'
})
export class EmployeeVacationsSearchPipe implements PipeTransform {

    transform(items: any, terms?: any): any {
        if (!items) return [];
        if (!terms) return items;
        terms = terms.toLowerCase();
        return items.filter(it => {
            if (it.employee.name) {
                return it.employee.name.toLowerCase().includes(terms);
            } else {
                return it.employee.fullname.toLowerCase().includes(terms);

            }
        });
    }

}
