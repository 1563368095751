import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'employeeSupervisorSearch'
})
export class EmployeeSupervisorSearchPipe implements PipeTransform {

    transform(items: any, terms?: any): any {
        if (!items) {
            return [];
        }
        if (!terms) {
            return items;
        }
        terms = terms.toLowerCase();
        return items.filter(it => {
            if (it.employee.fullname) {
                return it.employee.fullname.toLowerCase().includes(terms);

            } else {
                return it.employee.toLowerCase().includes(terms);

            }
        });
    }
}
