import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class TranslatedService {

  constructor(private translate:TranslateService) { }

  /**
   * Getting translated words :)
   * @param key
   * @param callback
   * @private
   */
  _initialiseTranslation(key,callback): void {
    this.translate.get(key).subscribe((res: string) => {
      callback(res)
    });
  }
  getTranslatedText(label){
    this._initialiseTranslation(label, (data) => {
      label = data;
    });
    return label;
  }
}
