import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeeAccountService {

    constructor(public helpers:HelpersService,public http: HttpClient, public token: TokenService, public lngProvider:LanguageService) {}
    public account:any = {};

    firstLoggedIn(){

        let headers = new HttpHeaders();

        headers = headers.append('Authorization',this.token.returnToken());

        headers = headers.append('Accept', 'application/json');

        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url+this.lngProvider.language+'/api/employee/profile',JSON.stringify({}), {headers: headers});



    }

    changePassword(data){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url+this.lngProvider.language+'/api/employee/profile', JSON.stringify(data), {headers: headers});

    }

    getEmpProfile(): Observable<any>{
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/profile', {headers: headers});
    }
    getVacationsData(): Observable<any>{
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/vacation', {headers: headers});
    }

    employeeMonthlyHour() {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/monthly/hours', {headers: headers});
    }
    employeeMonthlyHourYearly(year) {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/monthly/report/' + year, {headers: headers});
    }
    emp_chooseProject() {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/choose/projects', {headers: headers});
    }
    emp_projectActivit(project_id) {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/project/' + project_id, {headers: headers});
    }
    emp_verifiedAbsentProjects() {
        //UNVERIFIED WEEKLY
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/unverified/reports', {headers: headers});
    }
    emp_unverifiedAbsentProjects() {
        //ABSENT
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/absent/reports', {headers: headers});
    }

    emp_disapporvedReports() {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/disproved/reports', {headers: headers});
    }
    emp_ReportDetail(id) {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/report/verification/details/' + id, {headers: headers});
    }
    emp_scannedQrCode(rep_id, slug) {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/report/verify/hours/' + rep_id + '/' + slug, {headers: headers});
    }
    addWeeklyReport(data, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        if (year) {
            return this.http.post(this.helpers.url + this.lngProvider.language + '/api/employee/add/weekly/report/' + year, JSON.stringify(data), {headers: headers});
        } else {
            return this.http.post(this.helpers.url + this.lngProvider.language + '/api/employee/add/weekly/report', JSON.stringify(data), {headers: headers});
        }
    }
    deleteReport(report_id){
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/delete/report/' + report_id, {headers: headers});
    }
    editWeeklyReport(report_id,data){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url+this.lngProvider.language+'/api/employee/edit/report/' + report_id, JSON.stringify(data), {headers: headers});
    }
    getEditWeeklyReport(report_id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/edit/report/' + report_id, {headers: headers});
    }
    getWeeklyReport() {
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/add/weekly/report', {headers: headers});
    }

    generateSinglePDF(report_id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/single/project/pdf/' + report_id, {headers: headers});
    }
    generateWeeklyPDF(week) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/weekly/report/pdf/'+ week, {headers: headers});
    }

    /**
     * Get all regies for company or employee
     * @param type string -> company or employee
     */
    getRegieList(type){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/'+type+'/regie/list', {headers: headers});
    }

    requestAdminApproval(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/approval/request/'+id, {headers: headers});
    }

    getDataForWeeklyReport(week,year){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/week/r/report/'+week+'/'+year, {headers: headers});

    }


    getVacationList(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/vacation', {headers: headers});
    }

    getSupervisorCheck(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/self/is/supervisor', {headers: headers});
    }

    addVacation(body){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url+this.lngProvider.language+'/api/employee/vacation',JSON.stringify(body) ,{headers: headers});
    }

    deleteVacation(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(this.helpers.url+this.lngProvider.language+'/api/employee/vacation/'+id ,{headers: headers});
    }

    requestDeleteVacation(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/vacation/request/delete/'+id ,{headers: headers});
    }

    generateSingleMonthPDF(month, year){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/monthly/single/report/'+month+'/'+year, {headers: headers});
    }
    getRegieNumber(keyword){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/'+keyword+'/client/list', {headers: headers});
    }
    getEmployeeActivity(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/activity/page', {headers: headers});
    }

    getAllUnverifiedReports(){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/get/my/employees/data', {headers: headers});
    }

    getMyEmployeesVacations(){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/get/my/employees/vacations', {headers: headers});
    }

    /**
     * Check if we created same report
     * @param project_id
     * @param week
     */
    checkReport(project_id, week, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/check/available/report/' + project_id + '/' + week+'/'+year, {headers: headers});
    }
    getBadgeUnverifiedReports() {
        //api/company/employee/all/unverified/reports
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/get/requested/verification/reports/number', {headers: headers});
    }
    getBadgeVacations(){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/employee/get/my/employees/vacations/number', {headers: headers});
    }

    approveDeletionVacationEmployee(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/approve/vacation/delete/' + id,{headers: headers});
    }

    /**
     * Get verifiers list according to report's id
     * @param id
     * @returns {Observable<Object>}
     */
    getVerifiersListForApproval(id){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/get/verifier/list/'+id, {headers: headers});
    }

    /**
     * Request approval from verifier for a report (not supervisor)
     * @param r_id
     * @param v_id
     * @returns {Observable<Object>}
     */
    requestApprovalFromVerifier(r_id,v_id){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/verifier/approval/request/'+r_id+'/'+v_id, {headers: headers});
    }
}
