import {Injectable} from '@angular/core';
import {HelpersService} from '../helpers/helpers.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';

@Injectable({
    providedIn: 'root'
})
export class WeekDaysService {

    constructor(
        public helper: HelpersService,
        public http: HttpClient,
        public tokenProvider: TokenService,
        public lngProvider: LanguageService,
    ) {
    }


    getWeekDays(week, year) {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helper.url + this.lngProvider.language + '/api/days/week/' + week+'/'+year, {headers: headers});
    }

}
