import { Injectable } from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthService} from '../../services/authentication/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivate{
    constructor(public auth: AuthService) {}

    canActivate(): boolean {
        return this.auth.isAuthenticated();
    }
}
