import {ModuleWithProviders} from "@angular/compiler/src/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {DragulaModule} from 'ng2-dragula'
import {SearchProjectPipe} from './pipes/search-project/search-project.pipe';
import {SearchGroupPipe} from './pipes/search-group/search-group.pipe';
import {SearchEmployeesPipe} from './pipes/search-employees/search-employees.pipe';
import {EmployeeSupervisorSearchPipe} from './pipes/employee-supervisor-search/employee-supervisor-search.pipe';
import {EmployeeVacationsSearchPipe} from './pipes/employee-vacations-search/employee-vacations-search.pipe';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        DragulaModule.forRoot()
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SearchEmployeesPipe,
        SearchGroupPipe,
        SearchProjectPipe,
        EmployeeSupervisorSearchPipe,
        EmployeeVacationsSearchPipe
    ],
    declarations:[SearchProjectPipe,SearchGroupPipe,SearchEmployeesPipe,EmployeeSupervisorSearchPipe, EmployeeVacationsSearchPipe]
})
export class SharedModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
