import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    constructor(public http: HttpClient, public tokenProvider: TokenService, public lngProvider: LanguageService,public helpers:HelpersService) {
    }

    getProjects() : Observable<any>{
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/list', {headers: headers});
    }

    getProjectsDetails(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/details/' + id, {headers: headers});
    }

    addProject(id, data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/project/add/' + id, JSON.stringify(data), {headers: headers});

    }

    assignEmployee(id,data){
        let body={
            employees:data
        };
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/project/assign/employee/' + id, JSON.stringify(body), {headers: headers});

    }

    removeAssignedEmployee(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/remove/assigned/employee/' + id, {headers: headers});
    }

    addProjectEmail(id, data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/project/add/email/' + id, JSON.stringify(data), {headers: headers});

    }

    addVerifierEmail(id, data): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/project/add/verificator/' + id, JSON.stringify(data), {headers: headers});

    }

    removeAddedEmail(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/remove/email/' + id, {headers: headers});
    }

    removeAddedVerificator(id,v_id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/delete/verificator/' + id+'/'+v_id, {headers: headers});
    }

    editProject(id, data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/project/edit/' + id, JSON.stringify(data), {headers: headers});
    }

    finishActiveProject(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/finish/' + id, {headers: headers});
    }

    removeProject(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/delete/' + id, {headers: headers});
    }

    getEditProject(id,): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/edit/' + id, {headers: headers});
    }


    generateProjectPDF(project_id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/report/pdf/' + project_id, {headers: headers});
    }

    assignGroup(p_id, g_id) {
            let headers = new HttpHeaders();
            headers = headers.append('Authorization', this.tokenProvider.returnToken());
            headers = headers.append('Accept', 'application/json');
            headers = headers.append('Content-Type', 'application/json');
            return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/project/assign/group/' + p_id + '/' + g_id, {headers: headers});

    }

}
