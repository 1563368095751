import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Events, ModalController, NavController, Platform} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../../../services/project/project.service';
import {LoaderService} from '../../../services/loader/loader.service';
import {DatePipe} from '@angular/common';
import {LanguageService} from '../../../services/language/language.service';
import {AlertService} from '../../../services/alert/alert.service';
import {ToastService} from '../../../services/toast/toast.service';
import {EmployeeAccountService} from '../../../services/employee-account/employee-account.service';
import {PdfService} from '../../../services/pdf/pdf.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedService} from '../../../services/translated/translated.service';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {CompanyService} from '../../../services/company/company.service';
import {WeekDaysService} from '../../../services/week-days/week-days.service';

@Component({
    selector: 'app-employee-report-detail',
    templateUrl: './employee-report-detail.page.html',
    styleUrls: ['./employee-report-detail.page.scss'],
})
export class EmployeeReportDetailPage implements OnInit {

    keyword: any;
    report_id: any;
    @Input() modal_keyword: any;
    @Input() modal_report_id: any;

    verifiers_list = [];
    project_id;
    page_title;
    isVerified;
    reportObj;
    docDefinition;
    pdfReport;
    pdfText;
    project;
    errorMessage;
    reportDataObj;
    week;
    year;
    mon;
    tue;
    wed;
    thur;
    fri;
    sat;
    sun;
    weeklyReportProject;
    pleaseWaitLabel;
    yesLabel;
    cancelLabel;
    okText;
    canceltext;
    reportEditedSuccessMessage;
    invalidDataMessage;
    confirmDeleteLabel;
    doYouWantToRemoveReport;
    deletedSuccessMessage;
    total;
    isIOS;
    absentReason;
    PDF_generatedLabel;
    downloadingPDFLabel;
    okButtonLabel;
    monday;
    tuesday;
    wednesday;
    thursday;
    friday;
    saturday;
    sunday;
    emplyoee;
    isDisapproved;
    disapprove_reason;
    validDays: boolean = true;
    size;
    is_notification;

    validPercentage: boolean = true;
    mon_hours_with_extra_25: any ;
    mon_hours_with_extra_50: any ;
    mon_hours_with_extra_100: any ;
    tue_hours_with_extra_25: any ;
    tue_hours_with_extra_50: any ;
    tue_hours_with_extra_100: any ;
    wed_hours_with_extra_25: any ;
    wed_hours_with_extra_50: any ;
    wed_hours_with_extra_100: any ;
    thu_hours_with_extra_25: any ;
    thu_hours_with_extra_50: any ;
    thu_hours_with_extra_100: any ;
    fri_hours_with_extra_25: any ;
    fri_hours_with_extra_50: any ;
    fri_hours_with_extra_100: any ;
    sat_hours_with_extra_25: any ;
    sat_hours_with_extra_50: any ;
    sat_hours_with_extra_100: any ;
    sun_hours_with_extra_25: any ;
    sun_hours_with_extra_50: any ;
    sun_hours_with_extra_100: any ;
    subtotal25: any ;
    subtotal50: any ;
    subtotal100: any ;
    subtotal: any ;

    constructor(
        private navCtrl: NavController,
        private lngProvider: LanguageService,
        private route: ActivatedRoute,
        private employeeService: EmployeeAccountService,
        private projectService: ProjectService,
        private alertCtrl: AlertService,
        private datePipe: DatePipe,
        private loader: LoaderService,
        private toastCtrl: ToastService,
        public platform: Platform,
        private pdfProvider: PdfService,
        public translate: TranslateService,
        public translated: TranslatedService,
        private events: Events,
        private helpers: HelpersService,
        private companyService: CompanyService,
        private modalController: ModalController,
        public weekDaysHelper:WeekDaysService
    ) {
        this.events.subscribe('backButtonClicked', () => {
            this.goBack();
        });
        this.events.subscribe('auto-close', () => {
            this.goBack();
        });
        this.isIOS = this.platform.is('ios');
        this.emplyoee = this.companyService.company_profile;
    }

    ngOnInit() {
        this.keyword = this.route.snapshot.paramMap.get('keyword');
        this.report_id = this.route.snapshot.paramMap.get('report_id');
        if (this.modal_keyword && this.modal_report_id) {
            this.keyword = this.modal_keyword;
            this.report_id = this.modal_report_id;
        }
        this.is_notification = this.route.snapshot.paramMap.get('is_notification');
        // if (!this.is_notification) {
        this.page_title = this.route.snapshot.paramMap.get('page_title');
        this.isVerified = this.route.snapshot.paramMap.get('isVerified');
        this.project_id = this.route.snapshot.paramMap.get('project_id');
        // }

        this.getWeeklyReport(this.report_id);


        if (this.lngProvider.language == 'en') {
            this.pleaseWaitLabel = 'Please Wait...';
            this.yesLabel = 'Yes';
            this.cancelLabel = 'Cancel';
            this.okText = 'OK';
            this.canceltext = 'Cancel';
            this.reportEditedSuccessMessage = 'Report edited successfully';
            this.invalidDataMessage = 'Invalid data. Please fill from 0 to 24h';
            this.confirmDeleteLabel = 'Confirm delete';
            this.doYouWantToRemoveReport = 'Do you want to delete this Report?';
            this.deletedSuccessMessage = 'Report deleted successfully.';
            this.oneMessage = 'Invalid data!';
            this.datePipe = new DatePipe('en-US');
            this.pleaseWaitLabel = 'Please Wait...';
            this.okButtonLabel = 'OK';
            this.cancelLabel = 'Cancel';
            this.confirmDeleteLabel = 'Confirm delete';
            this.PDF_generatedLabel = 'PDF Generated.';
            this.downloadingPDFLabel = 'Downloading PDF...';
            this.pdfText = {
                weeklyReport: 'Weekly Report 2018 \n Week: ',
                name: '\n Name: ',
                persNo: '\n Pers.-Nr.:',
                clientCompanyName: 'Einsatzbetrieb',
                clientStreet: '\n',
                clientPostcode: '\n ',
                clientCity: '',
                clientMail: '\n',
                clientPhone: '\n',
                projectID: 'Project ID: ',
                absence_projectName: 'Absence|Project Name: ',
                workingHours: 'Working Hours: ',
                totalHours: 'Total Hours: ',
                verifiedBy: 'Verified by: ',
                verifiedTime: 'Verified time: ',
                unverified: 'Unverified',
                total: 'Total'
            };
            this.yesLabel = 'Yes';
            this.doYouWantToRemoveReport = 'Do you want to delete this Report?';
        } else {
            this.lngProvider.language = 'de';
            this.pleaseWaitLabel = 'Warten Sie mal...';
            this.yesLabel = 'Ja';
            this.cancelLabel = 'Abbrechen';
            this.okText = 'OK';
            this.canceltext = 'Abbrechen';
            this.reportEditedSuccessMessage = 'Rapport erfolgreich bearbeitet';
            this.invalidDataMessage = 'Ungültige Daten. Bitte füllen Sie von 0 bis 24h';
            this.confirmDeleteLabel = 'Löschen bestätigen';
            this.doYouWantToRemoveReport = 'Möchten Sie diesen Rapport löschen?';
            this.deletedSuccessMessage = 'Der Rapport wurde erfolgreich gelöscht.';
            this.oneMessage = 'Ungültige Daten!';
            this.pleaseWaitLabel = 'Warten Sie mal...';
            this.yesLabel = 'Ja';
            this.PDF_generatedLabel = 'PDF generiert';
            this.downloadingPDFLabel = 'PDF herunterladen ...';
            this.okButtonLabel = 'OK';
            this.cancelLabel = 'Abbrechen';
            this.confirmDeleteLabel = 'Löschen bestätigen';
            this.doYouWantToRemoveReport = 'Möchten Sie diesen Rapport löschen?';

            this.pdfText = {
                weeklyReport: 'Wochenrapport 2018 \n Woche: ',
                name: '\n Name: ',
                persNo: '\n Pers.-Num.:',
                clientCompanyName: 'Einsatzbetrieb',
                clientStreet: '\n',
                clientPostcode: '\n',
                clientCity: '',
                clientMail: '\n',
                clientPhone: '\n',
                projectID: 'Projekt-ID: ',
                absence_projectName: 'Abwesenheit|Projektname: ',
                workingHours: 'Arbeitszeit: ',
                totalHours: 'Gesamtstunden: ',
                verifiedBy: 'Geprüft von: ',
                verifiedTime: 'Überprüfte Zeit: ',
                unverified: 'Nicht überprüft',
                total: 'Gesamt: '
            };
        }
    }

    goBack() {
        if (this.keyword == 'modal') {
            this.modalController.dismiss();
        } else if (this.is_notification == 'true') {
            this.navCtrl.navigateBack(['/members/employee-dashboard']);
        } else {
            if (!this.keyword) {
                this.navCtrl.navigateBack(['/members/employee-reports']);

            } else {
                this.navCtrl.navigateBack(['/members/employee-project-activity', {keyword: this.keyword, project_id: this.project_id}]);

            }
        }
    }

    /**
     * Getting weekly report data
     * @param id int -> id of the report
     */
    getWeeklyReport(id) {
        let that = this;
        this.loader.fancyLoading('loading', function () {
            that.employeeService.getEditWeeklyReport(id).subscribe((res: any) => {
                that.reportDataObj = res;
                that.emplyoee = res.report.employee.fullname;
                that.week = res.report.woche;
                that.reportObj = that.reportDataObj.report;
                that.isVerified = that.reportObj.verified;
                that.page_title = that.reportObj.get_title;
                console.log(that.reportObj);
                that.isDisapproved = that.reportDataObj.report.disaprovedByAdmin;
                that.disapprove_reason = that.reportDataObj.report.disaproved_reason;
                that.year = that.reportDataObj.report.for_year?that.reportDataObj.report.for_year:( new Date(that.reportDataObj.report.created)).getFullYear();
                that.mon = that.reportDataObj.report.mo;
                that.tue = that.reportDataObj.report.di;
                that.wed = that.reportDataObj.report.mi;
                that.thur = that.reportDataObj.report.do;
                that.fri = that.reportDataObj.report.fr;
                that.sat = that.reportDataObj.report.sa;
                that.sun = that.reportDataObj.report.so;
                that.total = that.total = parseFloat(that.mon) + parseFloat(that.tue) + parseFloat(that.wed) + parseFloat(that.thur) + parseFloat(that.fri) + parseFloat(that.sat) + parseFloat(that.sun);
                if (!that.reportObj.absent) {
                    that.mon_hours_with_extra_25 = that.reportDataObj.report.mo_hours_with_extra_25;
                    that.mon_hours_with_extra_50 = that.reportDataObj.report.mo_hours_with_extra_50;
                    that.mon_hours_with_extra_100 = that.reportDataObj.report.mo_hours_with_extra_100;
                    that.tue_hours_with_extra_25 = that.reportDataObj.report.di_hours_with_extra_25;
                    that.tue_hours_with_extra_50 = that.reportDataObj.report.di_hours_with_extra_50;
                    that.tue_hours_with_extra_100 = that.reportDataObj.report.di_hours_with_extra_100;
                    that.wed_hours_with_extra_25 = that.reportDataObj.report.mi_hours_with_extra_25;
                    that.wed_hours_with_extra_50 = that.reportDataObj.report.mi_hours_with_extra_50;
                    that.wed_hours_with_extra_100 = that.reportDataObj.report.mi_hours_with_extra_100;
                    that.thu_hours_with_extra_25 = that.reportDataObj.report.do_hours_with_extra_25;
                    that.thu_hours_with_extra_50 = that.reportDataObj.report.do_hours_with_extra_50;
                    that.thu_hours_with_extra_100 = that.reportDataObj.report.do_hours_with_extra_100;
                    that.fri_hours_with_extra_25 = that.reportDataObj.report.fr_hours_with_extra_25;
                    that.fri_hours_with_extra_50 = that.reportDataObj.report.fr_hours_with_extra_50;
                    that.fri_hours_with_extra_100 = that.reportDataObj.report.fr_hours_with_extra_100;
                    that.sat_hours_with_extra_25 = that.reportDataObj.report.sa_hours_with_extra_25;
                    that.sat_hours_with_extra_50 = that.reportDataObj.report.sa_hours_with_extra_50;
                    that.sat_hours_with_extra_100 = that.reportDataObj.report.sa_hours_with_extra_100;
                    that.sun_hours_with_extra_25 = that.reportDataObj.report.so_hours_with_extra_25;
                    that.sun_hours_with_extra_50 = that.reportDataObj.report.so_hours_with_extra_50;
                    that.sun_hours_with_extra_100 = that.reportDataObj.report.so_hours_with_extra_100;
                }
                that.totalWrk();
                that.weeklyReportProject = that.reportDataObj.projList;
                that.weekDaysHelper.getWeekDays(that.week, that.year).subscribe((res:any)=>{
                    that.monday = res.monday;
                    that.tuesday = res.tuesday;
                    that.wednesday = res.wednesday;
                    that.thursday = res.thursday;
                    that.friday = res.friday;
                    that.saturday = res.saturday;
                    that.sunday = res.sunday;
                });
                /**
                 * Changing button size according if report is verified
                 */
                if (!that.reportObj.verified && !that.reportObj.requested_notification) {
                    that.size = '4';
                } else {
                    that.size = '6';
                }
                if (!that.reportObj.absent) {
                    that.getVerifiersList();
                }
            }, error => {
                that.errorMessage = <any>error;
            });
        });
    }
    oneMessage;


    /**
     * Removing comma and checking if days are not bigger than 24
     * @param event
     */
        // checkValid(event) {
        //     if (this.helpers.checkValid(event)) {
        //         this.validDays = true;
        //     } else {
        //         this.validDays = false;
        //     }
        //
        // }
    monValid = true;
    tueValid = true;
    wedValid = true;
    thuValid = true;
    friValid = true;
    satValid = true;
    sunValid = true;
    valid25 = true;
    valid50 = true;
    valid100 = true;

    checkValid(event) {

        switch (event.srcElement.id) {
            case 'mon':
            case 'mon25':
            case 'mon50':
            case 'mon100':
                this.monValid = this.helpers.checkValid(event);
                break;
            case 'tue':
            case 'tue25':
            case 'tue50':
            case 'tue100':
                this.tueValid = this.helpers.checkValid(event);
                break;
            case 'wed':
            case 'wed25':
            case 'wed50':
            case 'wed100':
                this.wedValid = this.helpers.checkValid(event);
                break;
            case 'thu':
            case 'thu25':
            case 'thu50':
            case 'thu100':
                this.thuValid = this.helpers.checkValid(event);
                break;
            case 'fri':
            case 'fri25':
            case 'fri50':
            case 'fri100':
                this.friValid = this.helpers.checkValid(event);
                break;
            case 'sat':
            case 'sat25':
            case 'sat50':
            case 'sat100':
                this.satValid = this.helpers.checkValid(event);
                break;
            case 'sun':
            case 'sun25':
            case 'sun50':
            case 'sun100':
                this.sunValid = this.helpers.checkValid(event);
                break;
        }

        this.validDays =
            this.monValid &&
            this.tueValid &&
            this.wedValid &&
            this.thuValid &&
            this.friValid &&
            this.satValid &&
            this.sunValid;

    }

    /**
     * Function that checks if value is 0
     * @param event number -> returns empty string
     */
    clearOnEdit(event) {
        this.helpers.clearOnEdit(event);
    }

    /**
     * Calculate the total number of hours for all days
     * @returns {string}
     */
    totalWrk() {
        this.total =
            parseFloat(this.mon == '' ? 0 : this.mon) +
            parseFloat(this.tue == '' ? 0 : this.tue) +
            parseFloat(this.wed == '' ? 0 : this.wed) +
            parseFloat(this.thur == '' ? 0 : this.thur) +
            parseFloat(this.fri == '' ? 0 : this.fri) +
            parseFloat(this.sat == '' ? 0 : this.sat) +
            parseFloat(this.sun == '' ? 0 : this.sun);

        if(!this.reportObj.absent){
            this.subtotal25 =
                parseFloat(this.mon_hours_with_extra_25 == '' ? 0 : this.mon_hours_with_extra_25) +
                parseFloat(this.tue_hours_with_extra_25 == '' ? 0 : this.tue_hours_with_extra_25) +
                parseFloat(this.wed_hours_with_extra_25 == '' ? 0 : this.wed_hours_with_extra_25) +
                parseFloat(this.thu_hours_with_extra_25 == '' ? 0 : this.thu_hours_with_extra_25) +
                parseFloat(this.fri_hours_with_extra_25 == '' ? 0 : this.fri_hours_with_extra_25) +
                parseFloat(this.sat_hours_with_extra_25 == '' ? 0 : this.sat_hours_with_extra_25) +
                parseFloat(this.sun_hours_with_extra_25 == '' ? 0 : this.sun_hours_with_extra_25);

            this.subtotal50 =
                parseFloat(this.mon_hours_with_extra_50 == '' ? 0 : this.mon_hours_with_extra_50) +
                parseFloat(this.tue_hours_with_extra_50 == '' ? 0 : this.tue_hours_with_extra_50) +
                parseFloat(this.wed_hours_with_extra_50 == '' ? 0 : this.wed_hours_with_extra_50) +
                parseFloat(this.thu_hours_with_extra_50 == '' ? 0 : this.thu_hours_with_extra_50) +
                parseFloat(this.fri_hours_with_extra_50 == '' ? 0 : this.fri_hours_with_extra_50) +
                parseFloat(this.sat_hours_with_extra_50 == '' ? 0 : this.sat_hours_with_extra_50) +
                parseFloat(this.sun_hours_with_extra_50 == '' ? 0 : this.sun_hours_with_extra_50);

            this.subtotal100 =
                parseFloat(this.mon_hours_with_extra_100 == '' ? 0 : this.mon_hours_with_extra_100) +
                parseFloat(this.tue_hours_with_extra_100 == '' ? 0 : this.tue_hours_with_extra_100) +
                parseFloat(this.wed_hours_with_extra_100 == '' ? 0 : this.wed_hours_with_extra_100) +
                parseFloat(this.thu_hours_with_extra_100 == '' ? 0 : this.thu_hours_with_extra_100) +
                parseFloat(this.fri_hours_with_extra_100 == '' ? 0 : this.fri_hours_with_extra_100) +
                parseFloat(this.sat_hours_with_extra_100 == '' ? 0 : this.sat_hours_with_extra_100) +
                parseFloat(this.sun_hours_with_extra_100 == '' ? 0 : this.sun_hours_with_extra_100);

            this.subtotal = this.subtotal25 + this.subtotal50 + this.subtotal100;
            this.total += this.subtotal;
        }


        //+ parseFloat(this.hours_with_extra_25 == '' ? 0 : this.hours_with_extra_25) +parseFloat(this.hours_with_extra_50 == '' ? 0 : this.hours_with_extra_50) +parseFloat(this.hours_with_extra_100 == '' ? 0 : this.hours_with_extra_100) ;
        this.total = this.total ? this.total : 0;
        this.total = parseFloat(this.total).toFixed(2);
        return this.total;
    }


    /**
     * Edit selected report
     * @param id int -> id of the report
     */
    edit_WeeklyReport(id) {
        let data:any;
        if(this.reportObj.absent)
             data = {
                mon_1: this.mon ? this.mon : 0,
                tue_2: this.tue ? this.tue : 0,
                wed_3: this.wed ? this.wed : 0,
                thu_4: this.thur ? this.thur : 0,
                fri_5: this.fri ? this.fri : 0,
                sat_6: this.sat ? this.sat : 0,
                sun_7: this.sun ? this.sun : 0,

            };
        else{
            data = {
                mon_1: this.mon ? this.mon : 0,
                tue_2: this.tue ? this.tue : 0,
                wed_3: this.wed ? this.wed : 0,
                thu_4: this.thur ? this.thur : 0,
                fri_5: this.fri ? this.fri : 0,
                sat_6: this.sat ? this.sat : 0,
                sun_7: this.sun ? this.sun : 0,
                'mo_hours_with_extra_25':this.mon_hours_with_extra_25 ? this.mon_hours_with_extra_25 :0,
                'mo_hours_with_extra_50':this.mon_hours_with_extra_50 ? this.mon_hours_with_extra_50 :0,
                'mo_hours_with_extra_100':this.mon_hours_with_extra_100? this.mon_hours_with_extra_100:0,
                'di_hours_with_extra_25':this.tue_hours_with_extra_25 ? this.tue_hours_with_extra_25 :0,
                'di_hours_with_extra_50':this.tue_hours_with_extra_50 ? this.tue_hours_with_extra_50 :0,
                'di_hours_with_extra_100':this.tue_hours_with_extra_100? this.tue_hours_with_extra_100:0,
                'mi_hours_with_extra_25':this.wed_hours_with_extra_25 ? this.wed_hours_with_extra_25 :0,
                'mi_hours_with_extra_50':this.wed_hours_with_extra_50 ? this.wed_hours_with_extra_50 :0,
                'mi_hours_with_extra_100':this.wed_hours_with_extra_100? this.wed_hours_with_extra_100:0,
                'do_hours_with_extra_25':this.thu_hours_with_extra_25 ? this.thu_hours_with_extra_25 :0,
                'do_hours_with_extra_50':this.thu_hours_with_extra_50 ? this.thu_hours_with_extra_50 :0,
                'do_hours_with_extra_100':this.thu_hours_with_extra_100? this.thu_hours_with_extra_100:0,
                'fr_hours_with_extra_25':this.fri_hours_with_extra_25 ? this.fri_hours_with_extra_25 :0,
                'fr_hours_with_extra_50':this.fri_hours_with_extra_50 ? this.fri_hours_with_extra_50 :0,
                'fr_hours_with_extra_100':this.fri_hours_with_extra_100? this.fri_hours_with_extra_100:0,
                'sa_hours_with_extra_25':this.sat_hours_with_extra_25 ? this.sat_hours_with_extra_25 :0,
                'sa_hours_with_extra_50':this.sat_hours_with_extra_50 ? this.sat_hours_with_extra_50 :0,
                'sa_hours_with_extra_100':this.sat_hours_with_extra_100? this.sat_hours_with_extra_100:0,
                'so_hours_with_extra_25':this.sun_hours_with_extra_25 ? this.sun_hours_with_extra_25 :0,
                'so_hours_with_extra_50':this.sun_hours_with_extra_50 ? this.sun_hours_with_extra_50 :0,
                'so_hours_with_extra_100':this.sun_hours_with_extra_100? this.sun_hours_with_extra_100:0,
            };
        }
        let that = this;
        if (this.validDays) {
            this.loader.fancyLoading('loading', function () {
                that.employeeService.editWeeklyReport(id, data).subscribe(res => {
                    let obj: any;
                    obj = res;
                    console.log(obj);
                    if (obj.status == 'success') {
                        that.toastCtrl.presentToast(obj.message, '', 'success', '');
                        that.disapprove_reason = '';//reseting disaprove reason after edit
                        that.isDisapproved = false;
                    } else {
                        that.toastCtrl.presentToast(obj.message, '', 'warning', '');

                    }
                }, error => {
                    console.log(error);
                });

            });
        } else {
            this.toastCtrl.presentToast('invalid_data', '', 'danger', '');

        }

    }

    deleteConfirmAlert(report_id) {
        let that = this;
        this.alertCtrl.presentDeleteAlert('delete_report', '', function () {
            that.employeeService.deleteReport(report_id).subscribe((res: any) => {
                that.toastCtrl.presentToast(res.message, '', res.status, '');
                if (res.status == 'success') {
                    that.goBack();
                }
            });
        });
    }

    /**
     *  Select element for verifier
     */
    @ViewChild('selectVerifier') verifiersOptions;

    optionsVerifierList() {
        if (this.isDisapproved) {
            this.requestApproval();
        }
        else{
            this.verifiersOptions.open();
        }
    }

    verifier: any;

    chooseVerifier() {
        if (this.verifier) {
            this.requestApproval();

        }
    }

    verifierOptionCanceled() {
        this.verifier = null;
    }

    /**
     * Send request to admin to approve the report
     */
    requestApproval() {
        if (this.isDisapproved) {
            this.alertCtrl.presentAlert(
                {
                    header: this.translated.getTranslatedText('Your report is disapproved. Please update your report first.'),
                    buttons: [{text: 'OK'}]
                });
        } else {

            let that = this;

            this.alertCtrl.presentAlertPrompt(this.translated.getTranslatedText('request_admin_approval') + '?', false, {}, function () {
                if (that.reportObj.absent || that.verifier.id == 'supervisor') {
                    that.employeeService.requestAdminApproval(that.report_id).subscribe((data: any) => {
                        console.log(data);
                        that.toastCtrl.presentToast(data.message, '', data.status, '');
                        that.verifier = null;
                    });
                } else {
                    that.employeeService.requestApprovalFromVerifier(that.report_id, that.verifier.id).subscribe((data: any) => {
                        console.log(data);
                        that.toastCtrl.presentToast(data.message, '', data.status, '');
                        that.verifier = null;
                    });
                }
            });

        }

    }

    showQR = false;

    qRCode() {
        this.showQR = !this.showQR;

    }

    /**
     * Generate PDF for the report
     * @param id
     */
    generatePDF(id) {
        this.employeeService.generateSinglePDF(id).subscribe((res: any) => {
            console.log(res);
            this.helpers.generate_current_report_pdf_with_percentage(res, this.page_title, 'basic');
        }, error => {
            this.errorMessage = <any>error;
        });

    }

    /**
     * Generate weekly PDF based on the week of this report
     */
    generatePDFWeekly(data) {
        this.employeeService.generateWeeklyPDF(data.woche).subscribe((res: any) => {
            this.helpers.generate_weekly_report_pdf_with_percentage(res, 'employee');
        });
    }

    @ViewChild('selectOption') alertOptions;
    option;
    customAlertOptions: any = {
        header: this.translated.getTranslatedText('generate_pdf'),
    };
    customAlertOptionsVerifier: any = {
        header: this.translated.getTranslatedText('request_admin_approval'),
        backdropDismiss: false
    };

    showOptions() {
        this.alertOptions.open();
    }

    optionSelected(w) {

        if (this.option == '1') {
            this.generatePDF(this.report_id);
        } else if (this.option == '2') {
            this.generatePDFWeekly(w);
        }

        this.option = null;
    }

    optionCanceled() {
        this.option = null;
    }


    /**
     * Get list of verifiers to request approval from
     */
    getVerifiersList() {
        this.employeeService.getVerifiersListForApproval(this.report_id).subscribe((data: any) => {
            this.verifiers_list = data.list;
        });
    }

}
