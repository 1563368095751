import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';
import {LanguageService} from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class TransLoaderService implements TranslateLoader{
  contentHeader = new HttpHeaders({"Content-Type": "application/json","Access-Control-Allow-Origin":"*"});

  constructor(private http: HttpClient, public lngProvider: LanguageService) {}
  getTranslation(lang: string): Observable<any>{
    // var apiAddress = "https://smartrapport-s3.s3.eu-central-1.amazonaws.com/static/"+ lang+".json";
    var apiAddress = "https://smartrapport-s3.s3.eu-central-1.amazonaws.com/"+lang+".json";
    return Observable.create(observer => {
      this.http.get(apiAddress, { headers: this.contentHeader }).subscribe((res: Response) => {
            observer.next(res);
            observer.complete();
          },
          error => {
            //  failed to retrieve from api, switch to local
            this.http.get("/assets/i18n/"+lang+".json").subscribe((res: Response) => {
              observer.next(res);
              observer.complete();
            })
          }
      );
    });
  }
}
