import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(public helpers:HelpersService,public http: HttpClient, public tokenProvider: TokenService, public lngProvider: LanguageService) {
    }

    getEmployeeList(): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/list', {headers: headers});
    }

    addEmploye(data): Observable<any> {
        // console.log(data);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/add/employee', JSON.stringify(data), {headers: headers});
    }
    addEmployeeWithSupervisor(data,id): Observable<any> {
        // console.log(data);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/add/employee/'+id, JSON.stringify(data), {headers: headers});
    }

    getListEmployeeSupervisors(): Observable<any> {
        // console.log(data);
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/add/employee', {headers: headers});
    }


    activateDeactivateEmployee(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activate/deactivate/' + id, {headers: headers});
    }

    getEditEmployeeProfile(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/edit/profile/' + id, {headers: headers});

    }

    editEmployeeProfile(id, data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/employee/edit/profile/' + id, JSON.stringify(data), {headers: headers});
    }

    getSupervisor(id){
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/get/supervisor/employee/' + id, {headers: headers});
    }

    editEmployeeSupervisor(e_id, s_id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/change/supervisor/employee/' + e_id + '/'+s_id, JSON.stringify({}), {headers: headers});
    }

    employeeMonthlyHour(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        // ona report bese hours
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/report/' + id, {headers: headers});
    }

    employeeMonthlyHourYearly(id, year): Observable<any> {
        // ona report bese hours
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/report/' + id + '/' + year, {headers: headers});
    }

    chooseProject(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activity/choose/projects/' + id, {headers: headers});
    }

    projectActivity(emp_id, project_id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activity/project/' + emp_id + '/' + project_id, {headers: headers});
    }

    unverifiedWeeklyProjects(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/unverified/reports/' + id, {headers: headers});
    }

    unverifiedAbsentProjects(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activity/absent/reports/' + id, {headers: headers});
    }

    disapprovedReports(id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activity/disproved/reports/' + id, {headers: headers});
    }

    verifyReport(id, report_id): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/verify/report/' + id + '/' + report_id, {headers: headers});
    }

    disapproveReport(id, report_id, body): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/employee/disprove/report/' + id + '/' + report_id, JSON.stringify(body), {headers: headers});
    }

    generateYearlyReportPDF(id, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/year/report/pdf/' + id + '/' + year, {headers: headers});
    }

    generateYearlyReportPDFAsEmployee(year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/year/report/' + year, {headers: headers});
    }

    getReportDetails(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/single/project/pdf/' + id, {headers: headers});
    }

    getDataForWeeklyReport(id, week, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/weekly/report/' + id + '/' + week + '/' + year, {headers: headers});

    }

    getVacationRequests() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/list/vacations', {headers: headers});
    }

    getVacationRequestsNumber() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/get/my/employees/vacations/number ', {headers: headers});
    }

    verifyVacationRequest(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/approve/vacations/' + id, {headers: headers});
    }

    disapproveVacationRequest(id,body){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/employee/disprove/vacation/' + id,JSON.stringify(body), {headers: headers});
    }

    approveDeletionVacationAdmin(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/approve/vacation/delete/' + id,{headers: headers});
    }



    getSingleMonthPDF(month, year, emp) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        // console.log(headers)
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/single/report/' + month + '/' + year + '/' + emp, {headers: headers});

    }

    getRegieNumber() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/client/list', {headers: headers});
    }

    getEmployeeActivity(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/activity/page/' + id, {headers: headers});
    }

    generateWeeklyPDF(week, id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/weekly/report/pdf/' + week + '/' + id, {headers: headers});
    }

    generatePDF(report_id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/single/project/pdf/' + report_id, {headers: headers});
    }

    generateWeeklyEmployeePDF(week) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/employee/weekly/report/pdf/' + week, {headers: headers});

    }

    /**
     * Getting list of supervisors en/api/company/get/supervisor/list
     */
    getSupervisors(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/get/supervisor/list', {headers: headers});
    }

    cancelVerification(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/report/cancel/verification/' + id, {headers: headers});
    }

    cancelVerifiedVacation(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/vacation/cancel/verification/' + id, {headers: headers});
    }

    resetPassword(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/reset/password/' + id, {headers: headers});
    }
}
