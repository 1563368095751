
export class type {
    constructor(
        public id: string,
        public name: string,
    ) {
    }

}

export class regie_model {
    constructor(
        public id: string,
        public name: string,
        public value: any) {
    }
}

export class worker_model {
    days;

    constructor(
        public id: string,
        public name: string,
        public value: any,
        public disabled: boolean,
        public isDate: boolean,
    ) {
    }

}

export class RegieRapportService {
    type = [
        new type('1', 'Regie'),
        new type('2', 'Vertrag'),
        new type('3', 'Garantie'),
        new type('3', 'Arbeit fertig'),
        new type('3', 'Arbeit nicht fertig')
    ];
    data = {
        firme: {kunde: '', strasse: '', ort: '', kontakt: '', telefon: '', mailadresse: '',},
        arbeit: {kennwort: '', auftragsnummer: '', arbeitsort: '', strasse: '', ort: '', zustandig: '', telefon: '',},
        table2: {anlage: '', arbeit: '', cell1: '', cell2: '', cell3: '', cell4: '', cell5: '', cell6: ''},
        totalR: 0,
        monteur: {name: '', mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0,},
        uz: {mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0, totalR: 0},
        kmP: {mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0,},
        sWagen: {mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0, totalR: 0},
        swerkzeug0_1: {mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0, totalR: 0},
        swerkzeug0: {mon: '', tue: '', wed: '', thur: '', fri: '', sat: '', sun: '', ansatz: '', total: 0, totalR: 0},
        mot1: {
            totalR: 0,
            status: false,
            monteur1: {
                name: '',
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0
            },
            uz: {
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
                totalR: 0
            },
        },
        mot2: {
            status: false, totalR: 0,
            monteur2: {
                name: '',
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
            },
            uz: {
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
                totalR: 0
            },
        },
        mot3: {
            status: false, totalR: 0,
            monteur3: {
                name: '',
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
            },
            uz: {
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
                totalR: 0
            },
        },
        mot4: {
            status: false,
            totalR: 0,
            uz: {
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0,
                totalR: 0
            },
            monteur4: {
                name: '',
                mon: '',
                tue: '',
                wed: '',
                thur: '',
                fri: '',
                sat: '',
                sun: '',
                extra_1: '',
                extra_2: '',
                ansatz: '',
                total: 0
            },
        },
        material: {
            materialverbrauch1: '',
            materialverbrauch2: '',
            materialverbrauch3: '',
            materialverbrauch4: '',
            materialverbrauch5: '',
            materialverbrauch6: '',
            materialverbrauch7: '',
            materialverbrauch8: '',
            materialverbrauch9: '',
            materialverbrauch10: '',
            materialverbrauch11: '',
            materialverbrauch12: '',
            materialverbrauch13: '',
            materialverbrauch14: '',
            col1: '',
            col2: '',
            col3: 0,
            col4: '',
            col5: '',
            col6: 0,
            col7: '',
            col8: '',
            col9: 0,
            col10: '',
            col11: '',
            col12: 0,
            col13: '',
            col14: '',
            col15: 0,
            col16: '',
            col17: '',
            col18: 0,
            col19: '',
            col20: '',
            col21: 0,
            col22: '',
            col23: '',
            col24: 0,
            col25: '',
            col26: '',
            col27: 0,
            col28: '',
            col29: '',
            col30: 0,
            col31: '',
            col32: '',
            col33: 0,
            col34: '',
            col35: '',
            col36: 0,
            col37: '',
            col38: '',
            col39: 0,


        },
        kleinmaterial: {
            col1: '',
            col2: '',
            total: 0,
        },
        aufgenommen: '',
        kostenstelle: '',
        termin: '',
        regie: false,
        vertrag: false,
        garantie: false,
        arbeitfertig: false,
        arbeitnfertig: false,

    };
    constructor() {
    }



}
