import {Injectable} from '@angular/core';
import {LoaderService} from '../loader/loader.service';
import {PdfService} from '../pdf/pdf.service';
import {Observable, Subject} from 'rxjs';
import {TranslatedService} from '../translated/translated.service';
import {LanguageService} from '../language/language.service';
import {DatePipe, DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    pdfText: any = {};
    // public url = 'http://54.93.72.18/';
    private _listners = new Subject<any>();
    fontsize: number = 10;
    // public url = 'http://192.168.100.107:8000/';
    // public url = "http://192.168.100.141:8000/";
    public isConnected;

    public url = 'https://old.smartrapport.ch/';

    constructor(private loader: LoaderService,
                private datePipe: DatePipe,
                private decimalPipe: DecimalPipe,
                public pdfProvider: PdfService,
                private translated: TranslatedService,
                public lngProvider: LanguageService) {

        if (this.lngProvider.language == 'en') {
            this.pdfText = {
                page: 'Page',
                of: ' of ',
                text_25: '25%',
                text_50: '50%',
                text_100: '100%',
                project: 'Project',
                clientHours: 'Client Hours',
                startDate: 'Start Date',
                endDate: 'End Date',
                employee: 'Employee',
                weeklyReport: 'Weekly Report',
                name: '\n Name: ',
                persNo: '\n Pers.-Nr.:',
                clientCompanyName: 'Client\n',
                clientStreet: '\n',
                clientPostcode: '\n',
                clientCity: '',
                clientMail: '\n',
                clientPhone: '\n',
                projectID: 'Project ID: ',
                absence_projectName: 'Absence | Project Name: ',
                workingHours: 'Working Hours: ',
                totalHours: 'Total Hours: ',
                verifiedBy: 'Verified by: ',
                verifiedTime: 'Verified time: ',
                unverified: 'Unverified',
                total: 'Total',
                worker: 'Name',
                week: 'Week',
                productive: 'Productive',
                vacation: 'Vacation',
                sick: 'Sick',
                further_education: 'Further education',
                school: 'School',
                military: 'Military',
                move: 'Move',
                mutter: 'Mutterschaftsurlaub',
                other: 'Other absences',
                civil: 'Civil service',
                accident: 'Accident',
                wedding: 'Wedding',
                birth: 'Birth',
                funeral: 'Funeral',
                special_leave: 'Special leave',
                care_leave: 'Care leave',
                no_data: 'No Data',
                day: 'Day',
                month: 'Month',
                year: 'Year',
                expected: 'Expected',
                extra: 'Extra',
                minused: 'Overtime',
                compensated: 'Compensated',
                difference: 'Difference',
                jan: 'January',
                feb: 'February',
                mar: 'March',
                apr: 'April',
                may: 'May',
                jun: 'June',
                jul: 'July',
                aug: 'August',
                sep: 'September',
                oct: 'October',
                nov: 'November',
                dec: 'December',
                date: 'Date',
                employeeName: 'Employee Name',
                mon: 'MON',
                tue: 'TUE',
                wed: 'WED',
                thu: 'THU',
                fri: 'FRI',
                sat: 'SAT',
                sun: 'SUN',
                date_of_birth: 'Date of birth',
                personalNo: 'Personal No. ',
                social_number: 'Social No. '
            };
        } else {
            this.pdfText = {
                page: 'Seite',
                of: ' von ',
                text_25: '25%',
                text_50: '50%',
                text_100: '100%',
                project: 'Projekt',
                date_of_birth: 'Geburtsdatum',
                personalNo: 'Personalnummer ',
                weeklyReport: 'Wochenrapport: ',
                clientHours: 'Kundenstunden',
                startDate: 'Anfangsdatum',
                endDate: 'Enddatum',
                name: '\n Mitarbeiter/in: ',
                persNo: '\n Personalnr.:',
                clientCompanyName: 'Einsatzbetrieb',
                clientStreet: '\n',
                clientPostcode: '\n',
                clientCity: '',
                clientMail: '\n',
                clientPhone: '\n',
                projectID: 'Projekt-ID: ',
                absence_projectName: 'Abwesenheit | Projektname: ',
                workingHours: 'Arbeitsstuden: ',
                totalHours: 'Gesamtstunden: ',
                verifiedBy: 'Geprüft von: ',
                verifiedTime: 'Überprüfte Zeit: ',
                unverified: 'Nicht überprüft',
                worker: 'Mitarbeiter/in',
                personal: 'Pers.-Nr.',
                week: 'Woche',
                productive: 'Produktiv',
                vacation: 'Ferien',
                sick: 'Krank',
                further_education: 'Weiterbildung',
                school: 'Schule',
                military: 'Militär',
                move: 'Umzug',
                mutter: 'Mutterschaftsurlaub',
                other: 'Abwesenheiten',
                civil: 'Zivieldienst / Zivischutz',
                accident: 'Unfall',
                wedding: 'Hochzeit',
                birth: 'Geburt',
                funeral: 'Beerdigung',
                special_leave: 'Sonderurlaub',
                care_leave: 'Pflegeurlaub',
                total: 'Gesamt',
                no_data: 'Keine Angaben',
                day: 'Tag',
                month: 'Monat',
                year: 'Jahr',
                employee: 'Mitarbeiter/in',
                expected: 'Erwartete Arbeitsstunden',
                extra: 'Extra',
                minused: 'Überstundenauszahlung',
                compensated: 'Kompensation',
                difference: 'Differenz',
                jan: 'Januar',
                feb: 'Februar',
                mar: 'März',
                apr: 'April',
                may: 'Mai',
                jun: 'Juni',
                jul: 'Juli',
                aug: 'August',
                sep: 'September',
                oct: 'Oktober',
                nov: 'November',
                dec: 'Dezember',
                date: 'Datum',
                mon: 'MO',
                tue: 'DI',
                wed: 'MI',
                thu: 'DO',
                fri: 'FR',
                sat: 'SA',
                sun: 'SO',
                social_number: 'SV Nummer'
            };
        }
    }

    /**
     * Replacing comma with dot
     * @param data string ->
     */
    changeCommaWithDotDays(data) {
        if (data != '' && data.indexOf(',') !== -1) {
            data = data.replace(',', '.');
        }
        return data;
    }

    /**
     * Scrolling to selected item
     */
    scroll() {
        setTimeout(() => {
            document.getElementsByClassName('alert-radio-group')[0]
                .querySelector('[aria-checked="true"]')
                .scrollIntoView();
        }, 500);
    }

    /**
     * Removing comma and checking if days are not bigger than 24
     * @param event
     */
    checkValid(event) {
        if (event.srcElement.value == '') {
            return false;
        }

        event.srcElement.value = this.numberNormalForm(this.changeCommaWithDotDays(event.srcElement.value));
        return parseFloat(event.srcElement.value) >= 0 && parseFloat(event.srcElement.value) <= 24;

    }

    /**
     * Loading weeks from 1 to 52
     */
    loadAllWeeks() {
        let arr = [];
        for (let j = 1; j <= 52; j++) {
            arr.push(j);
        }
        return arr;
    }

    /**
     * Function that checks if value is 0
     * @param event number -> returns empty string
     */
    clearOnEdit(event) {
        if (event.srcElement.value == 0) {
            event.srcElement.value = '';
        }
    }

    isValid(p) {
        var phoneRe = /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/;
        var digits = p.replace(/\D/g, '');
        return phoneRe.test(digits);
    }

    /**
     * Get the number of week according to the today's date
     * @param d
     * @returns {number}
     */
    getWeek(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    /**
     * Rotating text vertically
     * @param text
     */
    writeRotatedText = function (text) {
        var ctx, canvas = document.createElement('canvas');
        canvas.width = 70;
        canvas.height = 970;
        ctx = canvas.getContext('2d');
        ctx.font = '72pt Arial';
        ctx.save();
        ctx.translate(70, 970);
        ctx.rotate(-0.5 * Math.PI);
        ctx.fillStyle = '#000';
        ctx.fillText(text, 0, 0);
        ctx.restore();
        return canvas.toDataURL();
    };

    /**
     * Return todays date
     * @returns {Date}
     */
    date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        var ddStr;
        var mmStr;

        if (dd < 10) {
            // @ts-ignore
            ddStr = '0' + dd;
        } else {
            ddStr = dd.toString();
        }

        if (mm < 10) {
            // @ts-ignore
            mmStr = '0' + mm;
        } else {
            mmStr = mm.toString();
        }

        // @ts-ignore
        return ddStr + '.' + mmStr + '.' + yyyy;
    }

    docDefinition: any;

    toISODate(d: Date) {
        return d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate());
    }

    /**
     * Generate pdf for single month
     * @param data object -> data that we are getting from the api
     * @param month array-> months
     * @param year string -> selected year
     * @param empMothlyObj object -> object of employee that we are getting from api
     */
    generate_signle_month_pdf(data, month, year, empMothlyObj) {
        let body: any = [];
        let pdfObj: any = data;
        let list = pdfObj.list;
        let total = pdfObj.total;
        body.push([
            {image: this.writeRotatedText(this.pdfText.day), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.expected), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.difference), fit: [80, 80], alignment: 'center'},

        ]);
        let i = 1;
        list.forEach(element => {
            // console.log(element);
            body.push(
                [
                    {'text': i, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : element.hours_worked,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.vacation == 0 ? '' : element.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.sick == 0 ? '' : element.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.further_education == 0 ? '' : element.further_education,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.school == 0 ? '' : element.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.military == 0 ? '' : element.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.move == 0 ? '' : element.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : element.mutterschaftsuralub,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.other == 0 ? '' : element.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.civil_service == 0 ? '' : element.civil_service,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.accident == 0 ? '' : element.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.wedding == 0 ? '' : element.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.birth == 0 ? '' : element.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.funeral == 0 ? '' : element.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.special_leave == 0 ? '' : element.special_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : element.care_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.hours_expected == 0 ? '' : element.hours_expected,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : element.get_total,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.extra == 0 ? '' : element.extra, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                ]);

            i++;
        });
        body.push([

            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_worked == 0 ? '' : total.hours_worked, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.vacation == 0 ? '' : total.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.sick == 0 ? '' : total.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.further_education == 0 ? '' : total.further_education,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.school == 0 ? '' : total.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.military == 0 ? '' : total.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.move == 0 ? '' : total.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.mutterschaftsuralub == 0 ? '' : total.mutterschaftsuralub,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.other == 0 ? '' : total.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.civil_service == 0 ? '' : total.civil_serivce, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.accident == 0 ? '' : total.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.wedding == 0 ? '' : total.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.birth == 0 ? '' : total.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.funeral == 0 ? '' : total.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.special_leave == 0 ? '' : total.special_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.care_leave == 0 ? '' : total.care_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_expected == 0 ? '' : total.hours_expected, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_total == 0 ? '' : total.get_total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_difference == 0 ? '' : total.get_difference, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
        ]);
        let monthName: string = '';
        switch (month.toString()) {
            case '1':
                monthName = this.pdfText.jan;
                break;
            case '2':
                monthName = this.pdfText.feb;
                break;
            case '3':
                monthName = this.pdfText.mar;
                break;
            case '4':
                monthName = this.pdfText.apr;
                break;
            case '5':
                monthName = this.pdfText.may;
                break;
            case '6':
                monthName = this.pdfText.jun;
                break;
            case '7':
                monthName = this.pdfText.jul;
                break;
            case '8':
                monthName = this.pdfText.aug;
                break;
            case '9':
                monthName = this.pdfText.sep;
                break;
            case '10':
                monthName = this.pdfText.oct;
                break;
            case '11':
                monthName = this.pdfText.nov;
                break;
            case '12':
                monthName = this.pdfText.dec;
                break;
            default:
                monthName = '';
        }
        console.log(empMothlyObj);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [10, 10, 10, 10],
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: 10},

                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year + '/' + monthName, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]

                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            table: {
                                widths: [60, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                                body: body
                            },
                            layout: {
                                paddingLeft: function (i, node) {
                                    return 0;
                                },
                                paddingRight: function (i, node) {
                                    return 0;
                                },
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]
                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (empMothlyObj.fullname.replace(' ', '_') + year + '_' + empMothlyObj.id).toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name;//(name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_signle_month_pdf_with_percentage(data, month, year, empMothlyObj) {
        let body: any = [];
        let pdfObj: any = data;
        let list = pdfObj.list;
        let total = pdfObj.total;
        body.push([
            {image: this.writeRotatedText(this.pdfText.day), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_25), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_50), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_100), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.expected), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.difference), fit: [80, 80], alignment: 'center'},

        ]);
        let i = 1;
        list.forEach(element => {
            console.log(element);
            body.push(
                [
                    {'text': i, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : this.decimalPipe.transform(element.hours_worked, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.vacation == 0 ? '' : this.decimalPipe.transform(element.vacation, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.sick == 0 ? '' : this.decimalPipe.transform(element.sick, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.further_education == 0 ? '' : this.decimalPipe.transform(element.further_education, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.school == 0 ? '' : this.decimalPipe.transform(element.school, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.military == 0 ? '' : this.decimalPipe.transform(element.military, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.move == 0 ? '' : this.decimalPipe.transform(element.move, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(element.mutterschaftsuralub, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.other == 0 ? '' : this.decimalPipe.transform(element.other, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.civil_service == 0 ? '' : this.decimalPipe.transform(element.civil_service, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.accident == 0 ? '' : this.decimalPipe.transform(element.accident, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.wedding == 0 ? '' : this.decimalPipe.transform(element.wedding, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.birth == 0 ? '' : this.decimalPipe.transform(element.birth, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.funeral == 0 ? '' : this.decimalPipe.transform(element.funeral, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.special_leave == 0 ? '' : this.decimalPipe.transform(element.special_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : this.decimalPipe.transform(element.care_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.extra_with_25 == 0 ? '' : this.decimalPipe.transform(element.extra_with_25, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.extra_with_50 == 0 ? '' : this.decimalPipe.transform(element.extra_with_50, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.extra_with_100 == 0 ? '' : this.decimalPipe.transform(element.extra_with_100, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.hours_expected == 0 ? '' : this.decimalPipe.transform(element.hours_expected, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : this.decimalPipe.transform(element.get_total, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.extra == 0 ? '' : this.decimalPipe.transform(element.extra, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                ]);

            i++;
        });
        body.push([

            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'right', 'unbreakable': true},
            {
                'text': total.hours_worked == 0 ? '' : this.decimalPipe.transform(total.hours_worked, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.vacation == 0 ? '' : this.decimalPipe.transform(total.vacation, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.sick == 0 ? '' : this.decimalPipe.transform(total.sick, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.further_education == 0 ? '' : this.decimalPipe.transform(total.further_education, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.school == 0 ? '' : this.decimalPipe.transform(total.school, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.military == 0 ? '' : this.decimalPipe.transform(total.military, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.move == 0 ? '' : this.decimalPipe.transform(total.move, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(total.mutterschaftsuralub, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.other == 0 ? '' : this.decimalPipe.transform(total.other, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.civil_service == 0 ? '' : this.decimalPipe.transform(total.civil_serivce, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.accident == 0 ? '' : this.decimalPipe.transform(total.accident, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.wedding == 0 ? '' : this.decimalPipe.transform(total.wedding, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.birth == 0 ? '' : this.decimalPipe.transform(total.birth, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.funeral == 0 ? '' : this.decimalPipe.transform(total.funeral, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.special_leave == 0 ? '' : this.decimalPipe.transform(total.special_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.care_leave == 0 ? '' : this.decimalPipe.transform(total.care_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.extra_with_25 == 0 ? '' : this.decimalPipe.transform(total.extra_with_25, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.extra_with_50 == 0 ? '' : this.decimalPipe.transform(total.extra_with_50, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.extra_with_100 == 0 ? '' : this.decimalPipe.transform(total.extra_with_100, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.hours_expected == 0 ? '' : this.decimalPipe.transform(total.hours_expected, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.get_total == 0 ? '' : this.decimalPipe.transform(total.get_total, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.get_difference == 0 ? '' : this.decimalPipe.transform(total.get_difference, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
        ]);
        let monthName: string = '';
        switch (month.toString()) {
            case '1':
                monthName = this.pdfText.jan;
                break;
            case '2':
                monthName = this.pdfText.feb;
                break;
            case '3':
                monthName = this.pdfText.mar;
                break;
            case '4':
                monthName = this.pdfText.apr;
                break;
            case '5':
                monthName = this.pdfText.may;
                break;
            case '6':
                monthName = this.pdfText.jun;
                break;
            case '7':
                monthName = this.pdfText.jul;
                break;
            case '8':
                monthName = this.pdfText.aug;
                break;
            case '9':
                monthName = this.pdfText.sep;
                break;
            case '10':
                monthName = this.pdfText.oct;
                break;
            case '11':
                monthName = this.pdfText.nov;
                break;
            case '12':
                monthName = this.pdfText.dec;
                break;
            default:
                monthName = '';
        }
        console.log(empMothlyObj);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [45, 60, 45, 60],
            footer: (currentPage, pageCount) => {
                return {
                    margin: [45, 25, 45, 0],
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            text: this.pdfText.page + ' ' + currentPage.toString() + this.pdfText.of + pageCount,
                            fontSize: 8
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            header: (currentPage, pageCount, pageSize) => {
                // you can apply any logic and return any valid pdfmake element
                if (currentPage == 1) return;

                return {
                    margin: [45, 10, 45, 0],
                    columns: [
                        {
                            alignment: 'left',

                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: this.fontsize},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: this.fontsize},
                                {text: this.pdfText.social_number + ': ', fontSize: this.fontsize},
                                {text: empMothlyObj.socialNumber + '\n', bold: true, fontSize: this.fontsize},
                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: this.fontsize},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: this.fontsize
                                },


                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: this.fontsize},
                                {text: year + '/' + monthName, bold: true, fontSize: this.fontsize},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: this.fontsize},
                                {text: this.date(), bold: true, fontSize: this.fontsize},
                            ],

                        }
                    ]
                };
            },
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: 10},

                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year + '/' + monthName, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]

                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 750,
                            table: {
                                widths: [60, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                                body: body
                            },
                            layout: {
                                paddingLeft: function (i, node) {
                                    return 0;
                                },
                                paddingRight: function (i, node) {
                                    return 0;
                                },
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]
                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (month + '_' + year + '_' + empMothlyObj.fullname.replace(' ', '_')).toString();
        let date = new Date();
        let dateName: string = date.getTime().toString();
        let filename: string;
        filename = name; //(name + dateName); //.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generate pdf for all months
     * @param monthlyList object -> data that we are getting from the api
     * @param total array-> data that we are getting from the api
     * @param year string -> selected year
     * @param empMothlyObj object -> object of employee that we are getting from api
     */
    generate_pdf_all_months(monthlyList, total, year, empMothlyObj) {
        let body: any = [];
        body.push([
            {image: this.writeRotatedText(this.pdfText.month), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.expected), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.minused), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.compensated), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText('Saldo'), fit: [80, 80], alignment: 'center'},

        ]);
        monthlyList.forEach(element => {

            body.push(
                [
                    {'text': element.month, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : element.hours_worked,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.vacation == 0 ? '' : element.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.sick == 0 ? '' : element.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.further_education == 0 ? '' : element.further_education,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.school == 0 ? '' : element.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.military == 0 ? '' : element.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.move == 0 ? '' : element.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : element.mutterschaftsuralub,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.other == 0 ? '' : element.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.civil_service == 0 ? '' : element.civil_service,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.accident == 0 ? '' : element.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.wedding == 0 ? '' : element.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.birth == 0 ? '' : element.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {'text': element.funeral == 0 ? '' : element.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.special_leave == 0 ? '' : element.special_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : element.care_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.hours_expected == 0 ? '' : element.hours_expected,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.minused == 0 ? '' : element.minused, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.compensated == 0 ? '' : element.compensated,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : element.get_total,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.extra == 0 ? '' : element.extra, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.get_2_total == 0 ? '' : element.get_2_total,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                ]);
        });
        body.push([
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_worked == 0 ? '' : total.hours_worked, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.vacation == 0 ? '' : total.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.sick == 0 ? '' : total.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.further_education == 0 ? '' : total.further_education,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.school == 0 ? '' : total.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.military == 0 ? '' : total.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.move == 0 ? '' : total.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.mutterschaftsuralub == 0 ? '' : total.mutterschaftsuralub,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.other == 0 ? '' : total.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.civil_service == 0 ? '' : total.civil_service, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.accident == 0 ? '' : total.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.wedding == 0 ? '' : total.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.birth == 0 ? '' : total.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.funeral == 0 ? '' : total.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.special_leave == 0 ? '' : total.special_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.care_leave == 0 ? '' : total.care_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_expected == 0 ? '' : total.hours_expected, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.minused == 0 ? '' : total.minused, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.compensated == 0 ? '' : total.compensated, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_total == 0 ? '' : total.get_total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_difference == 0 ? '' : total.get_difference, 'style': 'header', 'alignment': 'center', 'unbreakable': true},

        ]);
        console.log(empMothlyObj);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [10, 10, 10, 10],
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: 10},

                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }
                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            table: {
                                widths: [50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', '*', '*', 33],
                                body: body
                            },
                            layout: {
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (empMothlyObj.fullname.replace(' ', '_') + year + '_' + empMothlyObj.id).toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name;//(name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_pdf_all_months_with_percentage(monthlyList, total, year, empMothlyObj) {
        let body: any = [];
        body.push([
            {image: this.writeRotatedText(this.pdfText.month), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_25), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_50), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_100), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.expected), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.minused), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.compensated), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText('Saldo'), fit: [80, 80], alignment: 'center'},

        ]);
        monthlyList.forEach(element => {

            body.push(
                [
                    {'text': element.month, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : this.decimalPipe.transform(element.hours_worked, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.vacation == 0 ? '' : this.decimalPipe.transform(element.vacation, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.sick == 0 ? '' : this.decimalPipe.transform(element.sick, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.further_education == 0 ? '' : this.decimalPipe.transform(element.further_education, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.school == 0 ? '' : this.decimalPipe.transform(element.school, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.military == 0 ? '' : this.decimalPipe.transform(element.military, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.move == 0 ? '' : this.decimalPipe.transform(element.move, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(element.mutterschaftsuralub, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.other == 0 ? '' : this.decimalPipe.transform(element.other, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.civil_service == 0 ? '' : this.decimalPipe.transform(element.civil_service, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.accident == 0 ? '' : this.decimalPipe.transform(element.accident, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.wedding == 0 ? '' : this.decimalPipe.transform(element.wedding, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.birth == 0 ? '' : this.decimalPipe.transform(element.birth, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.funeral == 0 ? '' : this.decimalPipe.transform(element.funeral, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.special_leave == 0 ? '' : this.decimalPipe.transform(element.special_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : this.decimalPipe.transform(element.care_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.total_25 == 0 ? '' : this.decimalPipe.transform(element.total_25, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.total_50 == 0 ? '' : this.decimalPipe.transform(element.total_50, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.total_100 == 0 ? '' : this.decimalPipe.transform(element.total_100, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.hours_expected == 0 ? '' : this.decimalPipe.transform(element.hours_expected, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.minused == 0 ? '' : this.decimalPipe.transform(element.minused, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.compensated == 0 ? '' : this.decimalPipe.transform(element.compensated, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : this.decimalPipe.transform(element.get_total, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.extra == 0 ? '' : this.decimalPipe.transform(element.extra, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_2_total == 0 ? '' : this.decimalPipe.transform(element.get_2_total, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                ]);
        });
        body.push([
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.hours_worked == 0 ? '' : this.decimalPipe.transform(total.hours_worked, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.vacation == 0 ? '' : this.decimalPipe.transform(total.vacation, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.sick == 0 ? '' : this.decimalPipe.transform(total.sick, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.further_education == 0 ? '' : this.decimalPipe.transform(total.further_education, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.school == 0 ? '' : this.decimalPipe.transform(total.school, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.military == 0 ? '' : this.decimalPipe.transform(total.military, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.move == 0 ? '' : this.decimalPipe.transform(total.move, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(total.mutterschaftsuralub, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.other == 0 ? '' : this.decimalPipe.transform(total.other, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.civil_service == 0 ? '' : this.decimalPipe.transform(total.civil_service, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.accident == 0 ? '' : this.decimalPipe.transform(total.accident, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.wedding == 0 ? '' : this.decimalPipe.transform(total.wedding, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.birth == 0 ? '' : this.decimalPipe.transform(total.birth, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.funeral == 0 ? '' : this.decimalPipe.transform(total.funeral, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.special_leave == 0 ? '' : this.decimalPipe.transform(total.special_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.care_leave == 0 ? '' : this.decimalPipe.transform(total.care_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_25 == 0 ? '' : this.decimalPipe.transform(total.total_25, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_50 == 0 ? '' : this.decimalPipe.transform(total.total_50, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_100 == 0 ? '' : this.decimalPipe.transform(total.total_100, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.hours_expected == 0 ? '' : this.decimalPipe.transform(total.hours_expected, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.minused == 0 ? '' : this.decimalPipe.transform(total.minused, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.compensated == 0 ? '' : this.decimalPipe.transform(total.compensated, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.get_total == 0 ? '' : this.decimalPipe.transform(total.get_total, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.get_difference == 0 ? '' : this.decimalPipe.transform(total.get_difference, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },

        ]);
        console.log(empMothlyObj);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            // pageMargins: [10, 10, 10, 10],
            pageMargins: [45, 60, 45, 60],
            header: (currentPage, pageCount, pageSize) => {
                if (currentPage == 1) return;

                return {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: 10},

                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'left',
                            text: [
                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                            ],
                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]
                };
            },
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: empMothlyObj.fullname + '\n', bold: true, fontSize: 10},

                                {text: empMothlyObj.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: empMothlyObj.date_of_birth ? (this.datePipe.transform(empMothlyObj.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: empMothlyObj.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }
                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 794,
                            table: {
                                widths: [50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', '*', '*', 33],
                                body: body
                            },
                            layout: {
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (year + '_' + empMothlyObj.fullname.replace(' ', '_')).toString();
        let date = new Date();
        let dateName: string = date.getTime().toString();
        let filename: string;
        filename = name; //(name + dateName); //.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generate weekly pdf
     * @param weekReportObj object -> data that we are getting from api
     * @param pdfReport object -> data that we are getting from api
     * @param employee ojbect -> data that we are getting from api
     * @param year string-> current or selected ear
     */
    generate_pdf_weekly(weekReportObj, pdfReport, employee, year) {
        let element = weekReportObj;
        let body: any = [];
        let total = pdfReport.total;
        body.push([
            {image: this.writeRotatedText(this.pdfText.week), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
        ]);
        let i = 0;
        let date1 = (element.start_date && element.end_date) ? element.start_date + '-' + element.end_date : this.pdfText.no_data;
        body.push([
            {'text': 'KW' + element.week + '\n' + date1, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.hours_worked == 0 ? '' : element.hours_worked, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.vacation == 0 ? '' : element.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.sick == 0 ? '' : element.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': element.further_education == 0 ? '' : element.further_education,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': element.school == 0 ? '' : element.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.military == 0 ? '' : element.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.move == 0 ? '' : element.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': element.mutterschaftsuralub == 0 ? '' : element.mutterschaftsuralub,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': element.other == 0 ? '' : element.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': element.civil_service == 0 ? '' : element.civil_service,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': element.accident == 0 ? '' : element.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.wedding == 0 ? '' : element.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.birth == 0 ? '' : element.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.funeral == 0 ? '' : element.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': element.special_leave == 0 ? '' : element.special_leave,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': element.care_leave == 0 ? '' : element.care_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': element.get_total == 0 ? '' : element.get_total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},


        ]);
        body.push([
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_worked == 0 ? '' : total.hours_worked, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.vacation == 0 ? '' : total.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.sick == 0 ? '' : total.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.further_education == 0 ? '' : total.further_education,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.school == 0 ? '' : total.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.military == 0 ? '' : total.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.move == 0 ? '' : total.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.mutterschaftsuralub == 0 ? '' : total.mutterschaftsuralub,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.other == 0 ? '' : total.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.civil_service == 0 ? '' : total.civil_serivce, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.accident == 0 ? '' : total.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.wedding == 0 ? '' : total.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.birth == 0 ? '' : total.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.funeral == 0 ? '' : total.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.special_leave == 0 ? '' : total.special_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.care_leave == 0 ? '' : total.care_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_total == 0 ? '' : total.get_total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},

        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [10, 10, 10, 10],
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: employee.fullname + '\n', bold: true, fontSize: 10},

                                {text: employee.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: employee.date_of_birth ? (this.datePipe.transform(employee.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: employee.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]

                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 550,
                            table: {
                                'widths': ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 30],
                                body: body
                            },
                            layout: {
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (employee.fullname.replace(' ', '_') + year + '_' + employee.id).toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name; //(name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_pdf_weekly_with_percentage(weekReportObj, pdfReport, employee, year) {
        let element = weekReportObj;
        let body: any = [];
        let total = pdfReport.total;
        let widths_arr: any = ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
        let init_columns: any = [
            {image: this.writeRotatedText(this.pdfText.week), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            // {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},
        ];

        let date1 = (element.start_date && element.end_date) ? element.start_date + '-' + element.end_date : this.pdfText.no_data;
        let row1 = [
            {'text': 'KW' + element.week + '\n' + date1, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': element.hours_worked == 0 ? '' : this.decimalPipe.transform(element.hours_worked, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.vacation == 0 ? '' : this.decimalPipe.transform(element.vacation, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.sick == 0 ? '' : this.decimalPipe.transform(element.sick, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.further_education == 0 ? '' : this.decimalPipe.transform(element.further_education, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.school == 0 ? '' : this.decimalPipe.transform(element.school, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.military == 0 ? '' : this.decimalPipe.transform(element.military, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.move == 0 ? '' : this.decimalPipe.transform(element.move, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(element.mutterschaftsuralub, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.other == 0 ? '' : this.decimalPipe.transform(element.other, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.civil_service == 0 ? '' : this.decimalPipe.transform(element.civil_service, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.accident == 0 ? '' : this.decimalPipe.transform(element.accident, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.wedding == 0 ? '' : this.decimalPipe.transform(element.wedding, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.birth == 0 ? '' : this.decimalPipe.transform(element.birth, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.funeral == 0 ? '' : this.decimalPipe.transform(element.funeral, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.special_leave == 0 ? '' : this.decimalPipe.transform(element.special_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': element.care_leave == 0 ? '' : this.decimalPipe.transform(element.care_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },


        ];
        let row2 = [
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.hours_worked == 0 ? '' : this.decimalPipe.transform(total.hours_worked, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.vacation == 0 ? '' : this.decimalPipe.transform(total.vacation, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.sick == 0 ? '' : this.decimalPipe.transform(total.sick, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.further_education == 0 ? '' : this.decimalPipe.transform(total.further_education, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.school == 0 ? '' : this.decimalPipe.transform(total.school, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.military == 0 ? '' : this.decimalPipe.transform(total.military, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.move == 0 ? '' : this.decimalPipe.transform(total.move, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(total.mutterschaftsuralub, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.other == 0 ? '' : this.decimalPipe.transform(total.other, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.civil_service == 0 ? '' : this.decimalPipe.transform(total.civil_serivce, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.accident == 0 ? '' : this.decimalPipe.transform(total.accident, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.wedding == 0 ? '' : this.decimalPipe.transform(total.wedding, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.birth == 0 ? '' : this.decimalPipe.transform(total.birth, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.funeral == 0 ? '' : this.decimalPipe.transform(total.funeral, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.special_leave == 0 ? '' : this.decimalPipe.transform(total.special_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.care_leave == 0 ? '' : this.decimalPipe.transform(total.care_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },


        ];

        if (element.has_extra_25) {
            init_columns.push({image: this.writeRotatedText(this.pdfText.text_25), fit: [80, 80], alignment: 'center'});
            row1.push({
                'text': this.decimalPipe.transform(element.hours_extra_25, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            });
            row2.push({
                'text': this.decimalPipe.transform(total.get_total_25, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            });
            widths_arr.push('auto');
        }
        if (element.has_extra_50) {
            init_columns.push({image: this.writeRotatedText(this.pdfText.text_50), fit: [80, 80], alignment: 'center'});
            row1.push({
                'text': this.decimalPipe.transform(element.hours_extra_50, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            });
            row2.push({
                'text': this.decimalPipe.transform(total.get_total_50, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            });
            widths_arr.push('auto');
        }
        if (element.has_extra_100) {
            init_columns.push({image: this.writeRotatedText(this.pdfText.text_100), fit: [80, 80], alignment: 'center'});
            row1.push({
                'text': this.decimalPipe.transform(element.hours_extra_100, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            });
            row2.push({
                'text': this.decimalPipe.transform(total.get_total_100, '1.2-2')
                , 'style': 'header', 'alignment': 'right', 'unbreakable': true
            });
            widths_arr.push('auto');
        }

        widths_arr.push(30);
        init_columns.push({image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'});
        row1.push({
            'text': element.get_total == 0 ? '' : this.decimalPipe.transform(element.get_total, '1.2-2'),
            'style': 'header',
            'alignment': 'right',
            'unbreakable': true
        });
        row2.push({
            'text': total.get_total == 0 ? '' : this.decimalPipe.transform(total.get_total, '1.2-2'),
            'style': 'header',
            'alignment': 'right',
            'unbreakable': true
        });

        body.push(init_columns);
        body.push(row1);
        body.push(row2);

        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [10, 10, 10, 10],
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: employee.fullname + '\n', bold: true, fontSize: 10},

                                {text: employee.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: employee.date_of_birth ? (this.datePipe.transform(employee.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: employee.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: year, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]

                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 550,
                            table: {
                                'widths': widths_arr,
                                body: body
                            },
                            layout: {
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = ('KW' + element.week + '_' + year + '_' + employee.fullname.replace(' ', '_')).toString();
        let date = new Date();
        let dateName: string = date.getTime().toString();
        let filename: string;
        filename = name; //(name + dateName);//.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generate yearly pdf
     * @param pdfReport object -> data that we are getting from api
     * @param res response -> data that we are getting from api
     * @param yearChosen string -> current or selected year
     */
    generate_pdf_yearly(pdfReport, res, yearChosen) {
        pdfReport = res;
        let total = pdfReport.total;
        let body: any = [];
        body.push([
            {image: this.writeRotatedText(this.pdfText.week), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},

        ]);
        let i = 0;
        pdfReport.worker.fullname = pdfReport.worker.fullname ? pdfReport.worker.fullname : pdfReport.worker.get_fullname;
        pdfReport.list.forEach(element => {
            let date = (element.start_date && element.end_date) ? element.start_date + '-' + element.end_date : this.pdfText.no_data;
            if (i != 32) {
                body.push(
                    [
                        {'text': 'KW' + element.week + '\n' + date, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                        {
                            'text': element.hours_worked == 0 ? '' : element.hours_worked,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.vacation == 0 ? '' : element.vacation,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {'text': element.sick == 0 ? '' : element.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                        {
                            'text': element.further_education == 0 ? '' : element.further_education,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.school == 0 ? '' : element.school,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.military == 0 ? '' : element.military,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {'text': element.move == 0 ? '' : element.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                        {
                            'text': element.mutterschaftsuralub == 0 ? '' : element.mutterschaftsuralub,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.other == 0 ? '' : element.other,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.civil_service == 0 ? '' : element.civil_service,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.accident == 0 ? '' : element.accident,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.wedding == 0 ? '' : element.wedding,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.birth == 0 ? '' : element.birth,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.funeral == 0 ? '' : element.funeral,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.special_leave == 0 ? '' : element.special_leave,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.care_leave == 0 ? '' : element.care_leave,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },
                        {
                            'text': element.get_total == 0 ? '' : element.get_total,
                            'style': 'header',
                            'alignment': 'center',
                            'unbreakable': true
                        },


                    ]);
            } else {
                body.push([
                    {'text': 'KW' + element.week + '\n' + date, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : element.hours_worked,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.vacation == 0 ? '' : element.vacation,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.sick == 0 ? '' : element.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.further_education == 0 ? '' : element.further_education,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.school == 0 ? '' : element.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.military == 0 ? '' : element.military,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.move == 0 ? '' : element.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : element.mutterschaftsuralub,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.other == 0 ? '' : element.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.civil_service == 0 ? '' : element.civil_service,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.accident == 0 ? '' : element.accident,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.wedding == 0 ? '' : element.wedding,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {'text': element.birth == 0 ? '' : element.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.funeral == 0 ? '' : element.funeral,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.special_leave == 0 ? '' : element.special_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : element.care_leave,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : element.get_total,
                        'style': 'header',
                        'alignment': 'center',
                        'unbreakable': true,
                        'pageBreak': 'before'
                    },

                ]);
            }

        });
        body.push([
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.hours_worked == 0 ? '' : total.hours_worked, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.vacation == 0 ? '' : total.vacation, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.sick == 0 ? '' : total.sick, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.further_education == 0 ? '' : total.further_education,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.school == 0 ? '' : total.school, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.military == 0 ? '' : total.military, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.move == 0 ? '' : total.move, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.mutterschaftsuralub == 0 ? '' : total.mutterschaftsuralub,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.other == 0 ? '' : total.other, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.civil_service == 0 ? '' : total.civil_serivce,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.accident == 0 ? '' : total.accident, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.wedding == 0 ? '' : total.wedding, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.birth == 0 ? '' : total.birth, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.funeral == 0 ? '' : total.funeral, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.special_leave == 0 ? '' : total.special_leave,
                'style': 'header',
                'alignment': 'center',
                'unbreakable': true
            },
            {'text': total.care_leave == 0 ? '' : total.care_leave, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {'text': total.get_total == 0 ? '' : total.get_total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},

        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [10, 10, 10, 10],
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: pdfReport.worker.fullname + '\n', bold: true, fontSize: 10},

                                {text: pdfReport.worker.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: pdfReport.worker.date_of_birth ? (this.datePipe.transform(pdfReport.worker.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: pdfReport.worker.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: yearChosen, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 550,
                            table: {
                                'widths': [60, 25, 25, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                                body: body
                            },
                            layout: {
                                paddingLeft: function (i, node) {
                                    return 0;
                                },
                                paddingRight: function (i, node) {
                                    return 0;
                                },
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (pdfReport.worker.fullname.replace(' ', '_') + pdfReport.year + '_' + pdfReport.worker.id).toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name; //(name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_pdf_yearly_with_percentage(pdfReport, res, yearChosen) {
        pdfReport = res;
        let total = pdfReport.total;
        let body: any = [];
        body.push([
            {image: this.writeRotatedText(this.pdfText.week), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.productive), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.vacation), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.sick), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.further_education), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.school), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.military), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.move), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.mutter), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.other), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.civil), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.accident), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.wedding), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.birth), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.funeral), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.special_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.care_leave), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_25), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_50), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.text_100), fit: [80, 80], alignment: 'center'},
            {image: this.writeRotatedText(this.pdfText.total), fit: [80, 80], alignment: 'center'},

        ]);
        let i = 0;
        pdfReport.worker.fullname = pdfReport.worker.fullname ? pdfReport.worker.fullname : pdfReport.worker.get_fullname;
        pdfReport.list.forEach(element => {
            let date = (element.start_date && element.end_date) ? element.start_date + '-' + element.end_date : this.pdfText.no_data;
            if (i != 32) {
                body.push(
                    [
                        {'text': 'KW' + element.week + '\n' + date, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                        {
                            'text': element.hours_worked == 0 ? '' : this.decimalPipe.transform(element.hours_worked, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.vacation == 0 ? '' : this.decimalPipe.transform(element.vacation, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.sick == 0 ? '' : this.decimalPipe.transform(element.sick, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.further_education == 0 ? '' : this.decimalPipe.transform(element.further_education, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.school == 0 ? '' : this.decimalPipe.transform(element.school, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.military == 0 ? '' : this.decimalPipe.transform(element.military, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.move == 0 ? '' : this.decimalPipe.transform(element.move, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(element.mutterschaftsuralub, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.other == 0 ? '' : this.decimalPipe.transform(element.other, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.civil_service == 0 ? '' : this.decimalPipe.transform(element.civil_service, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.accident == 0 ? '' : this.decimalPipe.transform(element.accident, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.wedding == 0 ? '' : this.decimalPipe.transform(element.wedding, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.birth == 0 ? '' : this.decimalPipe.transform(element.birth, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.funeral == 0 ? '' : this.decimalPipe.transform(element.funeral, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.special_leave == 0 ? '' : this.decimalPipe.transform(element.special_leave, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.care_leave == 0 ? '' : this.decimalPipe.transform(element.care_leave, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': !element.has_extra_25 ? '' : this.decimalPipe.transform(element.hours_extra_25, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': !element.has_extra_50 ? '' : this.decimalPipe.transform(element.hours_extra_50, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': !element.has_extra_100 ? '' : this.decimalPipe.transform(element.hours_extra_100, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },
                        {
                            'text': element.get_total == 0 ? '' : this.decimalPipe.transform(element.get_total, '1.2-2'),
                            'style': 'header',
                            'alignment': 'right',
                            'unbreakable': true
                        },


                    ]);
            } else {
                body.push([
                    {'text': 'KW' + element.week + '\n' + date, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
                    {
                        'text': element.hours_worked == 0 ? '' : this.decimalPipe.transform(element.hours_worked, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.vacation == 0 ? '' : this.decimalPipe.transform(element.vacation, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.sick == 0 ? '' : this.decimalPipe.transform(element.sick, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.further_education == 0 ? '' : this.decimalPipe.transform(element.further_education, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.school == 0 ? '' : this.decimalPipe.transform(element.school, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.military == 0 ? '' : this.decimalPipe.transform(element.military, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.move == 0 ? '' : this.decimalPipe.transform(element.move, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(element.mutterschaftsuralub, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.other == 0 ? '' : this.decimalPipe.transform(element.other, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.civil_service == 0 ? '' : this.decimalPipe.transform(element.civil_service, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.accident == 0 ? '' : this.decimalPipe.transform(element.accident, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.wedding == 0 ? '' : this.decimalPipe.transform(element.wedding, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.birth == 0 ? '' : this.decimalPipe.transform(element.birth, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.funeral == 0 ? '' : this.decimalPipe.transform(element.funeral, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.special_leave == 0 ? '' : this.decimalPipe.transform(element.special_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.care_leave == 0 ? '' : this.decimalPipe.transform(element.care_leave, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': !element.has_extra_25 ? '' : this.decimalPipe.transform(element.hours_extra_25, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': !element.has_extra_50 ? '' : this.decimalPipe.transform(element.hours_extra_50, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': !element.has_extra_100 ? '' : this.decimalPipe.transform(element.hours_extra_100, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true
                    },
                    {
                        'text': element.get_total == 0 ? '' : this.decimalPipe.transform(element.get_total, '1.2-2'),
                        'style': 'header',
                        'alignment': 'right',
                        'unbreakable': true,
                        'pageBreak': 'before'
                    },

                ]);
            }

        });
        body.push([
            {'text': this.pdfText.total == 0 ? '' : this.pdfText.total, 'style': 'header', 'alignment': 'center', 'unbreakable': true},
            {
                'text': total.hours_worked == 0 ? '' : this.decimalPipe.transform(total.hours_worked, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.vacation == 0 ? '' : this.decimalPipe.transform(total.vacation, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.sick == 0 ? '' : this.decimalPipe.transform(total.sick, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.further_education == 0 ? '' : this.decimalPipe.transform(total.further_education, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.school == 0 ? '' : this.decimalPipe.transform(total.school, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.military == 0 ? '' : this.decimalPipe.transform(total.military, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.move == 0 ? '' : this.decimalPipe.transform(total.move, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.mutterschaftsuralub == 0 ? '' : this.decimalPipe.transform(total.mutterschaftsuralub, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.other == 0 ? '' : this.decimalPipe.transform(total.other, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.civil_service == 0 ? '' : this.decimalPipe.transform(total.civil_serivce, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.accident == 0 ? '' : this.decimalPipe.transform(total.accident, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.wedding == 0 ? '' : this.decimalPipe.transform(total.wedding, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.birth == 0 ? '' : this.decimalPipe.transform(total.birth, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.funeral == 0 ? '' : this.decimalPipe.transform(total.funeral, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.special_leave == 0 ? '' : this.decimalPipe.transform(total.special_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.care_leave == 0 ? '' : this.decimalPipe.transform(total.care_leave, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_25 == 0 ? '' : this.decimalPipe.transform(total.total_25, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_50 == 0 ? '' : this.decimalPipe.transform(total.total_50, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.total_100 == 0 ? '' : this.decimalPipe.transform(total.total_100, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },
            {
                'text': total.get_total == 0 ? '' : this.decimalPipe.transform(total.get_total, '1.2-2'),
                'style': 'header',
                'alignment': 'right',
                'unbreakable': true
            },

        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [45, 60, 45, 60],
            footer: (currentPage, pageCount) => {
                return {
                    margin: [45, 25, 45, 0],
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            text: this.pdfText.page + ' ' + currentPage.toString() + this.pdfText.of + pageCount,
                            fontSize: 8
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            header: (currentPage, pageCount, pageSize) => {
                // you can apply any logic and return any valid pdfmake element
                if (currentPage == 1) return;

                return {
                    margin: [45, 10, 45, 0],
                    columns: [
                        {
                            alignment: 'left',
                            // margin:[50,20,50,50],

                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: this.fontsize},
                                {text: pdfReport.worker.fullname + '\n', bold: true, fontSize: this.fontsize},
                                {text: this.pdfText.social_number + ': ', fontSize: this.fontsize},
                                {text: pdfReport.worker.socialNumber + '\n', bold: true, fontSize: this.fontsize},
                                {text: pdfReport.worker.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: this.fontsize},
                                {
                                    text: pdfReport.worker.date_of_birth ? (this.datePipe.transform(pdfReport.worker.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: this.fontsize
                                },


                            ],
                        },
                        {
                            alignment: 'center',
                            // margin:[50,10,50,10],
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: this.fontsize},
                                {text: yearChosen, bold: true, fontSize: this.fontsize},
                            ],

                        },
                        {
                            alignment: 'right',
                            // margin:[50,10,50,10],
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: this.fontsize},
                                {text: this.date(), bold: true, fontSize: this.fontsize},
                            ]
                        }
                    ]
                };
            },
            'content': [
                {
                    columns: [
                        {
                            alignment: 'left',
                            text: [
                                {text: this.pdfText.employee + ': ', fontSize: 10},
                                {text: pdfReport.worker.fullname + '\n', bold: true, fontSize: 10},

                                {text: pdfReport.worker.date_of_birth ? (this.pdfText.date_of_birth + ': ') : '', fontSize: 10},
                                {
                                    text: pdfReport.worker.date_of_birth ? (this.datePipe.transform(pdfReport.worker.date_of_birth, 'dd.MM.yyyy') + '\n') : '',
                                    bold: true,
                                    fontSize: 10
                                },

                                {text: this.pdfText.social_number + ': ', fontSize: 10},
                                {text: pdfReport.worker.socialNumber, bold: true, fontSize: 10},
                            ],
                        },
                        {
                            alignment: 'center',
                            text: [
                                {text: this.pdfText.year + ': ', fontSize: 10},
                                {text: yearChosen, bold: true, fontSize: 10},
                            ],

                        },
                        {
                            alignment: 'right',
                            text: [
                                {text: this.pdfText.date + ': ', fontSize: 10},
                                {text: this.date(), bold: true, fontSize: 10},
                            ],

                        }
                    ]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [[' '], [' ']]
                    },
                    layout: {

                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 0 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }

                },
                {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 750,
                            // width: 550,

                            table: {
                                headerRows: 1,
                                'widths': [60, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', 25, 25, 25, '*'],
                                body: body
                            },
                            layout: {
                                paddingLeft: function (i, node) {
                                    return 0;
                                },
                                paddingRight: function (i, node) {
                                    return 0;
                                },
                                hLineStyle: function (i, node) {
                                    if (i === 0 || i === node.table.body.length) {
                                        return null;
                                    }
                                    return {dash: {length: 2, space: 2}};
                                },
                                fillColor: function (i, node) {
                                    if (i == 0) {
                                        return '#fff';
                                    }
                                    return (i % 2 === 0) ? '#F2F2F2' : null;
                                },
                            }
                        },
                        {width: '*', text: ''},
                    ]

                }
            ],
            'styles': {
                'header': {
                    'fontSize': 8,
                    'bold': true,
                    'margin': [1, 1, 1, 1]
                },
            }
        };
        let name: string = (pdfReport.year + '_' + pdfReport.worker.fullname.replace(' ', '_')).toString();
        let date = new Date();
        let dateName: string = date.getTime().toString();
        let filename: string;
        filename = name;//(name + dateName);//.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generating pdf file name
     * @param res
     */
    name_generator(res) {
        var filename: string;
        var date = new Date();
        var dateName: string = date.getSeconds().toString();
        filename = (res).toString();// (name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        return filename;
    }

    /**
     * Generating weekly report pdf
     */
    generate_weekly_report_pdf(res, type) {
        console.log(res)
        var user;
        var filename;
        var company;
        var logo;
        console.log('1 ', res);
        if (type == 'company') {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.woche + this.pdfText.name + res.fullname;
            filename = this.name_generator(res.fullname);
            company = this.capitalize(res.company.companyName ? res.company.companyName : res.company.get_fullname) + '\n' + (res.company.street ? res.company.street +
                '\n' : '') + (res.company.zip ? (res.company.zip + ' ') : '') + (res.company.city ? (res.company.city + '\n') : '\n') + res.company.get_email + '\n'
                + (res.company.personalPhone ? res.company.personalPhone : '');
            logo = res.company.get_base64_image;
        } else {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.woche + this.pdfText.name + res.emp_cmp_data.fullname + this.pdfText.persNo + res.emp_cmp_data.personalId;
            filename = this.name_generator(res.emp_cmp_data.fullname);
            company = this.capitalize(res.emp_cmp_data.company.companyName ? res.emp_cmp_data.company.companyName : res.emp_cmp_data.company.get_fullname) + '\n' + (res.emp_cmp_data.company.street ? (res.emp_cmp_data.company.street +
                '\n') : '') + (res.emp_cmp_data.company.zip ? (res.emp_cmp_data.company.zip + ' ') : '') + (res.emp_cmp_data.company.city ? (res.emp_cmp_data.company.city + '\n') : '\n') + res.emp_cmp_data.company.get_email + '\n' + (res.emp_cmp_data.company.personalPhone ? res.emp_cmp_data.company.personalPhone : '');
            logo = res.emp_cmp_data.company.get_base64_image;
        }
        let body: any = [];
        let total = 0;
        body.push([
            {text: this.pdfText.projectID, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
            {text: this.pdfText.absence_projectName, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
            {text: this.pdfText.workingHours, colSpan: 7, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
            {},
            {},
            {},
            {},
            {},
            {},
            {text: this.pdfText.totalHours, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
            {text: this.pdfText.verifiedBy, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
            {text: this.pdfText.verifiedTime, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize},
        ]);
        body.push([
            '',
            '',
            {text: res.moDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.diDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.miDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.doDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.frDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.saDate, alignment: 'center', fontSize: this.fontsize},
            {text: res.soDate, alignment: 'center', fontSize: this.fontsize},
            '',
            '',
            '',
        ]);
        res.report_data.forEach(element => {
            total += element.total_wh;
            body.push([
                {'text': element.project ? element.project.projectId : '', 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.project ? element.project.projectName : element.kenwort, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.mo, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.di, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.mi, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.do, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.fr, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.sa, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.so, 'alignment': 'center', fontSize: this.fontsize},
                {'text': element.total_wh, 'alignment': 'center', fontSize: this.fontsize},

                {'text': element.verifiedBy ? element.verifiedBy : '', 'alignment': 'center', fontSize: this.fontsize},
                {
                    'text': this.datePipe.transform(element.verifiedTime ? element.verifiedTime : '', 'dd. MMMM yyyy HH:mm'),
                    'alignment': 'center', fontSize: this.fontsize
                },
            ]);
        });
        body.push([
            '',
            {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {text: total, alignment: 'center', fontSize: this.fontsize},
            '',
            '',
        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + logo,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': user,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        text: company,
                                        alignment: 'right'
                                    },
                                ]
                            },
                        ]

                    ]
                },
                {
                    table: {
                        'headerRows': 1,
                        'widths': [60, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: body
                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],
        };
        this.loader.fancyLoading('loading', () => {
            let pdfObject = this.pdfProvider.generate(this.docDefinition);
            if (pdfObject) {
                this.pdfProvider.download(pdfObject, filename);
            }
        });

    }

    generate_weekly_report_pdf_with_percentage(res, type) {
        var user;
        var filename;
        var company;
        var logo;
        console.log('1 ', res);
        if (type == 'company') {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.woche + this.pdfText.name + res.fullname;// + this.pdfText.persNo + res.personalId +'\n'+ this.pdfText.social_number + res.socialNumber;
            // filename = this.name_generator(res.fullname);
            filename = 'KW' + res.woche + '_' + res.fullname + '.pdf';//+ '_' + (new Date()).getTime() + '.pdf';
            company = this.capitalize(res.company.companyName ? res.company.companyName : res.company.get_fullname) + '\n' + (res.company.street ? res.company.street +
                '\n' : '') + (res.company.zip ? (res.company.zip + ' ') : '') + (res.company.city ? (res.company.city + '\n') : '\n') + res.company.get_email + '\n'
                + (res.company.personalPhone ? res.company.personalPhone : '');
            logo = res.company.get_base64_image;
        } else {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.woche + this.pdfText.name + res.emp_cmp_data.fullname + this.pdfText.persNo + res.emp_cmp_data.personalId;// + '\n' + this.pdfText.social_number + ': ' + res.emp_cmp_data.socialNumber;
            // filename = this.name_generator(res.emp_cmp_data.fullname);
            filename = filename = 'KW' + res.woche + '_' + res.emp_cmp_data.fullname + '.pdf';//+ '_' + (new Date()).getTime() + '.pdf';
            company = this.capitalize(res.emp_cmp_data.company.companyName ? res.emp_cmp_data.company.companyName : res.emp_cmp_data.company.get_fullname) + '\n' + (res.emp_cmp_data.company.street ? (res.emp_cmp_data.company.street +
                '\n') : '') + (res.emp_cmp_data.company.zip ? (res.emp_cmp_data.company.zip + ' ') : '') + (res.emp_cmp_data.company.city ? (res.emp_cmp_data.company.city + '\n') : '\n') + res.emp_cmp_data.company.get_email + '\n' + (res.emp_cmp_data.company.personalPhone ? res.emp_cmp_data.company.personalPhone : '');
            logo = res.emp_cmp_data.company.get_base64_image;
        }
        let body: any = [];
        let total = 0;
        body.push([
            {text: this.pdfText.projectID, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: this.pdfText.absence_projectName, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: this.pdfText.workingHours, colSpan: 7, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
            {},
            {},
            {},
            {},
            {},
            {},
            {text: this.pdfText.totalHours, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: this.pdfText.verifiedBy, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: this.pdfText.verifiedTime, style: 'tableHeader', alignment: 'center', fontSize: this.fontsize, bold: true},
        ]);
        body.push([
            '',
            '',
            {text: res.moDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.diDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.miDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.doDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.frDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.saDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            {text: res.soDate, alignment: 'center', fontSize: this.fontsize, bold: true},
            '',
            '',
            '',
        ]);
        let i = 0;
        let color = '';
        res.report_data.forEach(element => {
            color = i % 2 == 0 ? '#F2F2F2' : '';
            i++;
            total += element.total_wh;
            let rowspan = 1;
            let row_25 = [];
            let row_50 = [];
            let row_100 = [];
            let el = [
                {
                    fillColor: color,
                    'text': element.project ? element.project.projectId : '',
                    'alignment': 'center',
                    fontSize: this.fontsize,
                    rowSpan: 1,
                    bold: true
                },
                {
                    fillColor: color,
                    'text': element.project ? element.project.projectName : element.kenwort,
                    'alignment': 'center',
                    fontSize: this.fontsize,
                    bold: true
                },
                {fillColor: color, 'text': this.decimalPipe.transform(element.mo, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.di, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.mi, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.do, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.fr, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.sa, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {fillColor: color, 'text': this.decimalPipe.transform(element.so, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {
                    fillColor: color,
                    'text': this.decimalPipe.transform(element.total_wh, '1.2-2'),
                    'alignment': 'right',
                    fontSize: this.fontsize,
                    bold: true
                },

                {fillColor: color, 'text': element.verifiedBy ? element.verifiedBy : '', 'alignment': 'center', fontSize: this.fontsize},
                {
                    fillColor: color,
                    'text': element.verifiedTime ? this.datePipe.transform(element.verifiedTime, 'dd. MMMM yyyy HH:mm') : '',
                    'alignment': 'center',
                    fontSize: this.fontsize
                },
            ];

            if (element.has_25) {
                rowspan++;
                row_25 =
                    [
                        {fillColor: color, 'text': '', 'alignment': 'center', fontSize: this.fontsize},
                        {fillColor: color, 'text': '25%', 'alignment': 'center', fontSize: this.fontsize},
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mo_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.di_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mi_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.do_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.fr_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.sa_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.so_hours_with_extra_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.total_25, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize,
                            bold: true
                        },
                        {fillColor: color, text: ''},
                        {fillColor: color, text: ''},
                    ];
            }
            if (element.has_50) {
                rowspan++;
                row_50 =
                    [
                        {fillColor: color, 'text': '', 'alignment': 'center', fontSize: this.fontsize},
                        {fillColor: color, 'text': '50%', 'alignment': 'center', fontSize: this.fontsize},
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mo_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.di_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mi_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.do_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.fr_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.sa_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.so_hours_with_extra_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.total_50, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize,
                            bold: true
                        },
                        {fillColor: color, text: ''},
                        {fillColor: color, text: ''},
                    ];
            }
            if (element.has_100) {
                rowspan++;
                row_100 =
                    [
                        {fillColor: color, 'text': '', 'alignment': 'center', fontSize: this.fontsize},
                        {fillColor: color, 'text': '100%', 'alignment': 'center', fontSize: this.fontsize},
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mo_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.di_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.mi_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.do_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.fr_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.sa_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.so_hours_with_extra_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize
                        },
                        {
                            fillColor: color,
                            'text': this.decimalPipe.transform(element.total_100, '1.2-2'),
                            'alignment': 'right',
                            fontSize: this.fontsize,
                            bold: true
                        },
                        {fillColor: color, text: ''},
                        {fillColor: color, text: ''},

                    ];
            }

            if (rowspan != 0) {
                el[0].rowSpan = rowspan;
                el[10].rowSpan = rowspan;
                el[11].rowSpan = rowspan;
            }

            console.log(el);
            body.push(el);

            if (element.has_25) body.push(row_25);
            if (element.has_50) body.push(row_50);
            if (element.has_100) body.push(row_100);

        });
        body.push([
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', text: ''},
            {
                fillColor: i % 2 == 0 ? '#F2F2F2' : '',
                'text': this.pdfText.total,
                'alignment': 'center',
                fontSize: this.fontsize,
                bold: true
            },
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', 'text': '', 'alignment': 'center'},
            {
                fillColor: i % 2 == 0 ? '#F2F2F2' : '',
                text: this.decimalPipe.transform(total, '1.2-2'),
                alignment: 'right',
                fontSize: this.fontsize,
                bold: true
            },
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', text: ''},
            {fillColor: i % 2 == 0 ? '#F2F2F2' : '', text: ''},
        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + logo,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': user,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        text: company,
                                        alignment: 'right'
                                    },
                                ]
                            },
                        ]

                    ]
                },
                {
                    table: {
                        'headerRows': 1,
                        'widths': [60, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: body
                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0 || i == 1) {
                                return '#fff';
                            }
                            // return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],
        };
        this.loader.fancyLoading('loading', () => {
            let pdfObject = this.pdfProvider.generate(this.docDefinition);
            if (pdfObject) {
                this.pdfProvider.download(pdfObject, filename);
            }
        });

    }

    /**
     * Generate for current report pdf
     */
    generate_current_report_pdf(res, page_title, type) {
        if (res.report_data.kenwort) {
            var absentReason = res.report_data.kenwort;
        }
        var user;
        var filename;
        var company;
        var logo;
        // company report
        if (type == 'company') {
            console.log('2 ', res);
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.company.get_fullname;
            filename = (res.report_data.company.get_fullname + '_' + page_title + '.pdf').toString();
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            logo = res.report_data.company.get_base64_image;
            console.log(company);
        }
        // basic employee report
        else if (type == 'basic') {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.employee.fullname + this.pdfText.persNo + res.report_data.employee.personalId;
            filename = this.name_generator(res.report_data.employee.fullname);
            // company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + res.report_data.company.street ? res.report_data.company.street : '' +
            // '\n' + res.report_data.company.zip ? res.report_data.company.zip : '' + ' ' + res.report_data.company.city ? res.report_data.company.city : '' + '\n' + res.report_data.company.get_email ? res.report_data.company.get_email : '' + '\n' + res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '';
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            logo = res.report_data.company.get_base64_image;
        }
        //company employee report
        else {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.employee.fullname + this.pdfText.persNo + res.report_data.employee.personalId;
            logo = res.report_data.company.get_base64_image;
            // company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + res.report_data.company.street ? res.report_data.company.street : '' +
            // '\n' + res.report_data.company.zip ? res.report_data.company.zip : '' + ' ' + res.report_data.company.city ? res.report_data.company.city : '' + '\n' + res.report_data.company.get_email ? res.report_data.company.get_email : '' + '\n' + res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '';
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            filename = this.name_generator(res.report_data.employee.fullname + '_' + page_title + '_');
        }
        let verifiedBy = res.report_data.verifiedBy ? res.report_data.verifiedBy : '';
        let verifiedTime = res.report_data.verifiedTime ? res.report_data.verifiedTime : '';
        this.docDefinition = {
            pageOrientation: 'landscape',
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            text: 'www.smartrapport.ch'
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            content: [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + logo,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': user,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: res.report_data.project ? (this.pdfText.clientCompanyName + '\n' + res.report_data.project.client.clientCompanyName + this.pdfText.clientStreet + res.report_data.project.client.street +
                                            this.pdfText.clientPostcode + res.report_data.project.client.zip + ' ' + this.pdfText.clientCity + res.report_data.project.client.city + this.pdfText.clientMail + res.report_data.project.client.clientEmail + this.pdfText.clientPhone + res.report_data.project.client.clientPhone) : '',
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        width: 160,
                                        'text': company,
                                        alignment: 'right'

                                    },
                                ]
                            },
                        ]
                    ]
                },
                {
                    table: {
                        'headerRows': 1,
                        'widths': [60, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        'body': [
                            [
                                {'text': this.pdfText.projectID, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': this.pdfText.absence_projectName, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': this.pdfText.workingHours, 'colSpan': 7, 'alignment': 'center', fontSize: this.fontsize},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {'text': this.pdfText.totalHours, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': this.pdfText.verifiedBy, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': this.pdfText.verifiedTime, 'alignment': 'center', fontSize: this.fontsize},
                            ],
                            [
                                '',
                                '',
                                {'text': res.report_data.moDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.diDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.miDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.doDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.frDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.saDate, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.soDate, 'alignment': 'center', fontSize: this.fontsize},
                                '',
                                '',
                                '',
                            ],
                            [
                                {
                                    'text': res.report_data.project ? res.report_data.project.projectId : '',
                                    'alignment': 'center', fontSize: this.fontsize
                                },
                                {
                                    'text': res.report_data.project ? res.report_data.project.projectName : absentReason,
                                    'alignment': 'center', fontSize: this.fontsize
                                },
                                {'text': res.report_data.mo, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.di, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.mi, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.do, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.fr, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.sa, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.so, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.total_wh, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': verifiedBy, 'alignment': 'center', fontSize: this.fontsize},
                                {
                                    'text': this.datePipe.transform(verifiedTime, 'dd. MMMM yyyy HH:mm'),
                                    'alignment': 'center',
                                    fontSize: this.fontsize
                                },

                            ],
                            [
                                '',
                                {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.mo, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.di, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.mi, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.do, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.fr, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.sa, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.so, 'alignment': 'center', fontSize: this.fontsize},
                                {'text': res.report_data.total_wh, 'alignment': 'center', fontSize: this.fontsize},
                                '',
                                '',
                            ],

                        ],

                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],

        };
        this.loader.fancyLoading('loading', () => {
            let pdfObject = this.pdfProvider.generate(this.docDefinition);
            if (pdfObject) {
                this.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_current_report_pdf_with_percentage(res, page_title, type) {
        if (res.report_data.kenwort) {
            var absentReason = res.report_data.kenwort;
        }
        var user;
        var filename;
        var company;
        var logo;
        // company report
        if (type == 'company') {
            console.log('2 ', res);
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.company.get_fullname;// + this.pdfText.social_number + res.report_data.company.social_number;
            filename = (page_title + '_' + res.report_data.company.get_fullname + '.pdf').toString();//+ '_' + (new Date()).getTime() + '.pdf').toString();
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            logo = res.report_data.company.get_base64_image;
            console.log(company);
        }
        // basic employee report
        else if (type == 'basic') {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.employee.fullname + this.pdfText.persNo + res.report_data.employee.personalId;// + '\n' + this.pdfText.social_number + ': ' + res.report_data.employee.socialNumber;
            filename = ('KW' + res.report_data.woche + '_' + res.report_data.employee.fullname + '.pdf').toString();//+ '_' + (new Date()).getTime() + '.pdf').toString();//this.name_generator(res.report_data.employee.fullname);
            // company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + res.report_data.company.street ? res.report_data.company.street : '' +
            // '\n' + res.report_data.company.zip ? res.report_data.company.zip : '' + ' ' + res.report_data.company.city ? res.report_data.company.city : '' + '\n' + res.report_data.company.get_email ? res.report_data.company.get_email : '' + '\n' + res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '';
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            logo = res.report_data.company.get_base64_image;
        }
        //company employee report
        else {
            user = this.pdfText.weeklyReport + res.current_year + '\n' + this.pdfText.week + ': ' + res.report_data.woche + this.pdfText.name + res.report_data.employee.fullname + this.pdfText.persNo + res.report_data.employee.personalId;//+ '\n' + this.pdfText.social_number + ': ' + res.report_data.employee.socialNumber;
            logo = res.report_data.company.get_base64_image;
            // company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + res.report_data.company.street ? res.report_data.company.street : '' +
            // '\n' + res.report_data.company.zip ? res.report_data.company.zip : '' + ' ' + res.report_data.company.city ? res.report_data.company.city : '' + '\n' + res.report_data.company.get_email ? res.report_data.company.get_email : '' + '\n' + res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '';
            company = this.capitalize(res.report_data.company.companyName ? res.report_data.company.companyName : res.report_data.company.get_fullname) + '\n' + (res.report_data.company.street ? (res.report_data.company.street + '\n') : '') + (res.report_data.company.zip ? (res.report_data.company.zip + ' ') : '') + (res.report_data.company.city ? (res.report_data.company.city + '\n') : '\n') + res.report_data.company.get_email + '\n' + (res.report_data.company.personalPhone ? res.report_data.company.personalPhone : '');
            // filename = this.name_generator(res.report_data.employee.fullname + '_' + page_title + '_');
            // filename = (page_title + '_' + res.report_data.employee.fullname + '_' + (new Date()).getTime() + '.pdf').toString();
            filename = (page_title + '_' + res.report_data.employee.fullname + '.pdf').toString();
        }
        let verifiedBy = res.report_data.verifiedBy ? res.report_data.verifiedBy : '';
        let verifiedTime = res.report_data.verifiedTime ? res.report_data.verifiedTime : '';

        let b: any = [
            [
                {'text': this.pdfText.projectID, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': this.pdfText.absence_projectName, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': this.pdfText.workingHours, 'colSpan': 7, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {},
                {},
                {},
                {},
                {},
                {},
                {'text': this.pdfText.totalHours, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': this.pdfText.verifiedBy, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': this.pdfText.verifiedTime, 'alignment': 'center', fontSize: this.fontsize, bold: true},

            ],
            [
                {text: ''},
                '',
                {'text': res.report_data.moDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.diDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.miDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.doDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.frDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.saDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {'text': res.report_data.soDate, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                {text: ''},
                '',
                '',

            ],

            [
                {
                    'text': res.report_data.project ? res.report_data.project.projectId : '',
                    'alignment': 'center', fontSize: this.fontsize, rowSpan: 1, bold: true
                },
                {
                    'text': res.report_data.project ? res.report_data.project.projectName : absentReason,
                    'alignment': 'center', fontSize: this.fontsize, bold: true
                },
                {'text': this.decimalPipe.transform(res.report_data.mo, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.di, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.mi, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.do, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.fr, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.sa, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {'text': this.decimalPipe.transform(res.report_data.so, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {
                    'text': this.decimalPipe.transform(res.report_data.total_wh, '1.2-2'),
                    'alignment': 'right',
                    fontSize: this.fontsize,
                    bold: true
                },
                {'text': res.report_data.verified ? res.report_data.verifiedBy : '', 'alignment': 'center', fontSize: this.fontsize},
                {
                    'text': res.report_data.verified ? this.datePipe.transform(res.report_data.verifiedTime, 'dd-MM-yyyy | HH:mm') : '',
                    'alignment': 'center',
                    fontSize: this.fontsize
                },

            ]


        ];

        let rowspan = 1;
        if (res.report_data.has_25) {
            rowspan++;
            b.push(
                [
                    {'text': '', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': '25%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(res.report_data.mo_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.di_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.mi_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.do_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.fr_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.sa_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.so_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.total_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize,
                        bold: true
                    },
                    '',
                    '',
                ],);
        }

        if (res.report_data.has_50) {
            rowspan++;
            b.push(
                [
                    {'text': '', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': '50%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(res.report_data.mo_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.di_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.mi_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.do_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.fr_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.sa_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.so_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.total_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize,
                        bold: true
                    },
                    '',
                    '',
                ],);

        }

        if (res.report_data.has_100) {
            rowspan++;
            b.push(
                [
                    {'text': '', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': '100%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(res.report_data.mo_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.di_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.mi_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.do_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.fr_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.sa_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.so_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(res.report_data.total_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize,
                        bold: true
                    },
                    '',
                    '',
                ],);
        }
        b[2][0].rowSpan = rowspan;
        b[2][10].rowSpan = rowspan;
        b[2][11].rowSpan = rowspan;

        b.push(
            [
                '',
                {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                '',//{'text': this.decimalPipe.transform(res.report_data.mo, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.di, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.mi, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.do, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.fr, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.sa, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                '',//{'text': this.decimalPipe.transform(res.report_data.so, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                {
                    'text': this.decimalPipe.transform(res.report_data.total_wh_with_extra, '1.2-2'),
                    'alignment': 'right',
                    fontSize: this.fontsize,
                    bold: true
                },
                '',
                '',
            ],);

        this.docDefinition = {
            pageOrientation: 'landscape',
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            text: 'www.smartrapport.ch'
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            content: [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + logo,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': user,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: res.report_data.project ? (this.pdfText.clientCompanyName + '\n' + res.report_data.project.client.clientCompanyName + this.pdfText.clientStreet + res.report_data.project.client.street +
                                            this.pdfText.clientPostcode + res.report_data.project.client.zip + ' ' + this.pdfText.clientCity + res.report_data.project.client.city + this.pdfText.clientMail + res.report_data.project.client.clientEmail + this.pdfText.clientPhone + res.report_data.project.client.clientPhone) : '',
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        width: 160,
                                        'text': company,
                                        alignment: 'right'

                                    },
                                ]
                            },
                        ]
                    ]
                },
                {
                    table: {
                        'headerRows': 1,
                        'widths': [60, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        'body': b

                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === 1 || i === 2 || i === node.table.body.length || i === node.table.body.length - 1) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],

        };
        this.loader.fancyLoading('loading', () => {
            let pdfObject = this.pdfProvider.generate(this.docDefinition);
            if (pdfObject) {
                this.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generate pdf for project details
     */
    generate_project_pdf(pdfReport) {

        let body: any = [];
        body.push([
            {'text': this.pdfText.name, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.week, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.mon, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.tue, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.wed, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.thu, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.fri, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.sat, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.sun, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.verifiedBy, 'alignment': 'center', fontSize: this.fontsize},
            {'text': this.pdfText.verifiedTime, 'alignment': 'center', fontSize: this.fontsize},

        ]);
        pdfReport.list.forEach(element => {
            body.push(
                [
                    {
                        'text': element.employee ? element.employee.fullname : pdfReport.company.get_fullname,

                        'alignment': 'center'
                        , fontSize: this.fontsize
                    },
                    {'text': element.woche, 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.moDate + '\n' + element.mo + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.diDate + '\n' + element.di + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.miDate + '\n' + element.mi + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.doDate + '\n' + element.do + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.frDate + '\n' + element.fr + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.saDate + '\n' + element.sa + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.soDate + '\n' + element.so + ' h', 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.total_wh, 'alignment': 'center', fontSize: this.fontsize},
                    {'text': element.verified ? element.verifiedBy : '', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': element.verified ? this.datePipe.transform(element.verifiedTime, 'dd-MM-yyyy | HH:mm') : '',

                        'alignment': 'center', fontSize: this.fontsize
                    },

                ]);


        });
        body.push([
            {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': pdfReport.total, 'alignment': 'center', fontSize: this.fontsize},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},

        ]);
        this.docDefinition = {
            'pageOrientation': 'landscape',
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {width: '*', text: ''},
                        {
                            width: 'auto',
                            text: 'www.smartrapport.ch'
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            'content': [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    }, {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + pdfReport.company.get_base64_image,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': this.pdfText.project + ': ' + pdfReport.project.projectName + '\n' + pdfReport.project.client.clientCompanyName + '\n' + pdfReport.project.client.street + '\n' + pdfReport.project.client.zip + ' ' + pdfReport.project.client.city,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        'text': this.capitalize((pdfReport.company.companyName ? pdfReport.company.companyName : pdfReport.company.get_fullname)) + '\n' + (pdfReport.company.street ? (pdfReport.company.street +
                                            '\n') : '') + (pdfReport.company.zip ? (pdfReport.company.zip + ' ') : '') + (pdfReport.company.city ? (pdfReport.company.city + '\n') : '') + pdfReport.company.get_email + '\n' + (pdfReport.company.personalPhone ? pdfReport.company.personalPhone : ''),
                                        alignment: 'right'
                                    },
                                ]
                            },
                        ]
                    ]
                },
                {
                    'table': {
                        'headerRows': 1,
                        'widths': [66, 40, 55, 55, 55, 55, 55, 55, 55, 42, 58, 61],
                        'body': body
                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],

        };
        let name: string = (pdfReport.project.projectName.replace(' ', '_') + pdfReport.project.projectId).toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name;//(name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    generate_project_pdf_with_percentage(pdfReport) {

        let body: any = [];
        body.push([
            {bold: true, 'text': this.pdfText.name, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.week, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.mon, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.tue, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.wed, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.thu, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.fri, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.sat, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.sun, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.verifiedBy, 'alignment': 'center', fontSize: this.fontsize},
            {bold: true, 'text': this.pdfText.verifiedTime, 'alignment': 'center', fontSize: this.fontsize},

        ]);
        pdfReport.list.forEach(element => {
            body.push(
                [
                    {
                        'text': element.employee ? element.employee.fullname : pdfReport.company.get_fullname,

                        'alignment': 'center'
                        , fontSize: this.fontsize, bold: true, rowSpan: 5
                    },
                    {'text': element.woche, 'alignment': 'center', fontSize: this.fontsize, bold: true},
                    {
                        'text':
                            [
                                {
                                    text: element.moDate ,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.diDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.di, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.miDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.mi, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.doDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.do, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.frDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.fr, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.saDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.sa, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: element.soDate,
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                    bold: true
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.so, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {
                        'text':
                            [
                                {
                                    text: '',
                                    'alignment': 'center',
                                    fontSize: this.fontsize,
                                },
                                // {
                                //     text: this.decimalPipe.transform(element.total_wh, '1.2-2'),
                                //     'alignment': 'right',
                                //     fontSize: this.fontsize
                                // }
                            ]
                    },
                    {'text': element.verified ? element.verifiedBy : '', 'alignment': 'center', fontSize: this.fontsize, rowSpan: 5},
                    {
                        'text': element.verified ? this.datePipe.transform(element.verifiedTime, 'dd-MM-yyyy | HH:mm') : '',

                        'alignment': 'center', fontSize: this.fontsize, rowSpan: 5
                    },

                ],
                [
                    '',
                    '',
                    {
                        text: this.decimalPipe.transform(element.mo, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.di, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.mi, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.do, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.fr, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.sa, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.so, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        text: this.decimalPipe.transform(element.total_wh, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },

                    '',
                    '',
                ],
                [
                    '',
                    {'text': '25%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(element.mo_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.di_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.mi_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.do_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.fr_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.sa_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.so_hours_with_extra_25, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {'text': this.decimalPipe.transform(element.total_25, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                    '',
                    '',
                ],
                [
                    '',
                    {'text': '50%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(element.mo_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.di_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.mi_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.do_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.fr_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.sa_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.so_hours_with_extra_50, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {'text': this.decimalPipe.transform(element.total_50, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                    '',
                    '',
                ],
                [
                    '',
                    {'text': '100%', 'alignment': 'center', fontSize: this.fontsize},
                    {
                        'text': this.decimalPipe.transform(element.mo_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.di_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.mi_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.do_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.fr_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.sa_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {
                        'text': this.decimalPipe.transform(element.so_hours_with_extra_100, '1.2-2'),
                        'alignment': 'right',
                        fontSize: this.fontsize
                    },
                    {'text': this.decimalPipe.transform(element.total_100, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize},
                    '',
                    '',
                ]
            );


        });
        body.push([
            {'text': this.pdfText.total, 'alignment': 'center', fontSize: this.fontsize, bold: true},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},
            {'text': this.decimalPipe.transform(pdfReport.total, '1.2-2'), 'alignment': 'right', fontSize: this.fontsize, bold: true},
            {'text': '', 'alignment': 'center'},
            {'text': '', 'alignment': 'center'},

        ]);
        this.docDefinition = {
            'pageOrientation': 'landscape',
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        {
                            width: '*',
                            text: this.pdfText.page + ' ' + currentPage.toString() + this.pdfText.of + pageCount.toString(),
                            fontSize: 8,
                            alignment: 'left',
                            margin: [45, 0, 45, 0]
                        },
                        {
                            width: 'auto',
                            text: 'www.smartrapport.ch'
                        },
                        {width: '*', text: ''},
                    ]
                };
            },
            'content': [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    }, {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        margin: [0, 0, 20, 20],
                                        alignment: 'right',
                                        image: 'data:image/jpeg;base64,' + pdfReport.company.get_base64_image,
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': this.pdfText.project + ': ' + pdfReport.project.projectName + '\n' + pdfReport.project.client.clientCompanyName + '\n' + pdfReport.project.client.street + '\n' + pdfReport.project.client.zip + ' ' + pdfReport.project.client.city,
                                        margin: [0, 0, 0, 50],
                                    },
                                    {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 160,
                                        'text': this.capitalize((pdfReport.company.companyName ? pdfReport.company.companyName : pdfReport.company.get_fullname)) + '\n' + (pdfReport.company.street ? (pdfReport.company.street +
                                            '\n') : '') + (pdfReport.company.zip ? (pdfReport.company.zip + ' ') : '') + (pdfReport.company.city ? (pdfReport.company.city + '\n') : '') + pdfReport.company.get_email + '\n' + (pdfReport.company.personalPhone ? pdfReport.company.personalPhone : ''),
                                        alignment: 'right'
                                    },
                                ]
                            },
                        ]
                    ]
                },
                {
                    'table': {
                        // 'headerRows': 1,
                        'widths': [66, 40, 55, 55, 55, 55, 55, 55, 55, 42, 58, 61],
                        'body': body
                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length || i === node.table.body.length - 1 || i%5===1) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],

        };
        let name: string = (pdfReport.project.projectName.replace(' ', '_') + '_' + pdfReport.project.client.clientCompanyName).toString();
        let date = new Date();
        let dateName: string = date.getTime().toString();
        let filename: string;
        filename = name;//(name + dateName);//.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Generate statistics project pdf
     */
    generate_statistics_project_pdf(project, company) {
        let body: any = [];
        body.push([
            {text: this.pdfText.employee, style: 'tableHeader', alignment: 'center'},
            {text: this.pdfText.totalHours, style: 'tableHeader', alignment: 'center'},
        ]);
        project.employees.forEach(e => {
            body.push([
                    {'text': e.name ? e.name : e.fullname, 'alignment': 'center'},
                    {'text': e.total_hours, 'alignment': 'center'},
                ]
            );

        });
        body.push([
            {'text': 'Total', style: 'tableHeader', 'alignment': 'center'},
            {text: project.working_hours, style: 'tableHeader', alignment: 'center'},

        ]);
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        [
                            {
                                columns: [
                                    {
                                        'width': 340,
                                        text: ''
                                    }, {
                                        'width': 300,
                                        text: ''
                                    },
                                    {
                                        width: 180,
                                        margin: [0, 0, 20, 20],
                                        image: 'data:image/jpeg;base64,' + company.get_base64_image,
                                        alignment: 'right',
                                        fit: [70, 70]
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        'width': 300,
                                        'text': this.pdfText.project + ': ' + project.project_name
                                    },
                                    {
                                        'width': 300,
                                        text: {
                                            'width': 300,
                                            text: ''
                                        },
                                    },
                                    {
                                        width: 180,
                                        text: this.capitalizeFirst(company.companyName ? company.companyName : company.get_fullname) + '\n' + (company.street ? (company.street +
                                            '\n') : '') + (company.zip ? (company.zip + ' ') : '') + (company.city ? (company.city + '\n') : '\n') + company.get_email + '\n' + (company.personalPhone ? company.personalPhone : ''),
                                        alignment: 'right'
                                    },
                                ]
                            },
                            {}
                        ]

                    ]
                },
                {
                    table: {
                        headerRows: 2,
                        'widths': [380, 380],
                        body: body
                    },
                    layout: {
                        hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return null;
                            }
                            return {dash: {length: 2, space: 2}};
                        },
                        fillColor: function (i, node) {
                            if (i == 0) {
                                return '#fff';
                            }
                            return (i % 2 === 0) ? '#F2F2F2' : null;
                        },
                    }
                }
            ],
            anotherStyle: {
                alignment: 'right'
            },
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                },
                tableHeader: {
                    fontSize: 12,
                    bold: true,
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 15, 0, 0]
                },
                story: {
                    italic: true,
                    alignment: 'center',
                    width: '50%',
                },
                defaultStyle: {
                    columnGap: 8
                },

            }
        };
        let name: string = (project.project_name + '_stats').toString();
        let date = new Date();
        let dateName: string = date.getSeconds().toString();
        let filename: string;
        filename = name;// (name + dateName).replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, '');
        filename = (filename + '.pdf').toString();
        let that = this;
        this.loader.fancyLoading('loading', function () {
            let pdfObject = that.pdfProvider.generate(that.docDefinition);
            if (pdfObject) {
                that.pdfProvider.download(pdfObject, filename);
            }
        });
    }

    /**
     * Simple event listener
     */
    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    /**
     * Simple filter listener
     * @param filterBy
     */
    filter(filterBy: string) {
        this._listners.next(filterBy);
    }

    /**
     * Turn a number into a normal form
     * Example 08 -> 8
     * @param n
     */
    numberNormalForm(n: string) {
        if (n == '') {
            return;
        } else if (n[n.length - 1] != '.') {
            let temp = parseFloat(n);
            // console.log(temp.toString());
            return temp;
        } else {
            return n;
        }
    }

    /**
     * Convert a string to normal form. First letter uppercase
     * Example: Jon Doe
     */
    stringToNormalForm(string) {
        if (typeof string == 'string') {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            string.srcElement.value = string.srcElement.value.charAt(0).toUpperCase() + string.srcElement.value.slice(1);
        }
    }

    /**
     * REMOVE SPACES FROM USERNAME
     */
    removeSPacesFromUsername(event) {
        event.srcElement.value = event.srcElement.value.replace(' ', '');
    }

    /**
     * Conver first letter to uppercase
     */
    capitalize = (string) => {
        if (typeof string == 'string') {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            string.srcElement.value = string.srcElement.value.toLowerCase().replace(/\b[a-z](?!\s)/g, function (x) {
                return x.toUpperCase();
            });
        }

    };

    capitalizeFirst = (string) => {
        if (typeof string == 'string') {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            string.srcElement.value =
                string.srcElement.value.toString();

            string.srcElement.value = string.srcElement.value.charAt(0).toUpperCase() + string.srcElement.value.slice(1);

        }
    };

    stringToNormalForm1(a: string) {
        a = a.substring(0, 1).toUpperCase() + a.substring(1);
        return a;
    }


}
