import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';
import {Events} from '@ionic/angular';
import { map} from 'rxjs/operators';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public company_profile:any;

    constructor(public helpers:HelpersService,public http: HttpClient, public tokenProvider: TokenService, public lngProvider: LanguageService, public event: Events, public token: TokenService) {
    }

    firstLoggedIn(){

        let headers = new HttpHeaders();

        headers = headers.append('Authorization',this.token.returnToken());

        headers = headers.append('Accept', 'application/json');

        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url+this.lngProvider.language+'/api/company/my/profile/data',JSON.stringify({}), {headers: headers});



    }

    // Self Report
    generateSingleSelfPDF(report_id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/admin/single/report/pdf/' + report_id, {headers: headers});
    }

    getVerifiersListForApproval(id){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/get/verifier/list/'+id, {headers: headers});
    }
    sendRequestToVerifier(r_id, v_id){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/verifier/approval/request/'+r_id+'/'+v_id, {headers: headers});
    }

    getCompanyProfile() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/my/profile/data', {headers: headers})
    }

    /**
     * Get Company Email
     */
    getCompanyEmail(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/my/profile/email', {headers: headers})
    }

    editCompanyProfile(data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/my/profile', JSON.stringify(data), {headers: headers})
    }

    getStatisticsData(status) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/statistics/' + status, {headers: headers});

    }

    getStatisticsDataProject(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/statistics/project/' + id, {headers: headers});
    }

    getRegieList() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/regie/list', {headers: headers});
    }

    companyGetGroupsList() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/groups', {headers: headers});

    }


    companyAddGroup(data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/groups', JSON.stringify(data), {headers: headers});

    }

    companyDeleteGroup(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.delete(this.helpers.url + this.lngProvider.language + '/api/company/group/' + id, {headers: headers});
    }

    companyEditGroup(id, data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/group/' + id, JSON.stringify(data), {headers: headers});

    }

    groupDetails(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.options(this.helpers.url + this.lngProvider.language + '/api/company/group/' + id, {headers: headers});
    }


    assignEmployeeToGroup(g_id, employees){
        let body={
            employees:employees
        }
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url+this.lngProvider.language+"/api/company/group/add/employees/"+g_id, JSON.stringify(body), {headers: headers});
    }

    deleteEmployeeFromGroup(g_id, e_g_id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.delete(this.helpers.url + this.lngProvider.language + '/api/company/group/emp/' + e_g_id, {headers: headers});
    }

    deleteGroup(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.delete(this.helpers.url + this.lngProvider.language + '/api/company/group/' + id, {headers: headers});
    }

    addMinused(id, data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/minused/add/' + id, JSON.stringify(data), {headers: headers});

    }

    addCompensated(id, data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/compensated/add/' + id, JSON.stringify(data), {headers: headers});

    }

    editMinused(id, data) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/minused/edit/' + id, JSON.stringify(data), {headers: headers});

    }

    editCompensated(id, data) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/employee/monthly/compensated/edit/' + id, JSON.stringify(data), {headers: headers});
    }

    getWeeklyReport() {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/create/report', {headers: headers});
    }

    getAbsentReport() {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/absent/reports', {headers: headers});
    }

    getAbsentVerifiedReport(){
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/verified/absent/reports', {headers: headers});
    }

    verifySelfReport(id) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/verify/self/report/' + id, {headers: headers});
    }

    editSelfReport(id, body) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/admin/edit/report/' + id, JSON.stringify(body), {headers: headers});
    }

    getEditSelfReport(id) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/edit/report/' + id, {headers: headers});
    }

    deleteSelfReport(id) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/delete/report/' + id, {headers: headers});
    }

    getSinglePDFReport(id) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/edit/report/' + id, {headers: headers});
    }

    getWeeklyPDFReport(w) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/weekly/report/pdf/' + w, {headers: headers});
    }

    getSinglePDFProject(id) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/single/project/pdf/' + id, {headers: headers});
    }

    getWeeklyPDFProject(w) {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/weekly/project/pdf/' + w, {headers: headers});
    }

    getUnverifiedWeeklyReport() {
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/unverified/reports', {headers: headers});
    }

    addWeeklyReport(data, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        if (year) {
            return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/admin/create/report/' + year, JSON.stringify(data), {headers: headers});
        } else {
            return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/admin/create/report', JSON.stringify(data), {headers: headers});
        }
    }

    getAllUnverifiedReports() {
        //api/company/employee/all/unverified/reports
        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/all/unverified/reports', {headers: headers});
    }

    /**
     * Sort Dashboard
     */
    sort_dashboard(method,data){

        let headers = new HttpHeaders().set('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        if(method == 'get' && data == false){
            return this.http.get(this.helpers.url + this.lngProvider.language + '/api/account/get/order/company', {headers: headers})
        }else{
            return this.http.put(this.helpers.url + this.lngProvider.language + '/api/account/get/order/company', JSON.stringify(data),{headers: headers})
        }

    }

    /**
     * Search Vacations by date
     * @param start_date
     * @param end_date
     */
    search_vacations(start_date,end_date){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/filter/vacations/' + start_date + '/' + end_date, {headers: headers});
    }

    /**
     * Getting Active and Finished projects
     */
    get_active_finished_projects(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/choose/project', {headers: headers});
    }

    /**
     * Project Details admin/project/report
     * @param project_id int -> id of the project
     */
    get_project_details(project_id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/project/report/'+project_id, {headers: headers});
    }
    /**
     * Check if we created same report
     * @param project_id
     * @param week
     */
    checkReport(project_id, week, year) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/check/available/report/' + project_id + '/' + week + '/' + year, {headers: headers});
    }

    /**
     * Getting list of employees that admin can verify
     */
    authorizedPeopleToVerifyReport(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/get/my/employees/data',{headers: headers});
    }
    /**
     * Getting list of employees that admin can verify
     */
    authorizedPeopleToVerifyVacation(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/get/my/employees/vacations',{headers: headers});
    }

    getWeeklyReportData(week, year){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/admin/week/r/report/'+week+'/'+year, {headers: headers});
    }

    getMonthlyReportData(year){
        let headers = new HttpHeaders().set("Authorization", this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/admin/monthly/report/' + year, {headers: headers});
    }

    generateSingleMonthPDF(month,year){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/admin/monthly/single/report/'+month+'/'+year, {headers: headers});
    }

    getYearlyReportData(year){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/admin/year/report/' + year, {headers: headers});
    }

    addMinusedAdmin(data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/admin/monthly/minused/add', JSON.stringify(data), {headers: headers});

    }

    addCompensatedAdmin(data) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url + this.lngProvider.language + '/api/company/admin/monthly/compensated/add', JSON.stringify(data), {headers: headers});

    }

    editMinusedAdmin(data) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/admin/monthly/minused/edit', JSON.stringify(data), {headers: headers});

    }

    editCompensatedAdmin(data) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.put(this.helpers.url + this.lngProvider.language + '/api/company/admin/monthly/compensated/edit', JSON.stringify(data), {headers: headers});

    }

    getVacationsData(){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/self/vacation', {headers: headers});
    }

    addVacation(body){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url+this.lngProvider.language+'/api/company/self/vacation',JSON.stringify(body) ,{headers: headers});
    }

    deleteVacation(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(this.helpers.url+this.lngProvider.language+'/api/company/self/vacation/'+id ,{headers: headers});
    }

    requestDeleteVacation(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/self/vacation/'+id ,{headers: headers});
    }

    /**
     * Get project statistics by date
     */
    get_project_statistics(project_id,start_date,end_date){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.token.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/statistics/project/'+project_id+'/'+start_date+'/'+end_date ,{headers: headers});
    }

    cancelVerification(id){
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url + this.lngProvider.language + '/api/company/employee/report/cancel/verification/' + id, {headers: headers});
    }


}
