import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {LanguageService} from '../language/language.service';
import {Events} from '@ionic/angular';
import {TokenService} from './token.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HelpersService} from '../helpers/helpers.service';



@Injectable({
  providedIn: 'root'
})
export class LoginService {
    _token: any;

  constructor(public lngProvider: LanguageService,
              public helpers:HelpersService,
              public http: HttpClient,
              public token:TokenService) { }

    login(credentials) {

        const headers = new HttpHeaders().set("Content-Type", "Application/json");
        // console.log(this.lngProvider.language)
        return this.http.post(this.helpers.url+(this.lngProvider.language?this.lngProvider.language:'de')+'/api-token-auth/', JSON.stringify(credentials), {headers: headers});
    }

    checkAccount(){
        let token = this.token.token;

        let headers = new HttpHeaders().set("Authorization",token);
        return this.http.get(this.helpers.url+(this.lngProvider.language?this.lngProvider.language:'de')+'/api/account/check', {headers: headers});


    }

    /**
     * Register Token
     * @param registration_id
     * @param platform
     */
    registerToken(registration_id,platform){
        let body = {
            "registration_id": registration_id,
            "type": platform
        };

        let token = this.token.token;
        let headers = new HttpHeaders();
        // console.log(body);
        headers = headers.append('Authorization',token);
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(this.helpers.url+(this.lngProvider.language?this.lngProvider.language:'de')+'/devices', JSON.stringify(body), {headers: headers});
    }

}
