import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslatedService} from '../../services/translated/translated.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.page.html',
  styleUrls: ['./tutorials.page.scss'],
})
export class TutorialsPage implements OnInit {
  @Input() type: any;
  slides_data: any;
  is_end = false;

  constructor(
      public modalController: ModalController,
      private translated: TranslatedService
  ) {
    console.log(this.slides_data);
  }

  endReached() {
    this.is_end = true;
  }

  previousSlide() {
    this.is_end = false;
  }

  ngOnInit() {
    let slides = document.querySelector('ion-slides');
    if (this.type == 'employee') {
      this.slides_data = [
        {
          index: 0,
          title: this.translated.getTranslatedText('menu_report'),
          image: '../../assets/imgs/report.svg',
          id: 'Reports',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_1')
        },

        {
          index: 1,
          title: 'Regierapport',
          image: '../../assets/imgs/regie_raport.svg',
          id: 'Regie Report',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_2')
        },
        {
          index: 3,
          title: this.translated.getTranslatedText('menu_report_control'),
          image: '../../assets/imgs/report_management.svg',
          id: 'Report Control',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_3')
        },
        {
          index: 7,
          title: this.translated.getTranslatedText('menu_vacations'),
          image: '../../assets/imgs/vacation.svg',
          id: 'Vacations',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_4')
        },
        {
          index: 8,
          title: this.translated.getTranslatedText('menu_hours_control'),
          image: '../../assets/imgs/report_management.svg',
          id: 'Hours Overview',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_5')
        },
        {
          index: 9,
          title: this.translated.getTranslatedText('activity'),
          image: '../../assets/imgs/activity.svg',
          id: 'Activity',
          type: 'employee',
          body: this.translated.getTranslatedText('emp_tutorial_6')
        }
      ];
    } else {
      // this.slides_data = [
      //     {
      //         index: 0,
      //         title: this.translated.getTranslatedText('menu_report'),
      //         image: '../../assets/imgs/report.svg',
      //         id: 'Reports',
      //         type: 'employee',
      //         body: 'In reports tab you have a list of unverified reports, you can edit or delete unverified reports, you can create pdf, verify report or request verification from verifier. In this tab you can also add new report.'
      //     },
      //
      //     {
      //         index: 1,
      //         title: 'Regierapport',
      //         image: '../../assets/imgs/regie_raport.svg',
      //         id: 'Regie Report',
      //         type: 'employee',
      //         body: ''
      //     },
      //     {
      //         index: 2,
      //         title: this.translated.getTranslatedText('menu_statistics'),
      //         image: '../../assets/imgs/statistics.svg',
      //         id: 'Statistics',
      //         body: 'In statistics you can check working hours for active or finished projects, create detailed PDF with working hours, also you can create PDF for sepecific time range.\n'
      //     },
      //     {
      //         index: 3,
      //         title: this.translated.getTranslatedText('menu_report_control'),
      //         image: '../../assets/imgs/report_management.svg',
      //         id: 'Report Control',
      //         type: 'employee',
      //         body: 'In report control you have a list of reports from employees that you supervise, you can check their reports also approve if the have requested you to do so, you also have an option to dissapprove their reports.'
      //     },
      //     {
      //         index: 4,
      //         title: this.translated.getTranslatedText('menu_projects'),
      //         image: '../../assets/imgs/projects.svg',
      //         id: 'Projects',
      //         body: 'In projects tab you can add, edit, delete, finish, activate projects, you can assign employees or groups to project, or delete assigned employee , you can add verifiers for project, and also create project PDF.\n'
      //     },
      //     {
      //         index: 5,
      //         title: this.translated.getTranslatedText('clients'),
      //         image: '../../assets/imgs/clients.svg',
      //         id: 'Clients',
      //         body: 'In clients tab, you are able to manage your clients, store client info, add project related to client and check working hours for the client.'
      //     },
      //     {
      //         index: 6,
      //         title: this.translated.getTranslatedText('menu_emp'),
      //         image: '../../assets/imgs/employees.svg',
      //         id: 'Employee',
      //         body: 'In employees tab you can add, edit and deactivate employees, also you can create groups for easier assignment to projects.'
      //
      //     },
      //     {
      //         index: 7,
      //         title: this.translated.getTranslatedText('menu_vacations'),
      //         image: '../../assets/imgs/vacation.svg',
      //         id: 'Vacations',
      //         type: 'employee',
      //         body: 'In vacations tab you can check your vacations add vacation, also you can se other employees vacations approve vacation when they add new, or disapprove if they choose bad timing.'
      //     },
      //     {
      //         index: 8,
      //         title: this.translated.getTranslatedText('menu_hours_control'),
      //         image: '../../assets/imgs/report_management.svg',
      //         id: 'Hours Overview',
      //         type: 'employee',
      //         body: 'In hour overview you can see weekly hours, monthly hours and yearly, every report is in different format, with related information. You can also create weekly, monthly and yearly PDF reports from this tab.'
      //     },
      //     {
      //         index: 9,
      //         title: this.translated.getTranslatedText('activity'),
      //         image: '../../assets/imgs/activity.svg',
      //         id: 'Activity',
      //         type: 'employee',
      //         body: 'Activity tab stores your reports, reports are organized in projects, you can check reports for finished projects, active projects also you can create PDF documents for single report or weekly PDF.\n'
      //     }
      // ];
      this.slides_data = [
        {
          index:1,
          title: this.translated.getTranslatedText('profile'),
          image: '../../assets/imgs/profile.svg',
          id:"Profile",
          body:this.translated.getTranslatedText('cmp_tutorial_1')
        },
        {
          index:2,
          title: this.translated.getTranslatedText('employees'),
          image: '../../assets/imgs/employees.svg',
          id:"Employees",
          body:this.translated.getTranslatedText('cmp_tutorial_2')
        },
        {
          index:3,
          title: this.translated.getTranslatedText('clients'),
          image: '../../assets/imgs/clients.svg',
          id:"Clients",
          body:this.translated.getTranslatedText('cmp_tutorial_3')
        },
        {
          index:4,
          title: this.translated.getTranslatedText('menu_projects'),
          image: '../../assets/imgs/projects.svg',
          id:"Projects",
          body:this.translated.getTranslatedText('cmp_tutorial_4')
        },
        {
          index:5,
          title: this.translated.getTranslatedText('assign_employee'),
          image: '../../assets/imgs/groups.svg',
          id:"Assign Employees",
          body:this.translated.getTranslatedText('cmp_tutorial_5')
        },
        {
          index:6,
          title: this.translated.getTranslatedText('menu_report_control'),
          image: '../../assets/imgs/report_management.svg',
          id:"Reports",
          body:this.translated.getTranslatedText('cmp_tutorial_6')
        },
      ];
    }
  }

  close() {
    this.modalController.dismiss();
  }

}
