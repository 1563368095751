import {Injectable} from '@angular/core';
import {TranslatedService} from '../translated/translated.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(private translated:TranslatedService){

    }

    public title = '';

    public menu: any = [

        {
            index: 0,
            name: this.translated.getTranslatedText('menu_report'),
            icon: '../../assets/imgs/report.svg',
            path: '/members/employee-reports'
        },
        {
            index: 1,
            name: 'Regierapport',
            icon: '../../assets/imgs/regie_raport.svg',
            path: ['/members/regierapport',{keyword:"Employee"}]
        },
        {
            index: 2,
            name: this.translated.getTranslatedText('menu_hours_control'),
            icon: '../../assets/imgs/report_management.svg',
            path: '/members/employee-manage-reports'
        },
        {
            index: 3,
            name: this.translated.getTranslatedText('activity'),
            icon: '../../assets/imgs/activity.svg',
            path: '/members/employee-activity'
        },
        {
            index: 7,
            name: this.translated.getTranslatedText('menu_vacations'),
            icon: '../../assets/imgs/vacation.svg',
            path: '/members/employee-vacations'
        },
        {
            index: 8,
            name: this.translated.getTranslatedText('menu_help'),
            icon: '../../assets/imgs/help.svg',
            path: ['/members/help',{keyword:"Employee"}]
        },

        {
            index: 10,
            name: this.translated.getTranslatedText('settings'),
            icon: '../../assets/imgs/settings.svg',
            path: ['/members/settings',{keyword:"Employee"}]
        }
    ];
}
