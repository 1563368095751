import { NotificationsService } from './notifications.service';
import {Component, QueryList, ViewChildren} from '@angular/core';

import {Events, IonRouterOutlet, ModalController, NavController, Platform, ToastController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {LanguageService} from './services/language/language.service';
import {TokenService} from './services/authentication/token.service';
import {AuthService} from './services/authentication/auth.service';
import {EmployeeAccountService} from './services/employee-account/employee-account.service';
import {LoaderService} from './services/loader/loader.service';
import {MenuService} from './services/menu/menu.service';

import {AlertService} from './services/alert/alert.service';
import {TranslatedService} from './services/translated/translated.service';
import {BackgroundMode} from '@ionic-native/background-mode/ngx';
import { firebase } from "@firebase/app";
import {Firebase} from '@ionic-native/firebase/ngx';
import {ToastService} from './services/toast/toast.service';
import {Badge} from '@ionic-native/badge/ngx';
import {Network} from '@ionic-native/network/ngx';
import {HelpersService} from './services/helpers/helpers.service';
import {NetworkPage} from './pages/network/network.page';
import { environment } from '../environments/environment';

@Component({
    selector: "app-root",
    templateUrl: "app.component.html"
})
export class AppComponent {
    @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
    has_notification = false;
    msg_object: any = null;
    action: any = null;
    modalPresented = false;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private alertCtrl: AlertService,
        private storage: Storage,
        private lngProvider: LanguageService,
        private tokenService: TokenService,
        private authService: AuthService,
        private navCtrl: NavController,
        public events: Events,
        private empService: EmployeeAccountService,
        private loader: LoaderService,
        private toastController: ToastController,
        private menuService: MenuService,
        private translated: TranslatedService,
        private bg: BackgroundMode,
        private _FCM: Firebase,
        private toastCtrl: ToastService,
        private badge: Badge,
        public helper: HelpersService,
        private network: Network,
        public modalController: ModalController,
        private notificationsService: NotificationsService
    ) {
        this.initializeApp();
        this.bg.enable();
    }

    async ngOnInit() {
        firebase.initializeApp(environment.firebase);
        await this.notificationsService.init();
    }

    click = 0;

    initializeApp() {
        this.platform.ready().then(() => {
            this.notificationSetup();
            this.splashScreen.hide();
            this.statusBar.show();
            this.backButtonEvent();
            this.networkCheck();
            this.lngProvider.checkLanguage();
            this.events.subscribe("navigate_at_start", () => {
                this.navigateInit();
            });

            this.events.subscribe("supervisor", () => {
                this.menuUpdate();
            });
            this.notificationsService.requestPermission(()=>{
                setTimeout(()=>{
                    this.alertCtrl.presentAlertConfirm(() => {})
                },3000)
            });
        });
    }

    navigateInit() {
        this.authService.authState.subscribe(state => {
            if (state) {
                // console.log("STATE SUBSCRIBED");
                // console.log(this.tokenService.type_user);
                if (this.tokenService.type_user == "Employee") {
                    let m = [];
                    this.loader.present();

                    this.empService.getEmpProfile().subscribe(
                        (user: any) => {
                            // console.log(user);

                            this.empService.account = user;
                            let emp_profile: any = this.empService.account;
                            this.menuService.title = emp_profile.employee.fullname;

                            this.loader.dismiss();

                            if (this.empService.account.is_supervisor == "True") {
                                let item = {
                                    index: 9,
                                    name: this.translated.getTranslatedText("menu_report_control"),
                                    icon: "../../assets/imgs/report_management.svg",
                                    path: ["/members/my-supervised-employees"]
                                };
                                let menuItemExistsObject = this.menuService.menu.find(item => item.index == 9);
                                if (!menuItemExistsObject) {
                                    // console.log("adding start");
                                    this.menuService.menu.splice(4, 0, item);
                                }
                            }
                            if (
                                emp_profile.employee.can_manage_employees &&
                                emp_profile.employee.can_edit_employees &&
                                emp_profile.employee.can_deactivate_employees &&
                                emp_profile.employee.can_manage_groups &&
                                emp_profile.employee.can_add_client &&
                                emp_profile.employee.can_add_project
                            ) {
                                //&& emp_profile.employee.can_verify_report
                                // IF ADMIN
                                m.push({
                                    icon: "../../assets/imgs/statistics.svg",
                                    name: this.translated.getTranslatedText("menu_statistics"),
                                    path: "/members/permission/employee-statistics"
                                });

                                // m.push(
                                //     {
                                //         icon: '../../assets/imgs/vacation.svg',
                                //         name: this.translated.getTranslatedText('Employees Vacations'),
                                //         path: '/members/permission/employee-employees-vacations',
                                //     }
                                // );

                                m.push({
                                    icon: "../../assets/imgs/projects.svg",
                                    name: this.translated.getTranslatedText("menu_projects"),
                                    path: "/members/permission/employee-projects"
                                });

                                m.push({
                                    icon: "../../assets/imgs/clients.svg",
                                    name: this.translated.getTranslatedText("clients"),
                                    path: "/members/permission/employee-clients"
                                });

                                m.push({
                                    icon: "../../assets/imgs/employees.svg",
                                    name: this.translated.getTranslatedText("menu_emp"),
                                    path: "/members/permission/employee-employees"
                                });

                                let arr: any = m;
                                arr.forEach(item => {
                                    this.menuService.menu.splice(4, 0, item);
                                });
                            } else if (emp_profile.employee.is_manager) {
                                if (
                                    emp_profile.employee.can_manage_employees ||
                                    emp_profile.employee.can_edit_employees ||
                                    emp_profile.employee.can_deactivate_employees ||
                                    emp_profile.employee.can_manage_groups
                                ) {
                                    // or can_manage_groups
                                    //|| emp_profile.employee.can_verify_report
                                    let a: any = {
                                        icon: "../../assets/imgs/employees.svg",
                                        name: this.translated.getTranslatedText("menu_emp"),
                                        path: "/members/permission/employee-employees"
                                    };
                                    m.push(a);
                                }

                                if (emp_profile.employee.can_add_client) {
                                    let a: any = {
                                        icon: "../../assets/imgs/clients.svg",
                                        name: this.translated.getTranslatedText("clients"),
                                        path: "/members/permission/employee-clients"
                                    };
                                    m.push(a);
                                }
                                if (emp_profile.employee.can_add_project) {
                                    let a: any = {
                                        icon: "../../assets/imgs/projects.svg",
                                        name: this.translated.getTranslatedText("menu_projects"),
                                        path: "/members/permission/employee-projects"
                                    };
                                    m.push(a);
                                }

                                let arr: any = m;
                                arr.forEach(item => {
                                    this.menuService.menu.splice(4, 0, item);
                                });
                            }

                            this.events.publish("set-menu", { menu: m });

                            // console.log("PATH ", this.authService.path);
                            this.navCtrl
                                .navigateRoot([
                                    this.authService.path,
                                    {
                                        has_notification: this.has_notification,
                                        msg: this.msg_object,
                                        action: this.action
                                    }
                                ])
                                .then(() => {
                                    this.resetNotificationFlags();
                                });
                        },
                        err => {
                            this.loader.dismiss();
                            console.log(err);
                        }
                    );
                } else {
                    this.navCtrl
                        .navigateRoot([
                            this.authService.path,
                            {
                                has_notification: this.has_notification,
                                msg: this.msg_object,
                                action: this.action
                            }
                        ])
                        .then(() => {
                            this.resetNotificationFlags();
                        });
                }
            } else {
                this.navCtrl.navigateRoot([this.authService.path]);
            }
        });
    }

    resetNotificationFlags() {
        this.has_notification = false;
        this.msg_object = null;
        this.action = null;
    }

    /***
     * Back button events
     */
    backButtonEvent() {
        this.platform.backButton.subscribeWithPriority(0, () => {
            // console.log("URL :", this.router.url);
            if (this.modalPresented) {
                this.presentBackButtonAlert();
            } else {
                if (this.router.url.substring(0, 26) == "/members/company-dashboard" || this.router.url.substring(0, 27) == "/members/employee-dashboard") this.presentBackButtonAlert();
                else if (this.router.url == "/welcome") navigator["app"].exitApp();
                else if (this.router.url.substring(0, 27) == "/members/company-report-add") {
                    // console.log("CMP REPORT ADD");
                    this.events.publish("backButtonAddReport");
                } else if (this.router.url.substring(0, 28) == "/members/employee-report-add") this.events.publish("backButtonAddReportEmp");
                else if (this.router.url.substring(0, 28) == "/members/company-add-project" || this.router.url.substring(0, 40) == "/members/permission/employee-project-add") {
                    // console.log(this.router.url);
                    this.events.publish("backButtonProjectAdd");
                } else this.events.publish("backButtonClicked");
            }
        });
    }

    presentBackButtonAlert() {
        this.alertCtrl.presentAlert({
            header: this.translated.getTranslatedText("exit"),
            buttons: [
                {
                    text: this.translated.getTranslatedText("no"),
                    role: "cancel",
                    handler: () => {
                        this.navCtrl.navigateRoot([this.router.url]);
                    }
                },
                {
                    text: this.translated.getTranslatedText("yes"),
                    handler: () => {
                        navigator["app"].exitApp();
                    }
                }
            ]
        });
    }

    private notificationSetup() {
        if(!this.platform.is('desktop')){
            this._FCM.onNotificationOpen().subscribe(msg => {
                // ("SETTING ", msg);
                // console.log(msg.tap, msg.user == "employee", msg.report_id);
                if (msg.user == "employee" && msg.action == "permissions changed") {
                    if (!msg.tap) {
                        this.alertCtrl.presentAlert({
                            header: msg.body,
                            buttons: [
                                {
                                    text: "OK",
                                    role: "cancel",
                                    handler: () => {
                                        location.reload();
                                    }
                                }
                            ],
                            backdropDismiss: false
                        });
                    }
                } else if (msg.user == "employee" && msg.action == "deactivated") {
                    if (!msg.tap) {
                        this.authService.notificationLogOut = true;
                        this.alertCtrl.presentAlert({
                            header: msg.body,
                            buttons: [
                                {
                                    text: "OK",
                                    role: "cancel",
                                    handler: () => {
                                        this.authService.logOut();
                                    }
                                }
                            ],
                            backdropDismiss: false
                        });
                    }
                } else {
                    // if the message is tapped
                    if (msg.tap && msg.report_id) {
                        //console.log("ok publishing event");
                        // this.events.publish('employee-notification', {msg:msg});
                        this.msg_object = msg.report_id;
                        this.has_notification = true;
                        this.action = msg.action;
                    } else {
                        if (this.platform.is('ios')) {

                            // if (this.tokenService.type_user == 'Company') {
                            this.badge.set(msg.notification.badge);
                            // }

                            if (msg.tap == 'background' || msg.tap == 'foreground' && msg.notification.report_id) {
                                console.log('ok publishing event');
                                this.msg_object = msg.notification.report_id;
                                this.has_notification = true;
                                this.action = msg.notification.action;
                            } else {
                                this.toastCtrl.createToast({
                                    header: msg.notification.body,
                                    position: 'top',
                                    duration: 5000,
                                    buttons:[
                                        {
                                            side: 'end',
                                            icon: 'close',
                                            role: 'cancel',
                                            handler: () => {
                                                console.log('Cancel clicked');
                                            }
                                        }
                                    ]
                                    })
                                    .then(t => {
                                        t.present();
                                        if (this.router.url.substring(0, 26) == "/members/my-report-details" || this.router.url.substring(0, 31) == "/members/employee-report-detail") {
                                            this.events.publish("auto-close");
                                        }
                                    });
                            }
                        } else {
                            // if (this.tokenService.type_user == 'Company') {
                            this.badge.set(msg.badge);
                            // }
                            if (!msg.tap) {
                                this.toastCtrl
                                    .createToast({
                                        header: msg.body,
                                        position: "top",
                                        duration: 5000,
                                        buttons: [
                                            {
                                                side: "end",
                                                icon: "close",
                                                role: "cancel",
                                                handler: () => {}
                                            }
                                        ]
                                    })
                                    .then(t => {
                                        t.present();
                                        if (this.router.url.substring(0, 26) == "/members/my-report-details" || this.router.url.substring(0, 31) == "/members/employee-report-detail") {
                                            this.events.publish("auto-close");
                                        }
                                    });
                            }
                        }
                    }
                }
            });
        }else{
            // console.log("pwa")
        }
    }

    menuUpdate() {
        // console.log("CHANGING");
        let m = [];
        let emp_profile = this.empService.account;
        this.menuService.menu = [
            {
                index: 0,
                name: this.translated.getTranslatedText("menu_report"),
                icon: "../../assets/imgs/report.svg",
                path: "/members/employee-reports"
            },
            {
                index: 1,
                name: "Regierapport",
                icon: "../../assets/imgs/regie_raport.svg",
                path: ["/members/regierapport", { keyword: "Employee" }]
            },
            {
                index: 2,
                name: this.translated.getTranslatedText("menu_hours_control"),
                icon: "../../assets/imgs/report_management.svg",
                path: "/members/employee-manage-reports"
            },
            {
                index: 3,
                name: this.translated.getTranslatedText("activity"),
                icon: "../../assets/imgs/activity.svg",
                path: "/members/employee-activity"
            },
            {
                index: 7,
                name: this.translated.getTranslatedText("menu_vacations"),
                icon: "../../assets/imgs/vacation.svg",
                path: "/members/employee-vacations"
            },
            {
                index: 8,
                name: this.translated.getTranslatedText("menu_help"),
                icon: "../../assets/imgs/help.svg",
                path: ["/members/help", { keyword: "Employee" }]
            },

            {
                index: 10,
                name: this.translated.getTranslatedText("settings"),
                icon: "../../assets/imgs/settings.svg",
                path: ["/members/settings", { keyword: "Employee" }]
            }
        ];

        if (this.empService.account.is_supervisor == "True") {
            let item = {
                index: 9,
                name: this.translated.getTranslatedText("menu_report_control"),
                icon: "../../assets/imgs/report_management.svg",
                path: ["/members/my-supervised-employees"]
            };
            let menuItemExistsObject = this.menuService.menu.find(item => item.index == 9);
            if (!menuItemExistsObject) {
                // console.log("adding update");
                this.menuService.menu.splice(4, 0, item);
            }
        }
        if (emp_profile.employee.can_manage_employees && emp_profile.employee.can_edit_employees && emp_profile.employee.can_deactivate_employees && emp_profile.employee.can_manage_groups) {
            //&& emp_profile.employee.can_verify_report
            m.push({
                icon: "../../assets/imgs/statistics.svg",
                name: this.translated.getTranslatedText("menu_statistics"),
                path: "/members/permission/employee-statistics"
            });

            // m.push(
            //     {
            //         icon: '../../assets/imgs/vacation.svg',
            //         name: this.translated.getTranslatedText('Employees Vacations'),
            //         path: '/members/permission/employee-employees-vacations',
            //     }
            // );

            m.push({
                icon: "../../assets/imgs/projects.svg",
                name: this.translated.getTranslatedText("menu_projects"),
                path: "/members/permission/employee-projects"
            });

            m.push({
                icon: "../../assets/imgs/clients.svg",
                name: this.translated.getTranslatedText("clients"),
                path: "/members/permission/employee-clients"
            });

            m.push({
                icon: "../../assets/imgs/employees.svg",
                name: this.translated.getTranslatedText("menu_emp"),
                path: "/members/permission/employee-employees"
            });

            let arr: any = m;
            arr.forEach(item => {
                this.menuService.menu.splice(4, 0, item);
            });
        } else if (emp_profile.employee.is_manager) {
            if (emp_profile.employee.can_manage_employees || emp_profile.employee.can_edit_employees || emp_profile.employee.can_deactivate_employees || emp_profile.employee.can_manage_groups) {
                // or can_manage_groups
                //|| emp_profile.employee.can_verify_report
                let a: any = {
                    icon: "../../assets/imgs/employees.svg",
                    name: this.translated.getTranslatedText("menu_emp"),
                    path: "/members/permission/employee-employees"
                };
                m.push(a);
            }

            if (emp_profile.employee.can_add_client) {
                let a: any = {
                    icon: "../../assets/imgs/clients.svg",
                    name: this.translated.getTranslatedText("clients"),
                    path: "/members/permission/employee-clients"
                };
                m.push(a);
            }
            if (emp_profile.employee.can_add_project) {
                let a: any = {
                    icon: "../../assets/imgs/projects.svg",
                    name: this.translated.getTranslatedText("menu_projects"),
                    path: "/members/permission/employee-projects"
                };
                m.push(a);
            }

            let arr: any = m;
            arr.forEach(item => {
                this.menuService.menu.splice(4, 0, item);
            });
        }

        this.events.publish("set-menu", { menu: m });
    }

    networkCheck() {
        if (this.network.type == this.network.Connection.NONE) {
            this.helper.isConnected = false;
            this.openModal().then(() => {
                // console.log("opened");
                this.modalPresented = true;
            });
        } else {
            this.helper.isConnected = true;
            this.closeModal().then(() => {
                // console.log("closed");
                this.modalPresented = false;
            });
        }

        this.network.onDisconnect().subscribe(() => {
            // ("network was disconnected :-(");
            this.helper.isConnected = false;
            this.openModal().then(() => {
                // console.log("present");
                this.modalPresented = true;
            });
            //show modal...

            // this.toastCtrl.createToast({
            //     header: "Please check your internet connection.",
            //     position: 'top',
            //     duration: 5000,
            //     buttons: [
            //         {
            //             side: 'end',
            //             icon: 'close',
            //             role: 'cancel',
            //             handler: () => {
            //                 console.log('Cancel clicked');
            //             }
            //         }
            //     ]
            // }).then(t => {
            //     t.present();
            // });
        });

        this.network.onConnect().subscribe(() => {
            // console.log("network connected!");
            this.helper.isConnected = true;

            // We just got a connection but we need to wait briefly
            // before we determine the connection type. Might need to wait.
            // prior to doing any api requests as well.
            setTimeout(() => {
                if (this.network.type != this.network.Connection.NONE) {
                    // console.log("we got a wifi connection, woohoo!");
                    // close modal

                    this.toastCtrl
                        .createToast({
                            header: "Connected to the internet.",
                            position: "top",
                            duration: 5000,
                            buttons: [
                                {
                                    side: "end",
                                    icon: "close",
                                    role: "cancel",
                                    handler: () => {
                                        // console.log("Cancel clicked");
                                    }
                                }
                            ]
                        })
                        .then(t => {
                            t.present();
                            this.closeModal().then(() => {
                                // console.log("close");
                                this.modalPresented = false;
                                location.reload();
                            });
                        });
                }
            }, 3000);
        });
    }

    async openModal() {
        const modal = await this.modalController.create({
            component: NetworkPage,
            backdropDismiss: false
        });

        return await modal.present();
    }

    async closeModal() {
        await this.modalController.dismiss();
    }
}
