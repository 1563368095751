// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyADOnE0M51IqQFNb2MkVkgWL470TgVNK2s",
    authDomain: "smartrapport-24436.firebaseapp.com",
    databaseURL: "https://smartrapport-24436.firebaseio.com",
    projectId: "smartrapport-24436",
    storageBucket: "smartrapport-24436.appspot.com",
    messagingSenderId: "1079374767075",
    appId: "1:1079374767075:web:c878a37693c6f1eb",
    vapidKey: "BLPGklWon1VP1mY7gE-AUqrxEaZYTdggfdgW91norZ0078Sehah0iPOBlsQcLrQKo8iRtrSygQb6_bWxpdu2m0E"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
