import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {TranslatedService} from '../translated/translated.service';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController, private translated: TranslatedService) {
    }

    /**
     * Simple Toast :)
     * @param label string -> message of the toast
     * @param duration string -> duration of the toast
     * @param theme string -> ex success
     * @param position string -> position of toast
     */
    async presentToast(label, duration, theme, position) {
        const toast = await this.toastController.create({
            message: this.translated.getTranslatedText(label) ? this.translated.getTranslatedText(label) : label,
            duration: duration ? duration : 3000,
            color: theme ? theme : 'dark',
            position: position ? position : 'bottom',
            buttons: [
                {
                    side: 'end',
                    icon: 'close',
                    cssClass:'white',
                    handler: () => {}
                },
            ]

        });
        toast.present();
    }


    async createToast(options) {
        return await this.toastController.create(options);
    }


    async dismiss() {
        return await this.toastController.dismiss() /*.then(() => console.log('toast dismissed') )*/;
    }

}
