import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslatedService} from '../translated/translated.service';
import {ok} from 'assert';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(public alertController: AlertController, private translated: TranslatedService) {
    }

    /**
     * Clients
     * @returns {Promise<void>}
     */
    async presentAlertRadio(header , inputs, buttons) {
        // console.log(inputs);
        const alert = await this.alertController.create({
            header:  this.translated.getTranslatedText(header),
            inputs: inputs,
            buttons: buttons
        });

        await alert.present();
    }

    /**
     * Checking if its empty :)
     * @param str
     */
    isEmpty(str) {
        return (!str || 0 === str.length);
    }

    /**
     * Display Simple alert
     * @param options
     */
    async presentAlert(options: any) {
        const alert = await this.alertController.create(options);
        await alert.present();
    }

    /**
     * Creating presentFancyAlert Buttons
     * @param text string ->
     * @param role string ->
     * @param callback function ->
     */
    createBtns(text, role, callback) {
        let obj = {
            'text': this.translated.getTranslatedText(text),
            'role': role,
            handler: () => {
                callback();
            }
        };
        return obj;
    }

    /**
     * Displaying Delete Alert
     * @param label string -> title translated
     * @param message string -> displaying translated message body
     * @param callback function -> the callback we want to do when we click okay
     */
    async presentDeleteAlert(label, message, callback) {
        const alert = await this.alertController.create({
            header: this.translated.getTranslatedText(label),
            'message': message ? message : '',
            buttons: [
                {
                    text: this.translated.getTranslatedText('no'),
                    role: 'cancel',
                    handler: () => {

                    }
                }, {
                    text: this.translated.getTranslatedText('yes'),
                    handler: () => {
                        callback();
                    }
                }
            ]
        });
        await alert.present();
    }


    /**
     * Display alert with inputs
     *  @param label string -> title translated
     * @param inputs object -> you can create inputs as much u want.
     * @param label_no string -> instead of No text.
     * @param label_yes string -> instead of Yes text.
     * @param callback function -> the callback we want to do when we click okay
     */
    async presentAlertPrompt(label, inputs,{label_no=null,label_yes=null}={}, callback) {

        if (inputs == false){
            const alert = await this.alertController.create({
                header: this.translated.getTranslatedText(label),
                buttons: [
                    {
                        text: this.translated.getTranslatedText(label_no?label_no:"no"),
                        role: 'cancel',
                        handler: () => {

                        }
                    }, {
                        text: this.translated.getTranslatedText(label_yes?label_yes:"yes"),
                        handler: (data) => {
                            callback(data);
                        }
                    }
                ]
            });
            await alert.present();
        } else{
            if(inputs.length !=0){
                let that = this;
                let arr = [];
                inputs.forEach(async  (index, value, array) => {
                    arr = array;
                });
                const alert = await that.alertController.create({
                    header: this.translated.getTranslatedText(label),
                    inputs: arr,
                    buttons: [
                        {
                            text: this.translated.getTranslatedText(label_no?label_no:"no"),
                            role: 'cancel',
                            handler: () => {

                            }
                        }, {
                            text: this.translated.getTranslatedText(label_yes?label_yes:"yes"),
                            handler: (data) => {
                                callback(data);
                            }
                        }
                    ]
                });
                await alert.present();
            }


        }

    }

    /**
     * Needs to be changed
     * @param label
     * @param inputs
     * @param callback
     */
    async reasonAlert(label, inputs, callback,okText,cancelText) {
        if (inputs == false){
            const alert = await this.alertController.create({
                header: this.translated.getTranslatedText(label),
                buttons: [
                    {
                        text: this.translated.getTranslatedText('no'),
                        role: 'cancel',
                        handler: () => {

                        }
                    }, {
                        text: this.translated.getTranslatedText('yes'),
                        handler: (data) => {
                            callback(data);
                        }
                    }
                ]
            });
            await alert.present();
        } else{
            if(inputs.length !=0){
                let that = this;
                let arr = [];
                inputs.forEach(async  (index, value, array) => {
                    arr = array;
                });
                const alert = await that.alertController.create({
                    header: this.translated.getTranslatedText(label),
                    inputs: arr,
                    buttons: [
                        {
                            text: this.translated.getTranslatedText('cancel'),
                            role: 'cancel',
                            handler: () => {

                            }
                        }, {
                            text: this.translated.getTranslatedText('send'),
                            handler: (data) => {
                                callback(data);
                            }
                        }
                    ]
                });
                await alert.present();
            }


        }

    }

    /**
     * Alert
     */
    async presentAlertConfirm(cb) {
        const alert = await this.alertController.create({
            header: this.translated.getTranslatedText('notification_required'),
            message: this.translated.getTranslatedText('notification_msg'),
            buttons: [
                {
                    text: this.translated.getTranslatedText('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: this.translated.getTranslatedText('ok'),
                    handler: () => {
                        cb()
                    }
                }
            ]
        });

        await alert.present();
    }
}
