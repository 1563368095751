import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    public token: any;
    public type_user: any;
    authenticationState = new BehaviorSubject(false);

    constructor(private storage: Storage) {
        this.getTokenInit();
    }

    // Set the token and save it in storage when the user logs in
    public setToken(val){
        this.storage.set('myauthtokenkey', val);
        this.token = this.getToken(val);
    }

    // When the app is open check if the user is authenticated
    public getTokenInit(){
        this.storage.get('myauthtokenkey').then((val) => {
            if(val){
                this.authenticationState.next(true);
                this.token = this.getToken(val);
            }
            else this.token = null;
        });
    }

    // Full token
    public returnToken(){
        return this.token;
    }

    // Full token
    public getToken(t){
        return "SmartRapport " + t.token;
    }

    // Use when user logs out
    public removeToken(){
        return this.storage.remove('myauthtokenkey');
    }
}

