import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchEmployee'
})
export class SearchEmployeesPipe implements PipeTransform {
    transform(items: any, terms?: any): any {
        if (!items) {
            return [];
        }
        if (!terms) {
            return items;
        }
        terms = terms.toLowerCase();
        return items.filter(it => {

            if (it.name) {
                return it.name.toLowerCase().includes(terms);
            }
            else{
                return it.fullname.toLowerCase().includes(terms);
            }

        });
    }

}
