import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {TokenService} from './token.service';
import {BehaviorSubject} from 'rxjs';
import {LanguageService} from '../language/language.service';
import {AlertService} from '../alert/alert.service';
import {Events, NavController, Platform} from '@ionic/angular';
import {MenuService} from '../menu/menu.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from '../loader/loader.service';
import {TranslatedService} from '../translated/translated.service';
import {HelpersService} from '../helpers/helpers.service';
import {Badge} from '@ionic-native/badge/ngx';

declare const window: any;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public routes = [];
    public isLoggedIn = false;
    public isCalledFromAppComponents = false;
    public authState = new BehaviorSubject(false);

    endSessionLabel;
    doYouWantToLogout;
    cancelLabel;
    yesLabel;
    notificationLogOut = false;

    constructor(private storage: Storage,
                public tokenService: TokenService,
                private lngProvider: LanguageService,
                private alertCtrl: AlertService,
                private navCtrl: NavController,
                private menuService: MenuService,
                private events: Events,
                public http: HttpClient,
                private platform: Platform,
                public tokenProvider: TokenService,
                private translated: TranslatedService,
                public helpers: HelpersService,
                private loader: LoaderService) {
        this.platform.ready().then(() => {
            this.checkIsLoggedIn().then(() => {
                this.events.publish('navigate_at_start');
            });
        });
    }

    public path = '/welcome';

    /**
     * Check if a user is authenticated and set the entry path
     * @returns {Promise<void>}
     */
    async checkIsLoggedIn() {
        return await this.storage.ready().then(async () => {
            await this.storage.get('myauthtokenkey').then(async (val) => {
                if (val) {
                    this.tokenService.token = this.tokenService.getToken(val);
                    await this.storage.get('user_type').then((user) => {
                        this.tokenService.type_user = user;
                        if (user === 'Company') {
                            this.authState.next(true);
                            this.isLoggedIn = true;
                            this.path = '/members/company-dashboard';
                        }
                        else if (user === 'Employee') {
                            this.isLoggedIn = true;
                            this.authState.next(true);
                            this.path = '/members/employee-dashboard';
                        }
                        else {
                            this.isLoggedIn = false;
                            this.authState.next(false);
                            this.path = '/login';
                        }

                    });
                }
                else {
                    await this.storage.get('first').then(first_val => {
                        if (first_val == 0) {
                            this.path = '/login';
                        }
                        else {
                            this.path = '/welcome';
                            this.loader.dismiss();
                        }

                        this.isLoggedIn = false;
                        this.authState.next(false);
                    });
                }

            });
        });
    }

    /**
     * Present alert to confirm log out
     * when the session is ended the myauthtoken and user_type are removed from storage
     */
    public logOut() {

        if (this.lngProvider.language == 'en') {
            this.endSessionLabel = 'End Session';
            this.yesLabel = 'Yes';
            this.cancelLabel = 'Cancel';
            this.doYouWantToLogout = 'Do you want to logout?';
        }
        else {
            this.endSessionLabel = 'Sitzung beenden';
            this.yesLabel = 'Ja';
            this.cancelLabel = 'Abbrechen';
            this.doYouWantToLogout = 'Möchten Sie sich abmelden?';
        }

        if (!this.notificationLogOut) {
            this.alertCtrl.presentAlert({
                header: this.endSessionLabel,
                message: this.doYouWantToLogout,
                buttons: [
                    {
                        text: this.cancelLabel,
                        role: 'cancel',
                    },
                    {
                        text: this.yesLabel,
                        handler: () => {
                            this.isLoggedIn = false;
                            this.notificationLogOut = false;
                            this.menuService.menu = [
                                {
                                    index: 0,
                                    name: this.translated.getTranslatedText('menu_report'),
                                    icon: '../../assets/imgs/report.svg',
                                    path: '/members/employee-reports'
                                },
                                {
                                    index: 1,
                                    name: 'Regierapport',
                                    icon: '../../assets/imgs/regie_raport.svg',
                                    path: ['/members/regierapport', {keyword: 'Employee'}]
                                },
                                {
                                    index: 2,
                                    name: this.translated.getTranslatedText('menu_hours_control'),
                                    icon: '../../assets/imgs/report_management.svg',
                                    path: '/members/employee-manage-reports'
                                },
                                {
                                    index: 3,
                                    name: this.translated.getTranslatedText('activity'),
                                    icon: '../../assets/imgs/activity.svg',
                                    path: '/members/employee-activity'
                                },
                                {
                                    index: 7,
                                    name: this.translated.getTranslatedText('menu_vacations'),
                                    icon: '../../assets/imgs/vacation.svg',
                                    path: '/members/employee-vacations'
                                },
                                {
                                    index: 8,
                                    name: this.translated.getTranslatedText('menu_help'),
                                    icon: '../../assets/imgs/help.svg',
                                    path: ['/members/help', {keyword: 'Employee'}]
                                },
                                // {
                                //     index: 9,
                                //     name: this.translated.getTranslatedText('menu_report_control'),
                                //     icon: '../../assets/imgs/report_management.svg',
                                //     path: ['/members/my-supervised-employees']
                                // },
                                {
                                    index: 10,
                                    name: this.translated.getTranslatedText('settings'),
                                    icon: '../../assets/imgs/settings.svg',
                                    path: ['/members/settings', {keyword: 'Employee'}]
                                }
                            ];
                            this.menuService.title = '';
                            this.tokenService.removeToken();
                            this.events.publish('user:company');
                            this.events.publish('user:employee');

                            this.storage.set('myauthtokenkey', null);
                            this.storage.set('user_type', null);
                            this.authState.next(false);
                            this.loader.present();
                            let headers = new HttpHeaders();
                            headers = headers.append('Authorization', this.tokenProvider.returnToken());
                            headers = headers.append('Accept', 'application/json');
                            headers = headers.append('Content-Type', 'application/json');
                            this.http.delete(this.helpers.url + (this.lngProvider.language ? this.lngProvider.language : 'de') + '/pop/device', {headers: headers}).subscribe((res) => {
                                this.loader.dismiss();
                                this.navCtrl.navigateRoot('/login');
                            }, err => {
                                this.loader.dismiss();
                                this.navCtrl.navigateRoot('/login');
                            });
                        }
                    }
                ]
            });
        } else {
            this.isLoggedIn = false;
            this.notificationLogOut = false;
            this.menuService.menu = [
                {
                    index: 0,
                    name: this.translated.getTranslatedText('menu_report'),
                    icon: '../../assets/imgs/report.svg',
                    path: '/members/employee-reports'
                },
                {
                    index: 1,
                    name: 'Regierapport',
                    icon: '../../assets/imgs/regie_raport.svg',
                    path: ['/members/regierapport', {keyword: 'Employee'}]
                },
                {
                    index: 2,
                    name: this.translated.getTranslatedText('menu_hours_control'),
                    icon: '../../assets/imgs/report_management.svg',
                    path: '/members/employee-manage-reports'
                },
                {
                    index: 3,
                    name: this.translated.getTranslatedText('activity'),
                    icon: '../../assets/imgs/activity.svg',
                    path: '/members/employee-activity'
                },
                {
                    index: 7,
                    name: this.translated.getTranslatedText('menu_vacations'),
                    icon: '../../assets/imgs/vacation.svg',
                    path: '/members/employee-vacations'
                },
                {
                    index: 8,
                    name: this.translated.getTranslatedText('menu_help'),
                    icon: '../../assets/imgs/help.svg',
                    path: ['/members/help', {keyword: 'Employee'}]
                },
                // {
                //     index: 9,
                //     name: this.translated.getTranslatedText('menu_report_control'),
                //     icon: '../../assets/imgs/report_management.svg',
                //     path: ['/members/my-supervised-employees']
                // },
                {
                    index: 10,
                    name: this.translated.getTranslatedText('settings'),
                    icon: '../../assets/imgs/settings.svg',
                    path: ['/members/settings', {keyword: 'Employee'}]
                }
            ];
            this.menuService.title = '';
            this.tokenService.removeToken();
            this.events.publish('user:company');
            this.events.publish('user:employee');

            this.storage.set('myauthtokenkey', null);
            this.storage.set('user_type', null);
            this.authState.next(false);
            this.loader.present();
            let headers = new HttpHeaders();
            headers = headers.append('Authorization', this.tokenProvider.returnToken());
            headers = headers.append('Accept', 'application/json');
            headers = headers.append('Content-Type', 'application/json');
            // window.FirebasePlugin.unregister();
            this.http.delete(this.helpers.url + (this.lngProvider.language ? this.lngProvider.language : 'de') + '/pop/device', {headers: headers}).subscribe((res) => {
                this.loader.dismiss();
                this.navCtrl.navigateRoot('/login');
            }, err => {
                this.loader.dismiss();
                this.navCtrl.navigateRoot('/login');
            });
        }

    }


    /**
     * Check if a user is authenticated
     * @returns {boolean}
     */
    public isAuthenticated() {
        return this.authState.value;
    }


}
