import {Component, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {LanguageService} from '../../services/language/language.service';

@Component({
    selector: 'app-verified',
    templateUrl: './verified.page.html',
    styleUrls: ['./verified.page.scss'],
})
export class VerifiedPage implements OnInit {

    language;
    message;
    constructor(
        private  navCtrl: NavController,
        private lngProvider:LanguageService,
        public modalCtrl:ModalController
    ) {
    }

    ngOnInit() {
        this.language = this.lngProvider.language;

        if(this.language == 'en'){
            this.message = "Please wait for your account verification within the next 24h."
        }
        else{
            this.message = "Ihr Konto wird während Arbeitstagen innerhalb 24 Stunden überprüft. Bitte auf die Freigabe warten. "
        }

    }

    goBack() {
        this.modalCtrl.dismiss();
    }


}
