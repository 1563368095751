import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

import {FileOpener} from '@ionic-native/file-opener/ngx';
import {File} from '@ionic-native/file/ngx';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    folderpath: string;
    folderName: string;

    constructor(
        public platform: Platform,
        private file: File,
        public fileOpener: FileOpener,
        public document: DocumentViewer
    ) {
    }

    generate(data){
        let pdfObj = pdfMake.createPdf(data);
        return pdfObj;
    }

    download(doc, name:string){
        let createDir = false;
        let browser = false;
        let filename1 = name.toString();
        if (this.platform.is('ios')) {
            console.log('IOS');
            this.folderpath = this.file.documentsDirectory;
        } else if (this.platform.is('android')) {
            this.folderpath = "file:///storage/emulated/0/Download/";
            console.log('Android');
        }
        else{
            console.log('browser')
            browser = true;
        }
        if(!browser) { //!browser
            this.file.checkDir(this.folderpath, 'SmartRapport')
                .then(exist => {
                    if (exist) {
                        createDir = true;
                        this.folderName = (this.folderpath + "SmartRapport").toString();
                        doc.getBuffer((buffer) => {
                            var blob = new Blob([buffer], {"type": "application/pdf"});
                            // Save the PDF to the data Directory of our App
                            if (blob) {
                                this.file.writeFile(this.folderpath + "SmartRapport", filename1, blob, {replace:true}).then(fileEntry => {
                                    // Open the PDf with the correct OS tools
                                    if (this.platform.is('android')) {
                                        this.document.viewDocument(fileEntry.nativeURL, 'application/pdf', {}, () => {
                                            // console.log("VIEWING ANDROID. NOW OPENING 2")
                                        }, () => {
                                        }, () => {
                                            this.fileOpener.open(fileEntry.nativeURL, "application/pdf").then(() => {
                                            });
                                        });
                                    }
                                    if (this.platform.is('ios')) {
                                        this.fileOpener.open(fileEntry.nativeURL, "application/pdf").then((data) => {
                                            // console.log("FILE OPENER ON IOS. NOW OPENING 2")
                                        });
                                    }

                                })
                            }
                        });
                    }
                })
                .catch(err => {
                    // console.log('Directory doesn\'t exist');
                    this.file.createDir(this.folderpath, 'SmartRapport', true)
                        .then(_ => {
                            createDir = true;
                            this.folderName = (this.folderpath + "SmartRapport").toString();
                            doc.getBuffer((buffer) => {
                                var blob = new Blob([buffer], {"type": "application/pdf"});
                                // Save the PDF to the data Directory of our App
                                if (blob) {
                                    // console.log("SECOND")
                                    this.file.writeFile(this.folderpath + "SmartRapport", filename1, blob, {replace:true}).then(fileEntry => {
                                        // Open the PDf with the correct OS tools
                                        if (this.platform.is('android')) {
                                            this.document.viewDocument(fileEntry.nativeURL, 'application/pdf', {}, () => {
                                                // console.log("VIEWING ANDROID. NOW OPENING 2")
                                            }, () => {
                                            }, () => {
                                                this.fileOpener.open(fileEntry.nativeURL, "application/pdf").then(() => {
                                                });
                                            });
                                        }
                                        if (this.platform.is('ios')) {
                                            this.fileOpener.open(fileEntry.nativeURL, "application/pdf").then((data) => {
                                                // console.log("FILE OPENER ON IOS. NOW OPENING 2")
                                            });
                                        }

                                    })
                                }
                            });

                        })
                        .catch(err =>
                            console.log("Failed")
                        )

                });
        }
        // if (this.platform.is('pwa')) {
        //     doc.download();
        // }
        if (!createDir && !this.platform.is('ios') || this.platform.is('pwa')){
            doc.download();
        }
    }

}
