import { Injectable } from "@angular/core";
import { firebase } from "@firebase/app";
import "@firebase/messaging";
import { environment } from "../environments/environment";
import { LoginService } from "./services/authentication/login.service";
import {AlertService} from './services/alert/alert.service';
@Injectable({
    providedIn: "root"
})
export class NotificationsService {
    constructor(private loginService: LoginService,private alertCtrl:AlertService) {}
    requestPermission(cb): Promise<void> {
        return new Promise<void>(async resolve => {
            if (!Notification) {
                resolve();
                return;
            }
            if (!firebase.messaging.isSupported()) {
                resolve();
                return;
            }
            try {
                const messaging = firebase.messaging();
                await messaging.requestPermission();
                const token: string = await messaging.getToken();

                this.loginService.registerToken(token, "web").subscribe(
                    res => {
                        // console.log(res);
                    },
                    err => {
                        console.log(err);
                    }
                );
                // console.log(firebase);
            } catch (err) {
               cb()
            }
            resolve();
        });
    }
    init(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            navigator.serviceWorker.ready.then(
                registration => {
                    // Don't crash an error if messaging not supported
                    if (!firebase.messaging.isSupported()) {
                        resolve();
                        return;
                    }
                    const messaging = firebase.messaging();
                    // Register the Service Worker
                    messaging.useServiceWorker(registration);
                    // Initialize your VAPI key
                    messaging.usePublicVapidKey(environment.firebase.vapidKey);
                    // Optional and not covered in the article
                    // Listen to messages when your app is in the foreground
                    messaging.onMessage(payload => {
                        // console.log(payload);
                    });
                    // Optional and not covered in the article
                    // Handle token refresh
                    messaging.onTokenRefresh(() => {
                        messaging
                            .getToken()
                            .then((refreshedToken: string) => {
                                // console.log(refreshedToken);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
                    resolve();
                },
                err => {
                    reject(err);
                }
            );
        });
    }
}
