import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {LanguageService} from '../services/language/language.service';
import {TranslatedService} from '../services/translated/translated.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.page.html',
    styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage implements OnInit {
    german: boolean = false;
    eng: boolean = false;
    close: any;
    page;
    title;

    constructor(
        private modalController: ModalController,
        private lang: LanguageService,
        private translated:TranslatedService

    ) {
    }


    ngOnInit() {
        if(this.page == 'Privacy Policy'){
            this.title = this.translated.getTranslatedText('privacy_policy')
        }else{
            this.title = this.translated.getTranslatedText('terms_condition')
        }
        if (this.lang.language == 'de') {

            this.german = true;
            this.close = 'Schließen';
        } else {
            this.eng = true;
            this.close = 'Close';
        }
    }

    async closeModal() {
        const onClosedData: string = 'Wrapped Up!';
        await this.modalController.dismiss(onClosedData);
    }
}
