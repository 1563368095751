import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ToastService} from '../../../services/toast/toast.service';
import {ActivatedRoute} from '@angular/router';
import {PdfService} from '../../../services/pdf/pdf.service';
import {LoaderService} from '../../../services/loader/loader.service';
import {CompanyService} from '../../../services/company/company.service';
import {AlertService} from '../../../services/alert/alert.service';
import {ProjectService} from '../../../services/project/project.service';
import {Events, ModalController, NavController, Platform} from '@ionic/angular';
import {LanguageService} from '../../../services/language/language.service';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {EmployeeAccountService} from '../../../services/employee-account/employee-account.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedService} from '../../../services/translated/translated.service';
import {EmployeeService} from '../../../services/employee/employee.service';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {WeekDaysService} from '../../../services/week-days/week-days.service';

@Component({
    selector: 'app-my-report-details',
    templateUrl: './my-report-details.page.html',
    styleUrls: ['./my-report-details.page.scss'],
})
export class MyReportDetailsPage implements OnInit {

    constructor(
        private lngProvider: LanguageService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private projectService: ProjectService,
        private alertCtrl: AlertService,
        private datePipe: DatePipe,
        private loader: LoaderService,
        private toastCtrl: ToastService,
        private pdfProvider: PdfService,
        public companyService: CompanyService,
        public platform: Platform,
        private employeeService: EmployeeAccountService,
        private empService: EmployeeService,
        private translated: TranslatedService,
        private events: Events,
        public helpers: HelpersService,
        private modalController: ModalController,
        public weekDaysHelper: WeekDaysService
    ) {
        this.events.subscribe('backButtonClicked', () => {
            this.goBack();
        });
        this.events.subscribe('auto-close', () => {
            this.goBack();
        });
        this.isIOS = this.platform.is('ios');
    }

    keyword: any;
    report_id: any;
    @Input() modal_keyword: any;
    @Input() modal_report_id: any;
    project_id;
    page_title;
    isVerified;
    reportObj;
    docDefinition;
    pdfReport;
    pdfText;
    project;
    errorMessage;
    reportDataObj;
    week;
    year;
    mon;
    tue;
    wed;
    thur;
    fri;
    sat;
    sun;
    weeklyReportProject;
    total;
    isIOS;
    absentReason;
    monday;
    tuesday;
    wednesday;
    thursday;
    friday;
    saturday;
    sunday;
    isFromAdmin = false;
    validDays = true;
    isFromActivityPage;
    cantEdit = false;
    hours_with_extra_25: any;
    hours_with_extra_50: any;
    hours_with_extra_100: any;
    validPercentage = true;

    mon_hours_with_extra_25: any ;
    mon_hours_with_extra_50: any ;
    mon_hours_with_extra_100: any ;
    tue_hours_with_extra_25: any ;
    tue_hours_with_extra_50: any ;
    tue_hours_with_extra_100: any ;
    wed_hours_with_extra_25: any ;
    wed_hours_with_extra_50: any ;
    wed_hours_with_extra_100: any ;
    thu_hours_with_extra_25: any ;
    thu_hours_with_extra_50: any ;
    thu_hours_with_extra_100: any ;
    fri_hours_with_extra_25: any ;
    fri_hours_with_extra_50: any ;
    fri_hours_with_extra_100: any ;
    sat_hours_with_extra_25: any ;
    sat_hours_with_extra_50: any ;
    sat_hours_with_extra_100: any ;
    sun_hours_with_extra_25: any ;
    sun_hours_with_extra_50: any ;
    sun_hours_with_extra_100: any ;
    subtotal25: any ;
    subtotal50: any ;
    subtotal100: any ;
    subtotal: any ;

    is_notification;

    /**
     * Removing comma and checking if days are not bigger than 24
     * @param event
     */
    monValid = true;
    tueValid = true;
    wedValid = true;
    thuValid = true;
    friValid = true;
    satValid = true;
    sunValid = true;
    valid25 = true;
    valid50 = true;
    valid100 = true;


    /**
     *  Select element for verifier
     */
    @ViewChild('selectVerifier') verifiersOptions;
    customAlertOptionsVerifier: any = {
        header: this.translated.getTranslatedText('request_admin_approval'),
        backdropDismiss: false
    };

    /**
     * Get list of verifiers for the project of the report
     *
     */
    verifier: any = {};
    verifiers: any = [];

    showQR = false;

    @ViewChild('selectOption') alertOptions;
    option;
    customAlertOptions: any = {
        header: this.translated.getTranslatedText('generate_pdf'),
    };

    ngOnInit() {
        this.is_notification = this.route.snapshot.paramMap.get('is_notification');
        this.page_title = this.route.snapshot.paramMap.get('page_title');
        this.isVerified = this.route.snapshot.paramMap.get('isVerified');
        this.report_id = this.route.snapshot.paramMap.get('report_id');
        this.keyword = this.route.snapshot.paramMap.get('keyword');
        this.isFromActivityPage = this.route.snapshot.paramMap.get('activity');

        if (this.modal_keyword && this.modal_report_id) {
            this.keyword = this.modal_keyword;
            this.report_id = this.modal_report_id;
        }

        this.getWeeklyReport(this.report_id);
        this.getVerifiers();

    }

    goBack() {
        if (this.keyword == 'modal') {
            this.modalController.dismiss();
        } else if (this.is_notification == 'true') {
            {
            }
            this.navCtrl.navigateBack(['/members/company-dashboard']);
        } else if (this.keyword == 'shortcut') {
            this.navCtrl.navigateBack(['/members/my-employees']);
        } else if (this.isFromActivityPage) {
            this.navCtrl.navigateBack(['/members/company-activity']);
        } else {
            this.navCtrl.navigateBack(['/members/company-reports']);
        }
    }

    /**
     * Get weekly report data
     * @param id
     */
    getWeeklyReport(id) {
        let that = this;
        this.loader.fancyLoading('loading', function () {
            that.companyService.getEditSelfReport(id).subscribe((res: any) => {
                that.reportDataObj = res;
                that.week = res.report.woche;
                that.reportObj = that.reportDataObj.report;
                console.log(that.reportObj.by_admin);
                that.isVerified = that.reportObj.verified;
                that.page_title = 'KW-' + (parseInt(that.reportObj.woche) < 10 ? '0' + that.reportObj.woche : that.reportObj.woche) + '-' + that.reportObj.kenwort;
                that.year =that.reportDataObj.report.for_year?that.reportDataObj.report.for_year:( new Date(that.reportDataObj.report.created)).getFullYear();
                console.log(res)
                console.log("Report year ",that.reportDataObj.report.created, that.year)
                that.mon = that.reportDataObj.report.mo;
                that.tue = that.reportDataObj.report.di;
                that.wed = that.reportDataObj.report.mi;
                that.thur = that.reportDataObj.report.do;
                that.fri = that.reportDataObj.report.fr;
                that.sat = that.reportDataObj.report.sa;
                that.sun = that.reportDataObj.report.so;
                if (!that.reportObj.absent) {
                    that.mon_hours_with_extra_25 = that.reportDataObj.report.mo_hours_with_extra_25;
                    that.mon_hours_with_extra_50 = that.reportDataObj.report.mo_hours_with_extra_50;
                    that.mon_hours_with_extra_100 = that.reportDataObj.report.mo_hours_with_extra_100;
                    that.tue_hours_with_extra_25 = that.reportDataObj.report.di_hours_with_extra_25;
                    that.tue_hours_with_extra_50 = that.reportDataObj.report.di_hours_with_extra_50;
                    that.tue_hours_with_extra_100 = that.reportDataObj.report.di_hours_with_extra_100;
                    that.wed_hours_with_extra_25 = that.reportDataObj.report.mi_hours_with_extra_25;
                    that.wed_hours_with_extra_50 = that.reportDataObj.report.mi_hours_with_extra_50;
                    that.wed_hours_with_extra_100 = that.reportDataObj.report.mi_hours_with_extra_100;
                    that.thu_hours_with_extra_25 = that.reportDataObj.report.do_hours_with_extra_25;
                    that.thu_hours_with_extra_50 = that.reportDataObj.report.do_hours_with_extra_50;
                    that.thu_hours_with_extra_100 = that.reportDataObj.report.do_hours_with_extra_100;
                    that.fri_hours_with_extra_25 = that.reportDataObj.report.fr_hours_with_extra_25;
                    that.fri_hours_with_extra_50 = that.reportDataObj.report.fr_hours_with_extra_50;
                    that.fri_hours_with_extra_100 = that.reportDataObj.report.fr_hours_with_extra_100;
                    that.sat_hours_with_extra_25 = that.reportDataObj.report.sa_hours_with_extra_25;
                    that.sat_hours_with_extra_50 = that.reportDataObj.report.sa_hours_with_extra_50;
                    that.sat_hours_with_extra_100 = that.reportDataObj.report.sa_hours_with_extra_100;
                    that.sun_hours_with_extra_25 = that.reportDataObj.report.so_hours_with_extra_25;
                    that.sun_hours_with_extra_50 = that.reportDataObj.report.so_hours_with_extra_50;
                    that.sun_hours_with_extra_100 = that.reportDataObj.report.so_hours_with_extra_100;
                }
                that.totalWrk();
                that.weeklyReportProject = that.reportDataObj.projList;
                that.weekDaysHelper.getWeekDays(that.week, that.year).subscribe((res: any) => {
                    that.monday = res.monday;
                    that.tuesday = res.tuesday;
                    that.wednesday = res.wednesday;
                    that.thursday = res.thursday;
                    that.friday = res.friday;
                    that.saturday = res.saturday;
                    that.sunday = res.sunday;
                });
            }, error => {
                that.errorMessage = <any>error;
            });
        });
    }

    /**
     * Calculate the total number of hours for all days
     * @returns {string}
     */
    totalWrk() {
        this.total =
            parseFloat(this.mon == '' ? 0 : this.mon) +
            parseFloat(this.tue == '' ? 0 : this.tue) +
            parseFloat(this.wed == '' ? 0 : this.wed) +
            parseFloat(this.thur == '' ? 0 : this.thur) +
            parseFloat(this.fri == '' ? 0 : this.fri) +
            parseFloat(this.sat == '' ? 0 : this.sat) +
            parseFloat(this.sun == '' ? 0 : this.sun);

        if (!this.reportObj.absent) {
            this.subtotal25 =
                parseFloat(this.mon_hours_with_extra_25 == '' ? 0 : this.mon_hours_with_extra_25) +
                parseFloat(this.tue_hours_with_extra_25 == '' ? 0 : this.tue_hours_with_extra_25) +
                parseFloat(this.wed_hours_with_extra_25 == '' ? 0 : this.wed_hours_with_extra_25) +
                parseFloat(this.thu_hours_with_extra_25 == '' ? 0 : this.thu_hours_with_extra_25) +
                parseFloat(this.fri_hours_with_extra_25 == '' ? 0 : this.fri_hours_with_extra_25) +
                parseFloat(this.sat_hours_with_extra_25 == '' ? 0 : this.sat_hours_with_extra_25) +
                parseFloat(this.sun_hours_with_extra_25 == '' ? 0 : this.sun_hours_with_extra_25);

            this.subtotal50 =
                parseFloat(this.mon_hours_with_extra_50 == '' ? 0 : this.mon_hours_with_extra_50) +
                parseFloat(this.tue_hours_with_extra_50 == '' ? 0 : this.tue_hours_with_extra_50) +
                parseFloat(this.wed_hours_with_extra_50 == '' ? 0 : this.wed_hours_with_extra_50) +
                parseFloat(this.thu_hours_with_extra_50 == '' ? 0 : this.thu_hours_with_extra_50) +
                parseFloat(this.fri_hours_with_extra_50 == '' ? 0 : this.fri_hours_with_extra_50) +
                parseFloat(this.sat_hours_with_extra_50 == '' ? 0 : this.sat_hours_with_extra_50) +
                parseFloat(this.sun_hours_with_extra_50 == '' ? 0 : this.sun_hours_with_extra_50);

            this.subtotal100 =
                parseFloat(this.mon_hours_with_extra_100 == '' ? 0 : this.mon_hours_with_extra_100) +
                parseFloat(this.tue_hours_with_extra_100 == '' ? 0 : this.tue_hours_with_extra_100) +
                parseFloat(this.wed_hours_with_extra_100 == '' ? 0 : this.wed_hours_with_extra_100) +
                parseFloat(this.thu_hours_with_extra_100 == '' ? 0 : this.thu_hours_with_extra_100) +
                parseFloat(this.fri_hours_with_extra_100 == '' ? 0 : this.fri_hours_with_extra_100) +
                parseFloat(this.sat_hours_with_extra_100 == '' ? 0 : this.sat_hours_with_extra_100) +
                parseFloat(this.sun_hours_with_extra_100 == '' ? 0 : this.sun_hours_with_extra_100);

            this.subtotal = this.subtotal25 + this.subtotal50 + this.subtotal100;
            this.total += this.subtotal;
        }


        // + parseFloat(this.hours_with_extra_25 == '' ? 0 : this.hours_with_extra_25) +parseFloat(this.hours_with_extra_50 == '' ? 0 : this.hours_with_extra_50) +parseFloat(this.hours_with_extra_100 == '' ? 0 : this.hours_with_extra_100) ;
        this.total = this.total ? this.total : 0;
        this.total = parseFloat(this.total).toFixed(2);
        return this.total;
    }

    /**
     * Edit current report
     * @param id int -> report id
     */
    edit_WeeklyReport(id) {
        const that = this;
        {
            let data: any;

            if (this.reportObj.absent) {
                data = {
                    mon_1: this.mon ? this.mon : 0,
                    tue_2: this.tue ? this.tue : 0,
                    wed_3: this.wed ? this.wed : 0,
                    thu_4: this.thur ? this.thur : 0,
                    fri_5: this.fri ? this.fri : 0,
                    sat_6: this.sat ? this.sat : 0,
                    sun_7: this.sun ? this.sun : 0,

                };
            } else {
                data = {
                    mon_1: this.mon ? this.mon : 0,
                    tue_2: this.tue ? this.tue : 0,
                    wed_3: this.wed ? this.wed : 0,
                    thu_4: this.thur ? this.thur : 0,
                    fri_5: this.fri ? this.fri : 0,
                    sat_6: this.sat ? this.sat : 0,
                    sun_7: this.sun ? this.sun : 0,
                    'mo_hours_with_extra_25': this.mon_hours_with_extra_25 ? this.mon_hours_with_extra_25 : 0,
                    'mo_hours_with_extra_50': this.mon_hours_with_extra_50 ? this.mon_hours_with_extra_50 : 0,
                    'mo_hours_with_extra_100': this.mon_hours_with_extra_100 ? this.mon_hours_with_extra_100 : 0,
                    'di_hours_with_extra_25': this.tue_hours_with_extra_25 ? this.tue_hours_with_extra_25 : 0,
                    'di_hours_with_extra_50': this.tue_hours_with_extra_50 ? this.tue_hours_with_extra_50 : 0,
                    'di_hours_with_extra_100': this.tue_hours_with_extra_100 ? this.tue_hours_with_extra_100 : 0,
                    'mi_hours_with_extra_25': this.wed_hours_with_extra_25 ? this.wed_hours_with_extra_25 : 0,
                    'mi_hours_with_extra_50': this.wed_hours_with_extra_50 ? this.wed_hours_with_extra_50 : 0,
                    'mi_hours_with_extra_100': this.wed_hours_with_extra_100 ? this.wed_hours_with_extra_100 : 0,
                    'do_hours_with_extra_25': this.thu_hours_with_extra_25 ? this.thu_hours_with_extra_25 : 0,
                    'do_hours_with_extra_50': this.thu_hours_with_extra_50 ? this.thu_hours_with_extra_50 : 0,
                    'do_hours_with_extra_100': this.thu_hours_with_extra_100 ? this.thu_hours_with_extra_100 : 0,
                    'fr_hours_with_extra_25': this.fri_hours_with_extra_25 ? this.fri_hours_with_extra_25 : 0,
                    'fr_hours_with_extra_50': this.fri_hours_with_extra_50 ? this.fri_hours_with_extra_50 : 0,
                    'fr_hours_with_extra_100': this.fri_hours_with_extra_100 ? this.fri_hours_with_extra_100 : 0,
                    'sa_hours_with_extra_25': this.sat_hours_with_extra_25 ? this.sat_hours_with_extra_25 : 0,
                    'sa_hours_with_extra_50': this.sat_hours_with_extra_50 ? this.sat_hours_with_extra_50 : 0,
                    'sa_hours_with_extra_100': this.sat_hours_with_extra_100 ? this.sat_hours_with_extra_100 : 0,
                    'so_hours_with_extra_25': this.sun_hours_with_extra_25 ? this.sun_hours_with_extra_25 : 0,
                    'so_hours_with_extra_50': this.sun_hours_with_extra_50 ? this.sun_hours_with_extra_50 : 0,
                    'so_hours_with_extra_100': this.sun_hours_with_extra_100 ? this.sun_hours_with_extra_100 : 0,

                };
            }
            if (this.validDays) {
                this.loader.fancyLoading('loading', function () {
                    that.companyService.editSelfReport(id, data).subscribe(res => {
                        let obj: any;
                        obj = res;
                        if (obj.status == 'success') {
                            that.toastCtrl.presentToast(obj.message, '', 'success', '');
                        } else {
                            that.toastCtrl.presentToast(obj.message, '', 'warning', '');
                        }
                    }, error => {
                        console.log(error);
                    });
                });
            } else {
                this.toastCtrl.presentToast('invalid_data', '', 'danger', '');
            }


        }
    }

    /**
     * Function that checks if value is 0
     * @param event number -> returns empty string
     */
    clearOnEdit(event) {
        this.helpers.clearOnEdit(event);
    }

    checkValid(event) {

        switch (event.srcElement.id) {
            case 'mon':
            case 'mon25':
            case 'mon50':
            case 'mon100':
                this.monValid = this.helpers.checkValid(event);
                break;
            case 'tue':
            case 'tue25':
            case 'tue50':
            case 'tue100':
                this.tueValid = this.helpers.checkValid(event);
                break;
            case 'wed':
            case 'wed25':
            case 'wed50':
            case 'wed100':
                this.wedValid = this.helpers.checkValid(event);
                break;
            case 'thu':
            case 'thu25':
            case 'thu50':
            case 'thu100':
                this.thuValid = this.helpers.checkValid(event);
                break;
            case 'fri':
            case 'fri25':
            case 'fri50':
            case 'fri100':
                this.friValid = this.helpers.checkValid(event);
                break;
            case 'sat':
            case 'sat25':
            case 'sat50':
            case 'sat100':
                this.satValid = this.helpers.checkValid(event);
                break;
            case 'sun':
            case 'sun25':
            case 'sun50':
            case 'sun100':
                this.sunValid = this.helpers.checkValid(event);
                break;
        }
        // if(this.helpers.checkValid(event)){
        //     this.validDays = true;
        // }else {
        //     this.validDays = false;
        // }
        this.validDays =
            this.monValid &&
            this.tueValid &&
            this.wedValid &&
            this.thuValid &&
            this.friValid &&
            this.satValid &&
            this.sunValid;
        // this.validDays = this.helpers.checkValid(event);

    }

    /**
     * Show confirmation alert to delete the selected report
     * @param report_id int ->  id of the report
     */
    deleteAlert(report_id) {
        const that = this;
        this.alertCtrl.presentDeleteAlert('delete_report', '', function () {
            that.remove_report(report_id);
        });

    }

    /**
     * Remove report
     * @param report_id int -> id of the report
     */
    remove_report(report_id) {
        this.companyService.deleteSelfReport(report_id).subscribe((res: any) => {
            this.toastCtrl.presentToast(res.message, '', 'success', '');
            this.goBack();
        });
    }

    /**
     * Verify report
     */
    verify_report() {
        if (!this.reportObj.absent) {
            this.alertCtrl.presentAlert({
                header: this.getTranslatedText('verify') + '?',
                buttons: [
                    {
                        text: this.getTranslatedText('verify'),
                        handler: () => {
                            this.alertCtrl.presentAlertPrompt('verify', false, {}, () => {
                                this.companyService.verifySelfReport(this.report_id).subscribe((res: any) => {
                                    this.toastCtrl.presentToast(res.message, '', 'success', '');
                                    this.goBack();
                                }, err => {
                                });

                            });
                        }
                    },
                    {
                        text: this.getTranslatedText('request_admin_approval'),
                        handler: () => {
                            this.verifiersOptions.open();

                        }
                    },
                    {
                        text: this.getTranslatedText('cancel'),
                        role: 'cancel',
                        handler: () => {
                        }
                    },
                ]
            });
        } else {
            this.alertCtrl.presentAlertPrompt('verify', false, {}, () => {
                this.companyService.verifySelfReport(this.report_id).subscribe((res: any) => {
                    this.toastCtrl.presentToast(res.message, '', 'success', '');
                    this.goBack();
                }, err => {
                });

            });
        }


    }

    chooseVerifier() {
        if (this.verifier) {
            this.alertCtrl.presentAlertPrompt('request_admin_approval', false, {}, () => {
                this.companyService.sendRequestToVerifier(this.report_id, this.verifier.id).subscribe((res: any) => {
                    this.toastCtrl.presentToast(res.message, '', 'success', '');
                    this.goBack();
                }, err => {
                });
            });
        }
    }

    verifierOptionCanceled() {
        this.verifier = null;
    }

    getVerifiers() {
        this.companyService.getVerifiersListForApproval(this.report_id).subscribe((data: any) => {
            console.log('verifiers ', data);
            this.verifiers = data.list;
        });
    }

    qRCode() {
        this.showQR = !this.showQR;
    }

    /**
     * Getting translated text :)
     * @param label string -> the label from de.json ex 'group_name'
     */
    getTranslatedText(label) {
        return this.translated.getTranslatedText(label);
    }

    /**
     * Generate PDF for the report
     * @param id
     */
    generatePDF(id) {
        this.companyService.generateSingleSelfPDF(id).subscribe((res: any) => {
            console.log(res);
            this.helpers.generate_current_report_pdf_with_percentage(res, this.page_title, 'company');
            // this.helpers.generate_current_report_pdf(res, this.page_title, 'company');
        }, error => {
            this.errorMessage = <any>error;
        });

    }

    /**
     * Generate weekly PDF based on the week of this report
     */
    generatePDFWeekly(data) {
        this.companyService.getWeeklyPDFReport(data.woche).subscribe((res: any) => {
            console.log(res);
            this.helpers.generate_weekly_report_pdf_with_percentage(res, 'company');
        }, error => {
            this.errorMessage = <any>error;
        });
    }

    showOptions() {
        this.alertOptions.open();
    }

    optionSelected(w) {
        if (this.option == '1') {
            this.generatePDF(this.report_id);
        } else if (this.option == '2') {
            this.generatePDFWeekly(w);
        }
        this.option = null;
    }

    optionCanceled() {
        this.option = null;
    }

    cancelVerification() {
        this.alertCtrl.reasonAlert('Cancel Verification', [], () => {

            this.loader.fancyLoading('loading', () => {
                this.companyService.cancelVerification(this.report_id).subscribe((res: any) => {
                    if (res) {
                        this.toastCtrl.presentToast(res.message, '', res.status, '');
                        this.goBack();
                    }
                });

            });

        }, 'yes', 'cancel');

    }
}
