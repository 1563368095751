import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslatedService} from '../translated/translated.service';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    isLoading = false;

    constructor(public loadingController: LoadingController,private translated:TranslatedService) {
    }

    async present() {
        this.isLoading = true;
        return await this.loadingController.create({}).then(a => {
            // present
            a.present().then(() => {
                if (!this.isLoading) {
                    // abort
                    a.dismiss().then(() => {});
                }
            });
        });
    }
    /**
     * Getting translated text :)
     * @param label string -> the label from de.json ex 'group_name'
     */
    getTranslatedText(label){
        return this.translated.getTranslatedText(label)
    }
    async fancyLoading(label,callback) {
        let that = this;
        const loading = await this.loadingController.create({
            spinner: "bubbles",
            duration: 500,
            message: that.getTranslatedText(label),
        });
         await loading.present();
        callback( await loading.onDidDismiss());
    }

    async dismiss() {
        this.isLoading = false;
        // dismiss
        return await this.loadingController.dismiss().then(() => {});
    }

}
