import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProject'
})
export class SearchProjectPipe implements PipeTransform {

    transform(items: any[], terms: string): any[] {
        if(!items) return [];
        if(!terms) return items;
        terms = terms.toLowerCase();
        return items.filter( it => {
            if(it.project_name) return it.project_name.toLowerCase().includes(terms);
            else
                return it.projectName.toLowerCase().includes(terms);
        });
    }
}
