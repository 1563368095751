import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public language;
    constructor(public storage: Storage, public translate: TranslateService) {
        this.checkLanguage();
    }

    setLanguage(val){
        this.language = val;
        this.storage.set('language', val);
        // console.log("Set language to ",val);

        this.translate.setDefaultLang(val);
        this.translate.use(val);
    }

    getLanguage(){
        return this.language;
    }

    checkLanguage(){
        this.storage.get('language').then((val) => {
            // console.log(val);
            if(val){
                this.language = val;
            }
            else {
                this.language = 'de';
                this.setLanguage('de')
            }

            this.translate.setDefaultLang(this.language);
            this.translate.use(this.language);
        });
    }
}
