import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {IonicStorageModule} from '@ionic/storage';

import {HttpClientModule, HttpClient} from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared.module';

import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePipe, DecimalPipe} from '@angular/common';
import { AppVersion } from '@ionic-native/app-version/ngx';


/**
 * Plugins
 */
import {Camera} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';

import { DatePicker } from '@ionic-native/date-picker/ngx';

import {PrivacyPolicyPageModule} from './privacy-policy/privacy-policy.module';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule, AngularFirestore } from 'angularfire2/firestore';
import { Firebase } from '@ionic-native/firebase/ngx';
import {RegieRapportService} from './services/regie-rapport/regie-rapport.service';
import {Badge} from '@ionic-native/badge/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import {TransLoaderService} from './services/trans-loader-service/trans-loader.service';
import {Network} from '@ionic-native/network/ngx';
import {NetworkPageModule} from './pages/network/network.module';
import {EmployeeReportDetailPageModule} from './pages/employee/employee-report-detail/employee-report-detail.module';
import {MyReportDetailsPageModule} from './pages/company/my-report-details/my-report-details.module';
import {VerifiedPageModule} from './pages/verified/verified.module';
import {TutorialsPageModule} from './pages/tutorials/tutorials.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const config = {
    apiKey: 'AIzaSyAYzLKdf-2HH3g8gJJub030aWjGt35ZOj8',
    authDomain: 'test-b2920.firebaseapp.com',
    databaseURL: 'https://test-b2920.firebaseio.com',
    projectId: 'test-b2920',
    storageBucket: 'test-b2920.appspot.com',
    messagingSenderId: '81420931088'

};

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            scrollAssist: true,
        }),
        AppRoutingModule,
        FormsModule, ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass:TransLoaderService,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AngularFireModule.initializeApp(config),
        AngularFirestoreModule,
        PrivacyPolicyPageModule,
        NetworkPageModule, VerifiedPageModule, TutorialsPageModule,
        EmployeeReportDetailPageModule, MyReportDetailsPageModule
    ],
    providers: [
        StatusBar,
        Network,
        SplashScreen,
        InAppBrowser,
        DatePipe, DecimalPipe,
        Badge,
        Keyboard,
        BackgroundMode,
        DatePicker, AppVersion,
        FileTransfer,AngularFirestore,RegieRapportService,
        Camera, File, FileOpener, DocumentViewer, Firebase,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
